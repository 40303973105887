import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import { BreadCrumbs, Grid, HeadingH5, Pip, S, Widget } from '@poly/site-book';
import { PageHeader } from '@poly/site-ui';

import { isExist, noDataToDisplay } from '../../../util/general.js';
import { STATUS_COLORS } from '../../../constants/report/master-pm.js';
import { SupplierTeamMember, UserTeamMember } from '../../../utils/wrappers.js';

function Content({ children }) {
  return (
    <S type="content" bold>
      {children || '—'}
    </S>
  );
}

Content.propTypes = {
  children: string,
};

Content.defaultProps = {
  children: '—',
};

function ProjectPmInfo({ project = {} }) {
  const {
    status,
    client,
    repeat,
    endDate,
    manager,
    startDate,
    suppliers,
    description,
    technicians,
  } = project;

  return (
    <>
      <Widget.Item>
        <PageHeader>
          <HeadingH5>PM Information</HeadingH5>
          <Pip color={STATUS_COLORS[status]}>
            <S type="title">{status}</S>
          </Pip>
        </PageHeader>
        <Grid columns="100px 1fr" simple>
          <Grid.Row>
            <Grid.Cell>
              <S type="title">Description</S>
            </Grid.Cell>
            <Grid.Cell>
              <Content>{description}</Content>
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell>
              <S type="title">Client</S>
            </Grid.Cell>
            <Grid.Cell>
              <Content>{client}</Content>
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell vertical="center">
              <S type="title">Planning</S>
            </Grid.Cell>
            <Grid.Cell>
              <BreadCrumbs>
                <BreadCrumbs.Item>
                  <Content>{startDate}</Content>
                </BreadCrumbs.Item>
                <BreadCrumbs.Item>
                  <Content>{endDate}</Content>
                </BreadCrumbs.Item>
              </BreadCrumbs>
            </Grid.Cell>
          </Grid.Row>
          <Grid.Row>
            <Grid.Cell>
              <S type="title">Repeats</S>
            </Grid.Cell>
            <Grid.Cell>
              <Content>{repeat}</Content>
            </Grid.Cell>
          </Grid.Row>
        </Grid>
      </Widget.Item>
      <Widget.Item>
        <PageHeader>
          <HeadingH5>Response Team</HeadingH5>
        </PageHeader>
        <Grid columns="repeat(2, minmax(100px, 1fr))" simple>
          {[manager, technicians, suppliers].some(isExist) ? (
            <>
              {isExist(manager) && (
                <UserTeamMember member={manager} type="Manager" />
              )}
              {suppliers.map((supplier) => (
                <SupplierTeamMember
                  member={supplier}
                  key={supplier._id}
                  type="Supplier"
                />
              ))}
              {technicians.map((technician) => (
                <UserTeamMember
                  member={technician}
                  key={technician._id}
                  type="Technician"
                />
              ))}
            </>
          ) : (
            noDataToDisplay('Members')()
          )}
        </Grid>
      </Widget.Item>
    </>
  );
}

ProjectPmInfo.propTypes = {
  project: shape({
    status: string,
    description: string,
    client: string,
    startDate: string,
    endDate: string,
    repeat: string,
    manager: shape({
      name: string,
      phone: string,
      email: string,
    }),
    suppliers: arrayOf(
      shape({
        _id: string.isRequired,
      }).isRequired,
    ),
    technicians: arrayOf(
      shape({
        _id: string.isRequired,
      }).isRequired,
    ),
  }).isRequired,
};

export default ProjectPmInfo;
