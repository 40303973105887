import * as R from 'ramda';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useUpdateProject } from '../../../../hooks/projects.js';
import { useTechnicians } from '../../../../hooks/staff.js';
import TechniciansTab from '../../../../components/project/details/response-team/technicians-tab.js';

import { DropDownLoader } from '../../../../util/dropdown.js';

import {
  AddResponseTeamSuccess,
  EditResponseTeamSuccess,
  GeneralError,
} from '../../../../constants/alerts.js';
import { prepareTechniciansWithoutSelected } from '../../../pm-projects/technicians-modal/prepareTechniciansWithoutSelected.js';

function TechniciansResponseTeam(props) {
  const { assignedTechnicians, searchTerm, ...restProps } = props;

  const projectId = useSelector((state) =>
    R.path(['projects', 'modals', 'responseTeam', 'projectDbId'], state),
  );
  const clientId = useSelector((state) => R.path(['user', 'clientId'], state));

  const [addLoading, setAddLoading] = useState(false);
  const [projectTechnician, setProjectTechnician] = useState(null);

  useEffect(() => {
    if (assignedTechnicians && assignedTechnicians.length > 0) {
      setProjectTechnician({
        _id: R.prop('_id', assignedTechnicians[0]),
        firstName: R.prop('firstName', assignedTechnicians[0]),
        lastName: R.prop('lastName', assignedTechnicians[0]),
        email: R.path(['emails', 0, 'address'], assignedTechnicians[0]),
        userGroups: assignedTechnicians?.[0]?.userGroups,
      });
    }
  }, [assignedTechnicians]);

  const { loading, technicians } = useTechnicians(searchTerm, clientId);

  const { updateProject } = useUpdateProject();

  const allTechnicians = prepareTechniciansWithoutSelected(
    technicians,
    projectTechnician,
  );

  if (loading) return <DropDownLoader />;

  const handleTechniciansClick = (data, isSelected) => () => {
    setAddLoading(true);
    const newTechnicians = isSelected ? [] : [data._id];
    const clickedTech =
      !isSelected && technicians.find((t) => t._id === data._id);

    updateProject(projectId, { technicians: newTechnicians })
      .then(() => {
        setProjectTechnician(
          isSelected
            ? null
            : {
                _id: clickedTech._id,
                firstName: clickedTech.firstName,
                lastName: clickedTech.lastName,
                email: clickedTech.email,
                userGroups: clickedTech.userGroups,
              },
        );
        setAddLoading(false);
        toast.success(
          isSelected ? EditResponseTeamSuccess : AddResponseTeamSuccess,
        );
      })
      .catch(() => {
        setAddLoading(false);
        toast.error(GeneralError);
      });
  };

  return (
    <TechniciansTab
      {...restProps}
      projectTechnician={projectTechnician}
      technicians={technicians}
      allTechnicians={allTechnicians}
      handleTechniciansClick={handleTechniciansClick}
      addLoading={addLoading}
    />
  );
}

TechniciansResponseTeam.propTypes = {
  searchTerm: PropTypes.string,
  assignedTechnicians: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default TechniciansResponseTeam;
