import * as R from 'ramda';
import { toast } from 'react-toastify';
import { isNilOrEmpty, propEqLegacy } from '@poly/utils';
import { EQUALS_AIT_OPERATOR, CLIENT_ID_AIT } from '@poly/security';

import {
  AddStaffSuccess,
  EditStaffEnrollmentResent,
  EditStaffSuccess,
  GeneralError,
} from '../../constants/alerts.js';
import { setStaffModal } from '../../redux/actions/index.js';
import { checkEmailError } from '../../utils/staff/index.js';
import { getValue } from '../../util/general.js';
import { hasUserTechnicianGroup } from '../../utils/user/index.js';

export const selectUserStatus =
  ({ setUserStatus }) =>
  (status) =>
    setUserStatus(R.prop('value', status));

export const selectUserGroup =
  ({
    isAdd,
    selectedUser,
    shouldRefetch,
    validateField,
    setShouldRefetch,
    setUserGroupId,
  }) =>
  (userGroupOption) => {
    const newUserGroupId = userGroupOption?.value;
    setUserGroupId(newUserGroupId);

    if (!isAdd && selectedUser.userGroupId !== newUserGroupId) {
      setShouldRefetch(true);
    } else if (shouldRefetch) {
      setShouldRefetch(false);
    }

    validateField('userGroupId', newUserGroupId);
  };

export const selectTechnicianSkills =
  ({ setTechnicianSkills, validateField }) =>
  (skills) => {
    setTechnicianSkills(skills.map(R.prop('value')));
    validateField('technicianSkills', skills);
  };

// getStaffUserByMutationResponse :: {createUser: User, updateUser: User} -> User
const getStaffUserByMutationResponse = R.either(
  R.prop('createUserWithUserGroups'),
  R.prop('updateUserWithUserGroups'),
);
// prepareUserProfilePhones :: String -> String
const prepareUserProfilePhone = R.when(isNilOrEmpty, R.always(null));

// getClientIdVariableIdByGroups :: (ID, [UserGroup]) -> ID
const getClientIdVariableIdByGroups = (userGroupId, userGroups) =>
  R.compose(
    R.prop('id'),
    R.find(propEqLegacy('type', CLIENT_ID_AIT)),
    R.propOr([], 'variables'),
    R.find(propEqLegacy('_id', userGroupId)),
  )(userGroups);

export const handleSubmitStaffUser =
  ({
    userGroupId,
    userGroups,
    isAdd,
    validate,
    setError,
    dispatch,
    setLoading,
    userStatus,
    selectedUser,
    areSkillsEnabled,
    technicianSkills,
    clientId,
    createUser,
    updateUser,
    resendUserEnrollmentEmail,
    handleOpenStaffSidebar,
  }) =>
  async (event) => {
    event.preventDefault();
    setLoading(true);

    const firstName = getValue('firstName', event);
    const lastName = getValue('lastName', event);
    const email = getValue('email', event);
    const officePhone = getValue('officePhone', event);
    const mobile = getValue('mobile', event);

    const errors = validate({
      firstName,
      lastName,
      userGroupId,
      email,
      officePhone,
      mobile,
      ...(areSkillsEnabled && { technicianSkills }),
    });

    if (errors.isInvalid) {
      setLoading(false);
      return;
    }

    const clientVariableId = getClientIdVariableIdByGroups(
      userGroupId,
      userGroups,
    );
    const user = {
      firstName,
      lastName,
      userGroups: [
        {
          userGroupId,
          variables: [
            {
              variableId: clientVariableId,
              value: { [CLIENT_ID_AIT]: { [EQUALS_AIT_OPERATOR]: clientId } },
            },
          ],
        },
      ],
      status: userStatus,
      emails: [email],
      profile: {
        cellPhoneNumber: prepareUserProfilePhone(mobile),
        workPhoneNumber: prepareUserProfilePhone(officePhone),
      },
      ...(!isAdd && { userId: selectedUser._id }),
      ...(areSkillsEnabled && { technicianSkills }),
    };

    const mutation = isAdd ? createUser : updateUser;

    try {
      const { data } = await mutation(user);

      toast.success(isAdd ? AddStaffSuccess : EditStaffSuccess);

      if (
        !isAdd &&
        selectedUser.isSignedUp === false &&
        selectedUser.email !== email
      ) {
        await resendUserEnrollmentEmail({ userId: selectedUser._id });
        toast.success(EditStaffEnrollmentResent);
      }
      dispatch(setStaffModal(null));

      const staffUser = getStaffUserByMutationResponse(data);

      handleOpenStaffSidebar(
        staffUser,
        () => areSkillsEnabled && hasUserTechnicianGroup(staffUser),
      );
    } catch (error) {
      setLoading(false);
      if (checkEmailError(error.message)) {
        setError({ email: 'This email address is already in use' });
      }
      setError({ server: error.message });
      toast.error(GeneralError);
    }
  };
