import * as R from 'ramda';
import PropTypes, { func, instanceOf, oneOfType, string } from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React, { Component, useEffect, useState } from 'react';
import { Input } from '@poly/site-book';
import { isValidDate } from '@poly/utils';
import styled from 'styled-components';

import { ErrorText } from '../Form/ValidationMessage.js';

// eslint-disable-next-line
class DateInput extends Component {
  render() {
    return <Input {...this.props} name="start-date" />;
  }
}

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ErrorTextS = styled(ErrorText)`
  padding: 0;
`;

export function DatePicker({ input, selected, invalid, onChange, ...props }) {
  const [internalError, setInternalError] = useState(null);
  const [internalSelected, setInternalSelected] = useState(selected);

  const handleChange = (date) => {
    if (isValidDate(date)) {
      setInternalError(null);
      onChange(date);
    } else if (date) {
      setInternalError("Incorrect format, date won't be saved");
      onChange(undefined);
    }
    setInternalSelected(date);
  };

  useEffect(() => {
    if (!R.equals(selected, internalSelected)) {
      setInternalSelected(selected);
    }
  }, [selected]);

  return (
    <DatePickerWrapper>
      <ReactDatePicker
        customInput={input || <DateInput />}
        className={invalid ? 'react-datepicker__input--invalid' : ''}
        selected={internalSelected}
        onChange={handleChange}
        {...props}
      />
      <ErrorTextS>{internalError}</ErrorTextS>
    </DatePickerWrapper>
  );
}

DatePicker.propTypes = {
  invalid: PropTypes.bool,
  input: PropTypes.node,
  selected: oneOfType([string, instanceOf(Date)]),
  onBlur: func,
  onChange: func.isRequired,
};

DatePicker.defaultProps = {
  invalid: false,
  input: null,
};
