import React from 'react';
import PropTypes from 'prop-types';
import {
  Header,
  HeadingH1,
  Holder,
  Icon,
  IconButton,
  S,
} from '@poly/site-book';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { CREATE_PROJECT_PERMISSION } from '@poly/security';
import { FilterByStatus } from '@poly/site-ui';

import { UpRow } from '../../styled.js';
import { FILTERS } from '../../constants/report/master-recurring.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import TableSearchContainer from '../../containers/master-recurring-work-orders/search.js';
import { useProjectFilters } from '../../utils/projects/projects-filter.js';
import { useRecurringProjectsCountQuery } from '../../hooks/projects.js';
import { exportMasterRecurringWOToXLS } from './export-to-xls.js';

function EditMRProjectButton({ onAddProject }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_PROJECT_PERMISSION,
  );

  return (
    ifHasPermission && (
      <IconButton fill="#ff8d02" onClick={onAddProject} hover>
        <S type="title">Add New</S>
        <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
      </IconButton>
    )
  );
}

function FilterWrapper(props) {
  const { searchTerm, sort, query } = useProjectFilters(props);

  const { count } = useRecurringProjectsCountQuery({ searchTerm, sort, query });

  return <FilterByStatus {...props} count={count} />;
}

function MasterRecurrentReportFilterComponent({
  onClick,
  activeFilter,
  locationType,
  onAddProject,
}) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>Master Recurring Work Orders</HeadingH1>
        </Header.Block>
        <Header.Block>
          <EditMRProjectButton {...{ onAddProject }} />
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={10} center>
          {FILTERS.map(({ type, ...props }) => (
            <FilterWrapper
              active={activeFilter === type}
              key={type}
              typeFilter={type}
              {...{ onClick, locationType }}
              {...props}
            />
          ))}
        </Holder>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Master Recurring Work Orders"
            exportFunction={exportMasterRecurringWOToXLS}
            marginRight="20px"
          />
          <Holder margin={20}>
            <TableSearchContainer />
          </Holder>
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

MasterRecurrentReportFilterComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  onAddProject: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
  locationType: PropTypes.string.isRequired,
};

EditMRProjectButton.propTypes = {
  onAddProject: PropTypes.func.isRequired,
};

export default MasterRecurrentReportFilterComponent;
