import { isNilOrEmpty, removePropDeep } from '@poly/utils';
import * as R from 'ramda';
import { ACTIVE } from '../../constants/staff.js';
import { removePhoneFormatting } from '../../utils/formatters/index.js';

// preparePhone :: String -> String
const preparePhone = R.ifElse(
  isNilOrEmpty,
  R.always(null),
  removePhoneFormatting,
);

// nameOrFromFullName :: (String, [String] -> String) -> User -> String
const nameOrFromFullName = (nameProp, getName) =>
  R.ifElse(
    R.propIs(String, nameProp),
    R.prop(nameProp),
    R.compose(
      R.defaultTo(''),
      getName,
      R.split(' '),
      R.defaultTo(''),
      R.prop('fullName'),
      R.defaultTo({}),
    ),
  );

// getFirstName :: User -> String
export const getFirstName = nameOrFromFullName('firstName', R.head);

// getLastName :: User -> String
export const getLastName = nameOrFromFullName(
  'lastName',
  R.compose(R.join(' '), R.tail),
);

// getAddress :: User -> Object
export const getAddress = R.compose(
  removePropDeep('__typename'),
  R.converge(R.mergeRight, [R.prop('address'), R.pick(['addressTwo'])]),
);

// getDataForUpdateMutations :: Object -> Object
export function getDataForUpdateMutations({
  getRef,
  email,
  address,
  mobile,
  workPhone,
  fax,
  linkId,
  linkProp,
  isAdd,
  userId,
}) {
  return {
    ...(isAdd ? { status: ACTIVE } : { userId }),
    remarks: getRef().getEditor().getContents(),
    emails: email ? [email] : [],
    profile: {
      address: R.omit(['addressTwo'], address),
      addressTwo: R.propOr('', 'addressTwo', address),
      cellPhoneNumber: preparePhone(mobile),
      workPhoneNumber: preparePhone(workPhone),
      faxPhoneNumber: preparePhone(fax),
    },
    links: [{ [linkProp]: linkId }],
  };
}
