import { Grid, S } from '@poly/site-book';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const ErrorText = styled(S).attrs(() => ({
  type: 'content',
  size: 12,
}))`
  padding-left: 15px;
  color: #ee0d45;
  font-style: italic;
`;

export function ValidationMessage({ error }) {
  return error ? (
    <>
      <Grid.Cell />
      <Grid.Cell>
        <ErrorText>{error}</ErrorText>
      </Grid.Cell>
    </>
  ) : null;
}

ValidationMessage.propTypes = {
  error: PropTypes.string,
};

ValidationMessage.defaultProps = {
  error: null,
};
