import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import {
  DEFAULT_CLIENT_PROJECT_PRIORITIES,
  WorkOrderPriority,
} from '@poly/constants';
import { AbsoluteLoader } from '@poly/site-ui';
import { propEqLegacy } from '@poly/utils';

import { CORRECTIVES } from '../../constants/routes.js';
import CompletedPMsStats from '../../components/completed-pms-stats/index.js';
import { PREVENTIVE_MAINTENANCE } from '../../constants/projects.js';
import { normalizeProjectPrioritiesToDays } from '../../utils/projects/priority.js';
import {
  entityFormatter,
  fieldFormatter,
  noDataToDisplay,
} from '../../util/general.js';
import { useProjectFilters } from '../../utils/projects/projects-filter.js';
import {
  checkInTime,
  convertToPercents,
  getAverageDays,
  SelectDateRangeMessage,
} from '../../utils/projects/index.js';
import { useProjectsStatsQuery } from '../../hooks/projects.js';

const columnsPMS = [
  ["PM's", R.prop('name'), fieldFormatter],
  ['Total', R.prop('total'), fieldFormatter],
];

const columnsCorrective = [
  ['Correctives', R.prop('name'), fieldFormatter],
  ['Total', R.prop('total'), fieldFormatter],
];

// getNormalPriorityOffset :: { clientConfig: ClientConfigs } -> Number
const getNormalPriorityOffset = R.compose(
  R.prop('amount'),
  R.find(propEqLegacy('id', WorkOrderPriority.NORMAL)),
  normalizeProjectPrioritiesToDays,
  R.pathOr(DEFAULT_CLIENT_PROJECT_PRIORITIES, [
    'clientConfig',
    'projectPriorities',
  ]),
);

export default function (props) {
  const dispatch = useDispatch();

  const completedPMProjects = useSelector((state) => state.completedPMProjects);
  const user = useSelector((state) => state.user);

  const normalPriorityOffset = getNormalPriorityOffset(user);

  const { dateFilter, serviceTypeId, typeFilter, tableSearchString } =
    completedPMProjects;

  const startDate = dateFilter?.startDate;
  const endDate = dateFilter?.endDate;

  const propsForFilter = {
    typeFilter,
    locationType: CORRECTIVES,
    tableSearchString,
    startDate,
    endDate,
  };

  const { searchTerm, query, sort } = useProjectFilters(propsForFilter);

  const { stats, statsTotal, statsLoading, restProjectsProps } =
    useProjectsStatsQuery({ searchTerm, query, sort });

  if (!startDate && !endDate) return <SelectDateRangeMessage />;
  if (statsLoading) return <AbsoluteLoader />;
  if (!stats.length) return noDataToDisplay('Stats')();

  const statsPMs = stats.filter((it) => it.type === PREVENTIVE_MAINTENANCE);

  const totalCorrective = statsTotal - statsPMs.length;

  const projectsInTime = checkInTime(statsPMs);

  const rowsPMs = [
    {
      _id: uuidV4(),
      name: "PM's Completed",
      total: statsPMs.length,
    },
    {
      _id: uuidV4(),
      name: '% On Time',
      total: convertToPercents(projectsInTime.length, statsPMs.length),
    },
    {
      _id: uuidV4(),
      name: 'Average Days',
      total: getAverageDays(statsPMs),
    },
  ];
  const rowsCorrective = [
    {
      _id: uuidV4(),
      name: "Corrective WO's Created",
      total: totalCorrective,
    },
    {
      _id: uuidV4(),
      name: "% Of PM's",
      total: convertToPercents(totalCorrective, statsPMs.length),
    },
  ];

  const pms = entityFormatter(rowsPMs, columnsPMS);
  const correctives = entityFormatter(rowsCorrective, columnsCorrective);
  const gridColumns = 'repeat(2, minmax(100px, 1fr))';

  return (
    <CompletedPMsStats
      {...props}
      {...restProjectsProps}
      pms={pms}
      correctives={correctives}
      gridColumns={gridColumns}
      normalPriorityOffset={normalPriorityOffset}
      serviceTypeId={serviceTypeId}
      dispatch={dispatch}
    />
  );
}
