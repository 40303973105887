import * as R from 'ramda';
import { setCurrentSentryUser } from '@poly/client-utils';

import { setForgotPasswordMessage, signInError } from '../actions/index.js';
import { setDynamicTitle } from '../../utils/authorization/index.js';
import { setCurrentUserActionP } from './set-current-user-to-store.js';

const logIn = () => async (dispatch, getState) => {
  dispatch(signInError(null));
  dispatch(setForgotPasswordMessage(null));

  try {
    dispatch(await setCurrentUserActionP());
    const { user } = getState();
    setCurrentSentryUser(user);
    setDynamicTitle(user.clientId);
  } catch (e) {
    dispatch(signInError(R.prop('message', e)));
  }
};

export default logIn;
