import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FilterComponent from '../../components/date-filter/completed-wos.js';
import {
  setCompletedWOsDate,
  setCompletedWOsActiveTab,
  setCompletedWOsTableSearchString,
  setCompletedWOsPaginationCurrentPage,
  setCompletedWOsServiceType,
} from '../../redux/actions/index.js';

export default function (props) {
  const activeTab = useSelector((state) => state.completedProjects.activeTab);

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [serviceTypeId, setServiceTypeId] = useState(null);

  const onPreview = useCallback(() => {
    dispatch(setCompletedWOsDate({ startDate, endDate }));
    dispatch(setCompletedWOsServiceType(serviceTypeId));
    dispatch(setCompletedWOsTableSearchString(''));
    dispatch(setCompletedWOsPaginationCurrentPage(1));
  }, [
    dispatch,
    startDate,
    endDate,
    serviceTypeId,
    setCompletedWOsDate,
    setCompletedWOsServiceType,
    setCompletedWOsTableSearchString,
    setCompletedWOsPaginationCurrentPage,
  ]);

  const onClick = useCallback(
    (tab) => dispatch(setCompletedWOsActiveTab(tab)),
    [dispatch, setCompletedWOsActiveTab],
  );

  return (
    <FilterComponent
      {...props}
      activeTab={activeTab}
      onClick={onClick}
      isWO
      onPreview={onPreview}
      changeStartDate={setStartDate}
      changeEndDate={setEndDate}
      startDate={startDate}
      endDate={endDate}
      serviceTypeId={serviceTypeId}
      changeServiceType={setServiceTypeId}
    />
  );
}
