import * as R from 'ramda';
import React, { useCallback, useState } from 'react';
import { debounce } from '@poly/utils';
import OutsideClickHandler from 'react-outside-click-handler';

import { useDispatch, useSelector } from 'react-redux';
import { func, node } from 'prop-types';
import {
  setGlobalSearchStringAction,
  toggleGlobalSearchFocusAction,
} from '../../redux/globalSearch.js';
import { GlobalSearchInput } from './GlobalSearchInput.js';
import { GlobalSearchResult } from './GlobalSearchResult.js';

export function GlobalSearch({ onFocus, children }) {
  const dispatch = useDispatch();

  const onBlur = () => dispatch(toggleGlobalSearchFocusAction(false));

  const setGlobalSearchText = (searchText) =>
    dispatch(setGlobalSearchStringAction(searchText));

  const [searchValue, setSearchValue] = useState('');

  const setGlobalSearchStringDebounced = useCallback(
    debounce(700)((searchText) => {
      setGlobalSearchText(searchText);
    }),
    [],
  );

  const onChangeSearch = (event) => {
    const { value } = event.target;
    setGlobalSearchStringDebounced(value);
    setSearchValue(value);
  };

  const onClearSearch = () => {
    onBlur();
    setSearchValue('');
    setGlobalSearchText('');
  };

  const isSearchFilled = useSelector(
    R.path(['globalSearch', 'isSearchFilled']),
  );

  const onFocusHandler = () => {
    onFocus();
    dispatch(toggleGlobalSearchFocusAction(true));
  };

  return (
    <OutsideClickHandler onOutsideClick={onClearSearch}>
      <GlobalSearchInput
        setGlobalSearchText={setGlobalSearchText}
        isSearchFilled={isSearchFilled}
        onBlur={onBlur}
        onFocus={onFocusHandler}
        searchValue={searchValue}
        onChangeSearch={onChangeSearch}
        onClearSearch={onClearSearch}
      />
      <GlobalSearchResult isSearchFilled={isSearchFilled}>
        {children}
      </GlobalSearchResult>
    </OutsideClickHandler>
  );
}

GlobalSearch.propTypes = {
  onFocus: func.isRequired,
  children: node.isRequired,
};
