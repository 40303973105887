import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, func, shape, string } from 'prop-types';
import { MAX_FILE_NAME_CHARS_LIMIT } from '@poly/constants';
import {
  InputWithLengthLimit,
  IconButton,
  HeadingH3,
  HeadingH5,
  Heading,
  Button,
  Modal,
  Grid,
  Icon,
  S,
} from '@poly/site-book';

import { FileArea } from '../FileArea.js';
import { ServerError } from '../ServerError.js';
import { ButtonLoader } from '../ButtonLoader.js';
import { ErrorText } from '../Form/ValidationMessage.js';

const Title = styled(S)`
  ${({ required }) =>
    required &&
    `
      &::after {
        content: '*';
        color: #ee0d45;
      }
    `};
`;

const ModalGrid = Modal.Item.withComponent(Grid);

export function AddFileModalComp({
  file,
  errors,
  heading,
  loading,
  fileName,
  onSubmit,
  onChange,
  onAddFile,
  closeModal,
  onRemoveFile,
  isFileNameDisabled,
}) {
  return (
    <Modal show>
      <Modal.Item>
        <Heading>
          <HeadingH5>{heading}</HeadingH5>
          <IconButton onClick={closeModal} disabled={loading}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <ServerError error={R.prop('server', errors)} style={{ maxWidth: 430 }} />
      <Modal.Item>
        <Modal.Content>
          <HeadingH3 lighter>Add File</HeadingH3>
        </Modal.Content>
      </Modal.Item>
      <form {...{ onSubmit }}>
        <ModalGrid columns="100px minmax(200px, 320px)" margin={30} simple>
          <Grid.Row>
            <Grid.Cell vertical="center">
              <Title type="title" required>
                File Name
              </Title>
            </Grid.Cell>
            <Grid.Cell horizontal="stretch">
              <InputWithLengthLimit
                type="text"
                name="fileName"
                placeholder="File Name"
                disabled={isFileNameDisabled}
                invalid={!!R.prop('fileName', errors)}
                value={fileName}
                limit={MAX_FILE_NAME_CHARS_LIMIT}
                {...{ onChange }}
              />
            </Grid.Cell>
          </Grid.Row>
          {R.prop('fileName', errors) && (
            <ErrorText>{errors.fileName}</ErrorText>
          )}
          <Grid.Row>
            <Grid.Cell vertical="center">
              <Title type="title">File</Title>
            </Grid.Cell>
            <Grid.Cell horizontal="stretch">
              <FileArea {...{ onAddFile, onRemoveFile, file }} />
            </Grid.Cell>
          </Grid.Row>
        </ModalGrid>
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">Add Files</S>
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
}

AddFileModalComp.propTypes = {
  file: shape({
    name: string,
    src: string,
  }),
  isFileNameDisabled: bool.isRequired,
  loading: bool.isRequired,
  heading: string,
  fileName: string.isRequired,
  onChange: func.isRequired,
  closeModal: func.isRequired,
  onAddFile: func.isRequired,
  onRemoveFile: func.isRequired,
  onSubmit: func.isRequired,
  errors: shape({
    server: string,
    fileName: string,
  }),
};

AddFileModalComp.defaultProps = {
  heading: '',
  errors: {},
};
