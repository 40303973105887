import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { SearchGrid } from '@poly/site-ui';

import {
  poDescriptionFormatter,
  poNumberFormatter,
  poStatusFormatter,
} from '../../../utils/global-search/index.js';

const columnConfig = (search) => [
  [R.identity, poNumberFormatter(search)],
  [R.identity, poDescriptionFormatter(search)],
  [R.identity, poStatusFormatter(search)],
];

function POsGlobalSearch({ hits, searchTerm }) {
  const gridColumns = `
      minmax(80px, 110px)
      auto
      minmax(80px, 130px)
    `;

  return (
    <SearchGrid
      rows={hits}
      columnConfig={columnConfig}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
    />
  );
}

POsGlobalSearch.propTypes = {
  searchTerm: PropTypes.string,
  hits: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
};

export default POsGlobalSearch;
