import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AbsoluteLoader } from '@poly/site-ui';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { useProjectsQuery } from '../../hooks/projects.js';
import {
  dateFormatter,
  SelectDateRangeMessage,
} from '../../utils/projects/index.js';
import {
  highlightField,
  highlightProject,
} from '../../utils/highlight/index.js';
import usePagination from '../../hooks/usePagination.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  setCompletedPMsPaginationCurrentPage,
  setCompletedPMsPaginationDropdownLabel,
  setCompletedPMsPaginationPageSize,
  setCompletedPMsPaginationTotal,
} from '../../redux/actions/index.js';
import { useProjectFilters } from '../../utils/projects/projects-filter.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';

const getColumns = (search) => [
  ['WO #', R.identity, highlightProject(search)],
  ['Description', R.prop('description'), highlightField(search)],
  [
    'Equipment Type',
    R.path(['searchAssets', 'hits', 0, 'equipmentType']),
    highlightField(search),
  ],
  ['Due Date', R.prop('endDate'), dateFormatter(search)],
  ['Completion Date', R.prop('workCompletionDate'), dateFormatter(search)],
  [
    'Technician',
    R.path(['technicians', 0, 'fullName']),
    highlightField(search),
  ],
  ['Property', R.path(['property', 'name']), highlightField(search)],
];

export default function (props) {
  const dispatch = useDispatch();

  const completedPMProjects = useSelector((state) => state.completedPMProjects);

  const {
    currentPage,
    pageSize,
    dateFilter,
    serviceTypeId,
    locationType,
    typeFilter,
    tableSearchString,
    paginationDropdownLabel,
  } = completedPMProjects;

  const startDate = dateFilter?.startDate;
  const endDate = dateFilter?.endDate;

  const from = (currentPage - 1) * pageSize;

  const propsForFilter = {
    typeFilter,
    locationType,
    tableSearchString,
    startDate,
    endDate,
  };

  const { searchTerm, query, sort } = useProjectFilters(propsForFilter);

  const { projects, total, restProjectsProps, result } = useProjectsQuery({
    searchTerm,
    query,
    pageSize,
    from,
    sort,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setCompletedPMsPaginationTotal,
    setCurrent: setCompletedPMsPaginationCurrentPage,
    setPageSize: setCompletedPMsPaginationPageSize,
    setPaginationDropdownLabel: setCompletedPMsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns(tableSearchString));
  const headers = R.map(R.nth(0), getColumns(tableSearchString));
  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(4))(item),
    getColumns(tableSearchString),
  );
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns(tableSearchString),
  );
  const colsPropsValue = R.map(R.nth(3), getColumns(tableSearchString));
  const gridColumns = `
          110px
          minmax(100px, 1fr)
          minmax(80px, 200px)
          minmax(80px, 150px)
          minmax(80px, 200px)
          minmax(80px, 150px)
          minmax(100px, 1fr)
        `;

  const { rows, sorting, setSorting } = useSortableTable(
    projects,
    valuesToSort,
  );

  if (!startDate && !endDate) return <SelectDateRangeMessage />;
  if (restProjectsProps.loading) return <AbsoluteLoader />;
  if (!projects.length) return noDataToDisplay('Projects')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...result}
      {...restProjectsProps}
      props={colsPropsValue}
      rows={rows}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      sorting={sorting}
      setSorting={setSorting}
      dispatch={dispatch}
      serviceTypeId={serviceTypeId}
      valuesToSort={valuesToSort}
      searchTerm={searchTerm}
    />
  );
}
