import React from 'react';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { APPROVE_PO_INVOICE_PERMISSION } from '@poly/security';
import { PurchaseOrderStatus } from '@poly/constants';

import { AbsoluteLoader } from '@poly/site-ui';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { ApproveInvoiceError } from '../../constants/alerts.js';
import TableCheckbox from '../../components/checkboxes/table-checkbox.js';
import HeaderCheckbox from '../../components/checkboxes/header-checkbox.js';
import {
  highlightCurrency,
  highlightField,
} from '../../utils/highlight/index.js';
import { useInvoicesFilters } from '../../utils/invoices/invoices-filters.js';
import { InvoiceFormatter } from '../../utils/invoices/index.js';
import {
  POFormatter,
  projectPOFormatter,
  propertySelector,
  scopeFormatter,
} from '../../utils/purchase-orders/index.js';
import {
  setInvoicesPaginationCurrentPage,
  setInvoicesPaginationDropdownLabel,
  setInvoicesPaginationPageSize,
  setInvoicesPaginationTotal,
  setSelectedInvoices,
  toggleInvoice,
} from '../../redux/actions/index.js';
import { noDataToDisplay } from '../../util/general.js';
import { checkPermissionByOneAccessItem } from '../../utils/user/index.js';
import { useInvoicesQuery } from '../../hooks/invoices.js';
import usePagination from '../../hooks/usePagination.js';

const STATE_PATH = ['invoices', 'selectedInvoices'];
const INVOICE_OPEN_STATUS = 'Open';

const getColumns = ({
  invoices,
  dispatch,
  tableSearchString: search,
  user,
  getInvoiceStatus,
}) => {
  const commonProps = {
    disabled: !checkPermissionByOneAccessItem(
      APPROVE_PO_INVOICE_PERMISSION,
      user,
      R.pick(['clientId'], user),
    ),
    showErrorMessage: () => toast.error(ApproveInvoiceError),
  };
  return [
    [
      <HeaderCheckbox
        statePath={STATE_PATH}
        pageItems={invoices.map(({ _id }) => _id)}
        onChange={R.compose(dispatch, setSelectedInvoices)}
        {...commonProps}
      />,
      R.prop('_id'),
      (id) => (
        <TableCheckbox
          statePath={STATE_PATH}
          onChange={R.compose(dispatch, toggleInvoice)}
          id={id}
          {...commonProps}
        />
      ),
    ],
    ['PO #', R.prop('purchaseOrder'), POFormatter(search, getInvoiceStatus)],
    ['Invoice #', R.identity, InvoiceFormatter(search)],
    ['Amount', R.prop('total'), highlightCurrency(search)],
    ['Scope', R.path(['purchaseOrder', 'isInScope']), scopeFormatter(search)],
    ['WO #', R.prop('project'), projectPOFormatter(search)],
    [
      'Supplier',
      R.path(['supplier', 'company', 'name']),
      highlightField(search),
    ],
    ['Property', propertySelector, highlightField(search)],
  ];
};

// getInvoiceStatus :: Invoice -> String
const getInvoiceStatus = (purchaseOrder) =>
  R.path(['searchPOInvoices', 'total'], purchaseOrder) > 0 &&
  R.prop('status', purchaseOrder) !== PurchaseOrderStatus.CLOSED &&
  INVOICE_OPEN_STATUS;

export default function InvoicesList(props) {
  const dispatch = useDispatch();

  const invoicesState = useSelector((state) => state.invoices);
  const user = useSelector((state) => state.user);

  const {
    type,
    currentPage,
    pageSize,
    paginationDropdownLabel,
    tableSearchString,
  } = invoicesState;

  const from = (currentPage - 1) * pageSize;

  const { searchTerm, query, size } = useInvoicesFilters(invoicesState);

  const { invoices, total, invoicesLoading, restInvoicesProps } =
    useInvoicesQuery({ searchTerm, query, pageSize: size, from });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setInvoicesPaginationTotal,
    setPageSize: setInvoicesPaginationPageSize,
    setCurrent: setInvoicesPaginationCurrentPage,
    setPaginationDropdownLabel: setInvoicesPaginationDropdownLabel,
    total,
    currentPage,
  });

  const cols = getColumns({
    invoices,
    dispatch,
    tableSearchString,
    user,
    getInvoiceStatus,
  });

  const columns = R.map(R.nth(1), cols);
  const headers = R.map(R.nth(0), cols);
  const formats = R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), cols);
  const colsPropsValue = R.map(R.nth(3), cols);

  const gridColumns = `
        16px
        minmax(80px, 150px)
        minmax(80px, 150px)
        100px
        100px
        minmax(80px, 150px)
        minmax(150px, 1fr)
        minmax(150px, 1fr)
      `;

  if (invoicesLoading) return <AbsoluteLoader />;
  if (!invoices.length) return noDataToDisplay('Invoices Pending Approvals')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restInvoicesProps}
      props={colsPropsValue}
      user={user}
      type={type}
      rows={invoices}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      tableSearchString={tableSearchString}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      getInvoiceStatus={getInvoiceStatus}
      invoicesLoading={invoicesLoading}
      searchTerm={searchTerm}
    />
  );
}
