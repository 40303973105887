import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@poly/site-book';

import { Table } from '../../table/table.js';
import { commonTablePropTypes } from '../../common-prop-types.js';

function AssetProjects({ projects }) {
  return (
    <Widget.Item>
      <Table
        gridColumns="
        minmax(90px, 280px)
        minmax(150px,1fr)
        minmax(80px, 140px)
      "
        {...projects}
      />
    </Widget.Item>
  );
}

AssetProjects.propTypes = {
  projects: PropTypes.shape(commonTablePropTypes).isRequired,
};

export default AssetProjects;
