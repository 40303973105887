import styled from 'styled-components';

export const Heading = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Base = styled.h1`
  margin: 0;
  padding: 0;
  color: ${({ lighter }) => (lighter ? '#5e6271' : '#2d2f33')};
  font-family: ${({ theme }) => theme.fonts.meta};
  font-weight: 500;
`;

export const HeadingH1 = styled(Base.withComponent('h1'))`
  font-size: 28px;
`;

export const HeadingH2 = styled(Base.withComponent('h2'))`
  font-size: 24px;
`;

export const HeadingH3 = styled(Base.withComponent('h3'))`
  font-size: 20px;
`;

export const HeadingH4 = styled(Base.withComponent('h4'))`
  font-size: 16px;
`;

export const HeadingH5 = styled(Base.withComponent('h5'))`
  font-size: 14px;
`;

Heading.displayName = 'Heading';
HeadingH1.displayName = 'HeadingH1';
HeadingH2.displayName = 'HeadingH2';
HeadingH3.displayName = 'HeadingH3';
HeadingH4.displayName = 'HeadingH4';
HeadingH5.displayName = 'HeadingH5';
