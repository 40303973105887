import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { propEqLegacy } from '@poly/utils';

import TechniciansTab from '../../components/project/details/response-team/technicians-tab.js';
import ManagersTab from '../../components/project/details/response-team/manager-tab.js';
import SuppliersTab from '../../components/project/details/response-team/suppliers-tab.js';
import { DropDownLoader } from '../../util/dropdown.js';
import { prepareTechniciansWithoutSelected } from '../pm-projects/technicians-modal/prepareTechniciansWithoutSelected.js';

function Staff({
  activeTab,

  allTechnicians,
  allManagers,
  allSuppliers,

  handleTechniciansClick,
  handleManagersClick,
  handleSuppliersClick,

  projectTechnician,
  projectManager,
  projectSuppliers,
}) {
  switch (activeTab) {
    case 'technicians':
      return (
        <TechniciansTab
          handleTechniciansClick={handleTechniciansClick}
          allTechnicians={allTechnicians}
          projectTechnician={projectTechnician}
        />
      );

    case 'manager':
      return (
        <ManagersTab
          handleManagersClick={handleManagersClick}
          allManagers={allManagers}
          projectManager={projectManager}
        />
      );

    case 'suppliers':
      return (
        <SuppliersTab
          handleSuppliersClick={handleSuppliersClick}
          allSuppliers={allSuppliers}
          projectSuppliers={projectSuppliers}
        />
      );

    default:
      return null;
  }
}

const shouldRenderResponseTab = ({ allTechnicians, managers, allSuppliers }) =>
  !allTechnicians || !managers || !allSuppliers;

// getManagersWithoutSelected :: [User] -> ID -> [User]
const getManagersWithoutSelected = R.curry((managers, selectedId) =>
  R.compose(
    R.reject(propEqLegacy('_id', selectedId)),
    R.defaultTo([]),
  )(managers),
);

// getSuppliersWithoutSelected :: [Suppliers] -> [Suppliers] -> [Suppliers]
const getSuppliersWithoutSelected = R.curry((suppliers, selected) =>
  R.compose(
    R.map(
      R.applySpec({
        _id: R.prop('_id'),
        email: R.pathOr('', ['company', 'emails', 0, 'email']),
        name: R.path(['company', 'name']),
      }),
    ),
    R.reject(
      R.compose(R.includes(R.__, R.pluck('_id', selected)), R.prop('_id')),
    ),
    R.defaultTo([]),
  )(suppliers),
);

Staff.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  activeTab: PropTypes.string.isRequired,
  allTechnicians: PropTypes.array,
  allManagers: PropTypes.array,
  allSuppliers: PropTypes.array,

  handleTechniciansClick: PropTypes.func.isRequired,
  handleManagersClick: PropTypes.func.isRequired,
  handleSuppliersClick: PropTypes.func.isRequired,

  projectTechnician: PropTypes.object,
  projectManager: PropTypes.object,
  projectSuppliers: PropTypes.array,
};

Staff.defaultProps = {
  projectTechnician: null,
  projectManager: null,
  projectSuppliers: [],
  allTechnicians: null,
  allManagers: null,
  allSuppliers: null,
};

export default function CreateResponseTeam(props) {
  const {
    allTechnicians,
    managers,
    allSuppliers,
    projectTechnician,
    projectManager,
    projectSuppliers,
  } = props;

  const allTechniciansValue = prepareTechniciansWithoutSelected(
    allTechnicians,
    projectTechnician,
  );

  const allManagersValue = getManagersWithoutSelected(managers, projectManager);

  const allSuppliersValue = getSuppliersWithoutSelected(
    allSuppliers,
    projectSuppliers,
  );

  if (
    shouldRenderResponseTab({
      allSuppliers: allManagersValue,
      allTechnicians: allTechniciansValue,
      managers,
    })
  )
    return <DropDownLoader />;

  return (
    <Staff
      {...props}
      managers={managers}
      allTechnicians={allTechniciansValue}
      allSuppliers={allSuppliersValue}
      allManagers={allManagersValue}
    />
  );
}

CreateResponseTeam.propTypes = {
  allTechnicians: PropTypes.array,
  allSuppliers: PropTypes.array,
  projectTechnician: PropTypes.object,
  projectManager: PropTypes.object,
  projectSuppliers: PropTypes.array,
  managers: PropTypes.array,
};
