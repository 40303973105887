import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { Loader } from '@poly/site-ui';
import People from '../../../../components/supplier/tabs/people.js';
import {
  setSupplierPeopleModal,
  setSupplierSidebar,
} from '../../../../redux/actions/index.js';
import { entityFormatter, fieldFormatter } from '../../../../util/general.js';
import { propertyUserFormatter } from '../../../../util/property-utils.js';
import { peopleStatusFormatter } from '../../../../utils/suppliers/constructions.js';
import { useSupplierPeople } from '../../../../hooks/suppliers.js';

const peopleColumns = (dispatch, action) => [
  ['Name', R.identity, propertyUserFormatter(dispatch, action)],
  ['Title', R.prop('title'), fieldFormatter],
  ['Status', R.prop('status'), peopleStatusFormatter],
];

export default function (props) {
  const dispatch = useDispatch();

  const supplierId = useSelector((state) => state.location.payload.id);
  const selectedUser = useSelector((state) => state.suppliers.modals.sidebar);

  const { people, defaultAddress, peopleLoading } =
    useSupplierPeople(supplierId);

  const openAddPeopleModal = useCallback(
    () =>
      dispatch(
        setSupplierPeopleModal({
          type: 'add',
          data: { defaultAddress },
        }),
      ),
    [dispatch, setSupplierPeopleModal, defaultAddress],
  );

  const openEditPeopleModal = useCallback(
    () =>
      dispatch(
        setSupplierPeopleModal({
          type: 'edit',
          data: { defaultAddress },
        }),
      ),
    [dispatch, setSupplierPeopleModal, defaultAddress],
  );

  const closeSidebar = useCallback(
    () => dispatch(setSupplierSidebar(null)),
    [dispatch, setSupplierSidebar],
  );

  const peopleObj = {
    ...entityFormatter(people, peopleColumns(dispatch, setSupplierSidebar)),
    gridColumns: `
        minmax(100px, 250px)
        auto
        100px
      `,
  };

  if (peopleLoading) return <Loader />;

  return (
    <People
      {...props}
      people={peopleObj}
      closeSidebar={closeSidebar}
      openEditPeopleModal={openEditPeopleModal}
      openAddPeopleModal={openAddPeopleModal}
      selectedUser={selectedUser}
      defaultAddress={defaultAddress}
    />
  );
}
