import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUIMasterProjectStatusText } from '@poly/client-utils';
import { getUserCellPhoneNumber } from '@poly/utils';
import { Loader } from '@poly/site-ui';

import { SUPPLIERS_EMAILS } from '../../constants/suppliers.js';
import RecurringProjectDetails from '../../components/mr-project/details.js';
import { setActiveProject } from '../../redux/actions/index.js';
import { formatDateWithDashes } from '../../util/general.js';
import {
  companyPhoneFormatter,
  getEmailByType,
} from '../../utils/suppliers/index.js';
import {
  useMRProjectSubscriptionQuery,
  useMRProjectDetails,
} from '../../hooks/project-details.js';

const commonUserFields = {
  _id: R.prop('_id'),
  firstName: R.prop('firstName'),
  lastName: R.prop('lastName'),
  email: R.path(['emails', 0, 'address']),
  phone: getUserCellPhoneNumber,
};

// getManagerByProject :: Project -> Manager
export const getManagerByProject = R.compose(
  R.unless(
    R.isEmpty,
    R.applySpec({
      ...commonUserFields,
    }),
  ),
  R.defaultTo({}),
  R.prop('manager'),
);

// getTechniciansByProject :: Project -> [Technician]
export const getTechniciansByProject = R.compose(
  R.map(
    R.applySpec({
      ...commonUserFields,
      userGroups: R.propOr([], 'userGroups'),
    }),
  ),
  R.defaultTo([]),
  R.prop('technicians'),
);

// getProject :: Project -> Object
function getProject(project) {
  return {
    description: project.description,
    location: project.location,
    property: project.property,
    schedule: project.schedule,
    status: getUIMasterProjectStatusText(project),
    details: project.invoiceDescription,
    type: project.serviceType.name,
    client: project.client.name,
    manager: getManagerByProject(project),

    technicians: getTechniciansByProject(project),
    suppliers: R.map(
      (supplier) => ({
        _id: supplier._id,
        name: supplier.company.name,
        email: getEmailByType(
          supplier.company.emails,
          SUPPLIERS_EMAILS.SERVICE,
        ),
        phone: companyPhoneFormatter(supplier.company.phones || []),
      }),
      project.suppliers,
    ),
    startDate: formatDateWithDashes(project.startDate),
    endDate: formatDateWithDashes(project.endDate),
  };
}

// pickReduxProjectFields :: Project -> ReduxProject
const pickReduxProjectFields = R.pick([
  '_id',
  'type',
  'status',
  'client',
  'manager',
  'endDate',
  'property',
  'schedule',
  'location',
  'suppliers',
  'startDate',
  'serviceType',
  'technicians',
  'description',
  'invoiceDescription',
]);

export default function (props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);

  const { loading, project } = useMRProjectDetails(projectId);

  const propsOfComponent = { ...props, projectId, _id: project?._id };

  const { restProps } = useMRProjectSubscriptionQuery(propsOfComponent);

  useEffect(() => {
    if (project) {
      dispatch(setActiveProject(pickReduxProjectFields(project)));
    }
  }, [project]);

  if (loading || !project) return <Loader />;

  const projectObj = getProject(project);

  return (
    <RecurringProjectDetails {...props} {...restProps} project={projectObj} />
  );
}
