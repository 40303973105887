import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ConfirmModal } from '@poly/site-ui';

import { useRemoveMaterials } from '../../../../hooks/project-job-costs.js';
import {
  DeleteMaterialSuccess,
  GeneralError,
} from '../../../../constants/alerts.js';
import { setMaterialsModal } from '../../../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const materialsModal = useSelector(
    (state) => state.projects.modals.materials,
  );

  const activeProjectId = useSelector(
    (state) => state.projects.activeProject.projectId,
  );

  const { removeMaterial } = useRemoveMaterials();

  const closeModal = useCallback(
    () => dispatch(setMaterialsModal(null)),
    [dispatch, setMaterialsModal],
  );

  const onConfirm = useCallback(() => {
    setLoading(true);

    removeMaterial(materialsModal.data._id, activeProjectId)
      .then(() => {
        setLoading(false);
        dispatch(setMaterialsModal(null));
        toast.success(DeleteMaterialSuccess);
      })
      .catch(() => {
        setLoading(false);
        toast.error(GeneralError);
      });
  }, [dispatch, setLoading, materialsModal, removeMaterial, activeProjectId]);

  const showModal = materialsModal?.type === 'remove';
  const subHeading = 'Delete Material?';
  const text = 'Are you sure that you want to delete the selected material?';

  if (!showModal) return null;

  return (
    <ConfirmModal
      {...props}
      closeModal={closeModal}
      onConfirm={onConfirm}
      loading={loading}
      showModal={showModal}
      heading={activeProjectId}
      subHeading={subHeading}
      text={text}
    />
  );
}
