import * as R from 'ramda';
import { propertiesForSelectQuery } from '@poly/site-ui';
import { SORTING } from '../constants/index.js';
import { ACTIVE, INACTIVE } from '../constants/properties.js';
import { getProperties, propertiesChanged } from '../queries/index.js';
import { useReactiveEntities } from './useReactiveEntities.js';

const filterQueryHash = {
  [ACTIVE]: {
    bool: {
      must: { match: { status: ACTIVE } },
    },
  },
  [INACTIVE]: {
    bool: {
      must: { match: { status: INACTIVE } },
    },
  },
};

export const usePropertiesListQuery = ({
  forSelect,
  searchTerm,
  from,
  pageSize,
  typeFilter,
}) => {
  const { sort } = SORTING;
  const query = filterQueryHash[typeFilter];

  const { data, loading, result } = useReactiveEntities({
    gqlQuery: forSelect ? propertiesForSelectQuery : getProperties,
    gqlChangedQuery: propertiesChanged,
    searchTerm,
    query,
    sort,
    from,
    pageSize,
    alias: 'usePropertiesListQuery',
  });

  const properties = data?.searchProperties.hits || [];
  const total = data?.searchProperties.total || 0;

  return {
    propertyLoading: loading || false,
    properties,
    total,
    data,
    result,
  };
};

export const usePropertiesQuery = (search, typeFilter) => {
  const noPropertyResults = R.ifElse(
    R.isEmpty,
    R.always(null),
    R.always('No Results Found'),
  )(search);

  const query = filterQueryHash[typeFilter];
  const { sort } = SORTING;

  const { propertyLoading, properties, total, data, result } =
    usePropertiesListQuery({
      forSelect: true,
      searchTerm: search,
      query,
      sort,
    });

  return {
    propertyLoading,
    properties,
    total,
    data,
    result,
    searchTerm: search,
    query,
    noPropertyResults,
  };
};

export const usePropertiesCountQuery = ({ typeFilter }) => {
  const query = filterQueryHash[typeFilter];

  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: getProperties,
      gqlChangedQuery: propertiesChanged,
      query,
      alias: 'usePropertiesCountQuery',
    });

  const count = data?.searchProperties.total || 0;

  return {
    count,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};
