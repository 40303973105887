import { useState, useCallback, useEffect } from 'react';
import { removePropDeep } from '@poly/utils';
import { getValue } from '../../util/general.js';
import useValidation from '../../hooks/useValidation.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';
import { getDataForUpdateMutations } from './helpers.js';
import {
  useCreateContactMutationHandler,
  useEditModalHandler,
} from './hooks.js';

export function usePeopleEnhancer({
  modal,
  selectedUser,
  onModalClose,
  onComplete,
  defaultAddress,
  isAdd,
  linkId,
  linkProp,
}) {
  const [address, setAddress] = useState('');
  const [emailState, setEmailState] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isAdd) {
      setAddress(removePropDeep('__typename')(defaultAddress));
    }

    return () => {
      setEmailState('');
    };
  }, [isAdd]);

  const {
    firstNameValue,
    lastNameValue,
    workPhoneValue,
    mobileValue,
    faxValue,
    titleValue,
    remarksValue,
    userId,
    emailValue,
  } = useEditModalHandler({
    setAddress,
    selectedUser,
  });

  const { errors, setError, onChange, validateOnBlur, resetError, validate } =
    useValidation({
      validationRules: {
        firstName: [{ rule: 'required' }],
        lastName: [{ rule: 'required' }],
        email: [{ rule: 'email' }],
        workPhone: [{ rule: 'phone' }],
        mobile: [{ rule: 'phone' }],
        fax: [{ rule: 'phone' }],
        address: [{ rule: 'zipLength' }, { rule: 'zip' }, { rule: 'address' }],
      },
    });

  const { setRef, getRef } = useRefHandlers();

  const closeModal = useCallback(() => {
    onModalClose();
    setEmailState('');
  }, [onModalClose]);

  const { finalMutation } = useCreateContactMutationHandler({
    onComplete,
    setLoading,
    isAdd,
    onModalClose,
    setError,
  });

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setLoading(true);

      const firstName = getValue('firstName', event);
      const lastName = getValue('lastName', event);
      const title = getValue('title', event);
      const workPhone = getValue('workPhone', event);
      const mobile = getValue('mobile', event);
      const fax = getValue('fax', event);
      const email = getValue('email', event);

      const errorsObj = validate({
        firstName,
        lastName,
        email,
        workPhone,
        mobile,
        fax,
        address,
      });

      if (errorsObj.isInvalid) {
        setLoading(false);
        return false;
      }

      const update = getDataForUpdateMutations({
        getRef,
        email,
        address,
        mobile,
        workPhone,
        fax,
        linkId,
        linkProp,
        isAdd,
        userId,
      });

      return finalMutation({
        update: { ...update, firstName, lastName, title },
      });
    },
    [
      onModalClose,
      linkProp,
      linkId,
      onComplete,
      selectedUser,
      address,
      setError,
      setLoading,
      validate,
      userId,
      isAdd,
    ],
  );

  return {
    modal,
    isAdd,
    loading,
    closeModal,
    onSubmit,
    setRef,
    remarks: remarksValue,
    onChange,
    validateOnBlur,
    resetError,
    errors,
    firstName: firstNameValue,
    lastName: lastNameValue,
    title: titleValue,
    workPhone: workPhoneValue,
    mobile: mobileValue,
    fax: faxValue,
    email: !isAdd ? emailValue : emailState,
    address,
    setAddress,
    setEmail: setEmailState,
    linkId,
    linkProp,
  };
}
