import { toast } from 'react-toastify';
import { ALERTS } from '@poly/site-ui';
import { useDispatch } from 'react-redux';
import { useEffect, useCallback } from 'react';
import { MAX_SIZE } from '../constants/index.js';

const usePagination = ({
  setCurrent = () => {},
  setTotal = () => {},
  setPageSize = () => {},
  setPaginationDropdownLabel = () => {},
  total,
  currentPage,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (total) {
      dispatch(setTotal(total));
    }
  }, [dispatch, total]);

  const onChange = useCallback(
    (current) => dispatch(setCurrent(current)),
    [dispatch],
  );

  const onShowSizeChange = useCallback(
    ({ value, label }) => {
      if (value === MAX_SIZE) {
        toast.error(ALERTS.MaxRangeError);
        return;
      }
      const newCurrent = Math.ceil(total / value);

      dispatch(setCurrent(currentPage > newCurrent ? newCurrent : currentPage));
      dispatch(setPageSize(value));
      dispatch(setPaginationDropdownLabel(label));
    },
    [dispatch, total, currentPage],
  );

  return {
    onChange,
    onShowSizeChange,
    showPagination: true,
  };
};

export default usePagination;
