import { subDays } from 'date-fns';
import { ensureIsDate } from '@poly/utils';
import {
  LOGIN_APP_PERMISSION,
  MOBILE_APP_NAME,
  SITE_APP_NAME,
  getSearchAccessItemsQueryByAIT,
  CLIENT_ID_AIT,
  CLIENT_APP_AIT,
} from '@poly/security';
import { UserStatuses, WorkOrderStatus } from '@poly/constants';
import { getTechnicianUsersElasticQuery } from '@poly/client-utils/src/elasticQueries.js';

const currentDate = new Date();
const activeProjectStatuses = [
  WorkOrderStatus.ACTIVE,
  WorkOrderStatus.IN_PROCESS,
  WorkOrderStatus.ON_HOLD_CLIENT,
  WorkOrderStatus.ON_HOLD_PARTS,
  WorkOrderStatus.ON_HOLD_PROJECT,
];

const requestArray = [
  {
    terms: {
      status: activeProjectStatuses,
    },
  },
];

const additionalUsersQuery = [
  getSearchAccessItemsQueryByAIT(
    CLIENT_APP_AIT,
    [{ term: { 'accessItems.permission': LOGIN_APP_PERMISSION } }],
    [SITE_APP_NAME, MOBILE_APP_NAME],
  ),
];

export const commonUsersQuery = (clientId) => [
  {
    nested: {
      path: 'userGroups',
      query: { exists: { field: 'userGroups' } },
    },
  },
  clientId
    ? getSearchAccessItemsQueryByAIT(
        CLIENT_ID_AIT,
        additionalUsersQuery,
        clientId,
      )
    : {
        nested: {
          path: 'accessItems',
          query: { bool: { should: additionalUsersQuery } },
        },
      },
];

export const activeProjectsQuery = {
  bool: {
    must: requestArray,
  },
};

export const overdueProjectsQuery = {
  bool: {
    must: requestArray,
    filter: {
      range: {
        endDate: { lt: currentDate },
      },
    },
  },
};

export const lastThirtyProjectsQuery = {
  bool: {
    must: [{ term: { status: WorkOrderStatus.COMPLETED } }],
    filter: {
      range: {
        workCompletionDate: { gt: subDays(ensureIsDate(currentDate), 30) },
      },
    },
  },
};

export const techniciansQuery = (clientId) => ({
  bool: {
    must: [...commonUsersQuery(clientId), getTechnicianUsersElasticQuery()],
  },
});

export const managersQuery = (clientId) => ({
  bool: {
    must: [
      ...commonUsersQuery(clientId),
      { bool: { must_not: getTechnicianUsersElasticQuery() } },
    ],
  },
});

export const requestersQuery = { term: { status: UserStatuses.ACTIVE } };

export const allStaffQuery = (clientId) => ({
  bool: {
    must: commonUsersQuery(clientId),
  },
});
