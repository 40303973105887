import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { SearchGrid } from '@poly/site-ui';

import {
  staffLinkFormatter,
  staffDescriptionFormatter,
} from '../../../utils/global-search/index.js';

const columnConfig = (search) => [
  [R.identity, staffLinkFormatter(search)],
  [R.identity, staffDescriptionFormatter(search)],
];
function StaffGlobalSearch({ hits, searchTerm }) {
  const gridColumns = `
        minmax(100px, 200px)
        auto
      `;

  return (
    <SearchGrid
      rows={hits}
      columnConfig={columnConfig}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
    />
  );
}

StaffGlobalSearch.propTypes = {
  searchTerm: PropTypes.string,
  hits: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
};

export default StaffGlobalSearch;
