import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Heading, HeadingH5, Pip, S, Widget } from '@poly/site-book';
import { capitalizeFirstLetter } from '@poly/client-utils';

import { DetailsRow } from '../components/DetailsRow.js';
import { AddressDetailsRow } from '../components/AddressDetailsRow.js';

const Title = styled(Heading)`
  margin-bottom: 20px;
`;

const checkPipColor = (status) => (status === 'active' ? '#9bdc53' : '#ff8c00');

const formatNumberWithCommas = (number) =>
  Number.isInteger(number)
    ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : null;

export function PropertyDetailsCard({
  specialPropertyFields,
  property: {
    path,
    status,
    address,
    addressTwo,
    workZone,
    occupancy,
    ownership,
    squareFootage,
    region,
    regionCode,
    clientUnitNumber,
  },
}) {
  return (
    <>
      <Widget.Item>
        <Title>
          <HeadingH5>Property Details</HeadingH5>
          <Pip color={checkPipColor(status)}>
            <S type="title" textColor="#4b5a8f">
              {status}
            </S>
          </Pip>
        </Title>
        <Grid columns="120px 1fr" simple>
          <AddressDetailsRow
            title="Address"
            value={R.prop('address_parts', address)}
            additional={addressTwo}
          />
        </Grid>
      </Widget.Item>
      <Widget.Item>
        <Title>
          <HeadingH5>Building Information</HeadingH5>
        </Title>
        <Grid columns="120px 1fr" simple>
          <DetailsRow
            title="Occupancy"
            value={capitalizeFirstLetter(occupancy)}
          />
          <DetailsRow
            title="Ownership"
            value={capitalizeFirstLetter(ownership)}
          />
          {specialPropertyFields && (
            <>
              <DetailsRow
                title="SQ Footage"
                value={formatNumberWithCommas(squareFootage)}
              />
              <DetailsRow
                title="Work Zone"
                value={capitalizeFirstLetter(workZone)}
              />
              <DetailsRow title="Path" value={capitalizeFirstLetter(path)} />
              <DetailsRow
                title="Region"
                value={capitalizeFirstLetter(region)}
              />
              <DetailsRow title="Region Code" value={regionCode} />
              <DetailsRow title="Business Unit #" value={clientUnitNumber} />
            </>
          )}
        </Grid>
      </Widget.Item>
    </>
  );
}

PropertyDetailsCard.propTypes = {
  specialPropertyFields: PropTypes.bool.isRequired,
  property: PropTypes.shape({
    status: PropTypes.oneOf(['active', 'inactive']),
    address: PropTypes.shape({
      formatted_address: PropTypes.string,
    }),
    addressTwo: PropTypes.string,
    path: PropTypes.string,
    workZone: PropTypes.string,
    occupancy: PropTypes.string,
    ownership: PropTypes.string,
    squareFootage: PropTypes.number,
    region: PropTypes.string,
    regionCode: PropTypes.string,
    clientUnitNumber: PropTypes.string,
    clientOuterId: PropTypes.string,
    clientExternalId: PropTypes.string,
    clientUnitName: PropTypes.string,
    typeByClient: PropTypes.string,
    square: PropTypes.string,
    tier: PropTypes.string,
  }).isRequired,
};
