import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { SearchGrid } from '@poly/site-ui';

import { propertiesDescriptionFormatter } from '../../../utils/global-search/index.js';
import { statusFormatter } from '../../../util/property-utils.js';

const columnConfig = (search) => [
  [R.identity, propertiesDescriptionFormatter(search)],
  [R.prop('status'), statusFormatter(search)],
];
function PropertiesGlobalSearch({ hits, searchTerm }) {
  const gridColumns = `
      auto
      minmax(80px, 130px)
    `;

  return (
    <SearchGrid
      rows={hits}
      columnConfig={columnConfig}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
    />
  );
}

PropertiesGlobalSearch.propTypes = {
  searchTerm: PropTypes.string,
  hits: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
};

export default PropertiesGlobalSearch;
