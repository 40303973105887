import * as R from 'ramda';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState, useCallback } from 'react';
import { UPDATE_PURCHASE_ORDER_PERMISSION } from '@poly/security';
import { useHasUserAccessWithPermission } from '@poly/client-utils';

import {
  CANCELLED,
  PENDING_APPROVAL,
} from '../../constants/purchase-orders.js';
import { STATUSES } from '../../constants/invoices.js';
import { EditPOError } from '../../constants/alerts.js';
import { checkIfAllInvoicesAttached } from '../invoices/index.js';
import { modifyPOToAccessItemTypes } from './pos-utils.js';
import { setPOsModal } from '../../redux/actions/index.js';

// getCreatedById :: PurchaseOrder -> ID
const getCreatedById = R.pathOr(null, ['createdBy', '_id']);

/**
 * usePOPermissionsHelper :: String -> String -> {Helpers}
 */
export const usePOPermissionsHelper = ({
  orderProp,
  userProp,
  handlerName = null,
  restProps,
}) => {
  const dispatch = useDispatch();

  const order = R.propOr({}, orderProp, restProps);
  const user = R.propOr({}, userProp, restProps);

  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_PURCHASE_ORDER_PERMISSION,
    modifyPOToAccessItemTypes(order),
  );

  const isOwnPo = user._id === getCreatedById(order);

  const isReadyToClose = checkIfAllInvoicesAttached(order);

  const isAvailableForAll =
    (order.status === STATUSES.APPROVED ||
      order.status === PENDING_APPROVAL ||
      order.status === CANCELLED) &&
    isOwnPo;

  const modalHandler = () => {
    if (isAvailableForAll || ifHasPermission) {
      if (isReadyToClose) {
        return dispatch(setPOsModal({ closePO: { ...order, goToPO: true } }));
      }
      return dispatch(setPOsModal({ editPORequest: order }));
    }
    return toast.error(EditPOError);
  };

  return {
    [handlerName]: modalHandler,
    isAvailableForAll,
    isReadyToClose,
    ifHasPermission,
  };
};

export const usePOFileHandler = () => {
  const [fileState, setFileState] = useState(null);
  const [wasAddedState, setWasAddedState] = useState(false);
  const [wasRemovedState, setWasRemovedState] = useState(false);

  const setFile = useCallback((fileValue) => {
    setFileState(fileValue);
  }, []);

  const setWasAdded = (isAdded) => setWasAddedState(isAdded);

  const setWasRemoved = (isRemoved) => setWasRemovedState(isRemoved);

  const onAddFile = useCallback((fileInputValue) => {
    setFile(fileInputValue);
    setWasAdded(true);
  }, []);

  const onRemoveFile = useCallback(() => {
    setFile(null);
    setWasRemoved(true);
  }, []);

  return {
    file: fileState,
    wasAdded: wasAddedState,
    wasRemoved: wasRemovedState,
    onAddFile,
    onRemoveFile,
    setFile,
    setWasAdded,
    setWasRemoved,
  };
};
