import * as R from 'ramda';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Grid, S } from '@poly/site-book';

const updateSorting = (key, sorting = {}) => ({
  key,
  dir: sorting.key === key ? -sorting.dir : 1,
});

const getColor =
  (expected) =>
  ({ direction }) =>
    expected === direction ? '#4676b4' : '#90939d';

const GridCell = styled(Grid.Cell)`
  ${({ isSort }) =>
    isSort &&
    css`
      cursor: pointer;
    `};
`;

const SortingPip = styled.span`
  width: 6px;
  height: 8px;
  display: inline-flex;
  position: relative;
  margin-left: 10px;

  &::before,
  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
  }

  &::before {
    bottom: 0;
    border-width: 3px 3px 0 3px;
    border-color: ${getColor(-1)} transparent transparent transparent;
  }

  &::after {
    top: 0;
    border-width: 0 3px 3px 3px;
    border-color: transparent transparent ${getColor(1)} transparent;
  }
`;
// dispatch props on update
export function Table({
  headers,
  rows,
  columns,
  formats,
  gridColumns,
  props,
  omitSorting,
  sorting,
  setSorting,
  valuesToSort,
  className,
  GridRowComponent,
  onHeaderCellClick,
  withoutBorder,
  headerStyles,
}) {
  return (
    <Grid columns={gridColumns} className={className}>
      <Grid.Head style={headerStyles}>
        {headers.map((header, i) => (
          <GridCell
            key={`${header}-header-cell`}
            isSort={!!valuesToSort.length && !omitSorting[i]}
            {...R.propOr({}, 'align')(props[i])}
            onClick={() =>
              onHeaderCellClick
                ? onHeaderCellClick(i, valuesToSort[i])
                : !omitSorting[i] && setSorting(updateSorting(i, sorting))
            }
          >
            <S type="title" textColor="#90939d">
              {header}
              {!!valuesToSort.length && valuesToSort[i] && !omitSorting[i] && (
                <SortingPip
                  direction={sorting?.key === i ? sorting?.dir : undefined}
                />
              )}
            </S>
          </GridCell>
        ))}
      </Grid.Head>
      <Grid.Body withoutBorder={withoutBorder}>
        {/* all except last */}
        {rows.slice(0, -1).map((row) => (
          <GridRowComponent columns={gridColumns} key={row._id}>
            {columns.map((_, i) => (
              <Grid.Cell
                key={`${row._id}_${headers[i]}`}
                {...R.propOr({}, 'align')(props[i])}
              >
                {R.compose(formats[i], columns[i])({ ...row })}
              </Grid.Cell>
            ))}
          </GridRowComponent>
        ))}
        {/* last row - table footer */}
        {rows.slice(-1).map((row) => (
          <GridRowComponent
            columns={gridColumns}
            key={row._id}
            className="grid-table-footer"
          >
            {columns.map((_, i) => (
              <Grid.Cell
                key={`${row._id}_${headers[i]}`}
                {...R.propOr({}, 'align')(props[i])}
              >
                {R.compose(formats[i], columns[i])({ ...row })}
              </Grid.Cell>
            ))}
          </GridRowComponent>
        ))}
      </Grid.Body>
    </Grid>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  valuesToSort: PropTypes.arrayOf(PropTypes.func),
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.arrayOf(PropTypes.object),
  omitSorting: PropTypes.arrayOf(PropTypes.bool),
  gridColumns: PropTypes.string.isRequired,
  setSorting: PropTypes.func,
  sorting: PropTypes.shape({
    key: PropTypes.number.isRequired,
    dir: PropTypes.number.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  headerStyles: PropTypes.object,
  onChange: PropTypes.func,
  GridRowComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onHeaderCellClick: PropTypes.func,
  withoutBorder: PropTypes.bool,
};

const defaultPropsForTable = {
  className: '',
  setSorting: () => null,
  sorting: undefined,
  valuesToSort: [],
  props: [],
  omitSorting: [],
  headerStyles: {},
  onChange: () => {},
  GridRowComponent: Grid.Row,
  withoutBorder: false,
};

Table.defaultProps = defaultPropsForTable;
