import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { NOTHING_UI_STRING } from '@poly/constants';
import { AbsoluteLoader } from '@poly/site-ui';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { warrantyEndFormatter } from '../../utils/assets.js';
import usePagination from '../../hooks/usePagination.js';
import {
  selectPropertyMessage,
  noDataToDisplay,
} from '../../util/assets/no-data.js';
import {
  highlightAssetByEquipment,
  highlightField,
} from '../../utils/highlight/index.js';
import {
  setAssetsPaginationDropdownLabel,
  setAssetsPaginationCurrentPage,
  setAssetsPaginationPageSize,
  setAssetsPaginationTotal,
} from '../../redux/actions/index.js';
import { useAssetsQuery } from '../../hooks/assets.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';

const getColumns = (search) => [
  [
    'Equipment type',
    R.identity,
    highlightAssetByEquipment(search),
    R.prop('equipmentType'),
  ],
  [
    'Property',
    R.path(['property', 'name']),
    highlightField(search),
    R.path(['property', 'name']),
  ],
  [
    'Description',
    R.prop('description'),
    highlightField(search),
    R.prop('description'),
  ],
  [
    'Serial Number',
    R.propOr(NOTHING_UI_STRING, 'serial'),
    highlightField(search),
    R.prop('serial'),
  ],
  [
    'Warranty EXP',
    R.prop('warrantyEnd'),
    warrantyEndFormatter(search),
    R.prop('warrantyEnd'),
  ],
];

export default function (props) {
  const assetsState = useSelector((state) => state.assets);

  const {
    filter,
    pageSize,
    currentPage,
    tableSearchString,
    paginationDropdownLabel,
  } = assetsState;

  const from = (currentPage - 1) * pageSize;

  const { fetchMore, loading, assets, total } = useAssetsQuery({
    propertyId: filter || '',
    from,
    size: pageSize,
    searchTerm: tableSearchString,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setAssetsPaginationTotal,
    setPageSize: setAssetsPaginationPageSize,
    setCurrent: setAssetsPaginationCurrentPage,
    setPaginationDropdownLabel: setAssetsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns(tableSearchString));
  const headers = R.map(R.nth(0), getColumns(tableSearchString));
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns(tableSearchString),
  );
  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(3))(item),
    getColumns(tableSearchString),
  );
  const gridColumns = `
      minmax(80px, 200px)
      repeat(2, minmax(200px, 1fr))
      repeat(2, minmax(80px, 150px))
    `;

  const { rows, sorting, setSorting } = useSortableTable(
    assets || [],
    valuesToSort,
  );

  if (!filter) return selectPropertyMessage();
  if (loading) return <AbsoluteLoader />;
  if (!assets.length) return noDataToDisplay();

  return (
    <TableWithSavingDataForExport
      {...props}
      rows={rows}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      tableSearchString={tableSearchString}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      loading={loading}
      fetchMore={fetchMore}
      sorting={sorting}
      setSorting={setSorting}
      valuesToSort={valuesToSort}
      searchTerm={tableSearchString}
    />
  );
}
