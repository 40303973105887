import React from 'react';
import * as R from 'ramda';
import PropTypes, { bool } from 'prop-types';
import styled from 'styled-components';
import { Avatar, Dropdown, S, Username } from '@poly/site-book';
import { getFirstLetterOfNames } from '@poly/client-utils';
import { DropDown } from '../components/DropDown.js';

const color = '#babfd2';

const UserCredentials = styled(Dropdown.Item.withComponent(S))`
  letter-spacing: 1.5px;
  text-transform: none;
  line-height: 14px;
  font-size: 13px;
`;

const DropDownHeader = styled(Dropdown.Header)`
  span:first-child {
    font-family: inherit;
    font-size: 14px;
    color: ${color};
  }
`;

// shouldShowUserCredentials :: Object -> Boolean
const shouldShowUserCredentials = R.pathSatisfies(R.equals(true), [
  'site',
  'showUserCredentials',
]);

export function UserMenu({
  clientConfig,
  lastName,
  firstName,
  onLogoutClick,
  openSupportModal,
}) {
  return (
    <DropDown
      HeaderComponent={DropDownHeader}
      header={
        <Username name={firstName}>
          <Avatar
            type="text"
            content={getFirstLetterOfNames(firstName, lastName)}
          />
        </Username>
      }
      arrowColor={color}
      transparent
    >
      {shouldShowUserCredentials(clientConfig) && (
        <UserCredentials type="title">
          {firstName} {lastName}
        </UserCredentials>
      )}
      {openSupportModal && (
        <Dropdown.Item onClick={openSupportModal}>
          <S type="title">Support</S>
        </Dropdown.Item>
      )}
      <Dropdown.Item onClick={onLogoutClick}>
        <S type="title">Logout</S>
      </Dropdown.Item>
    </DropDown>
  );
}

UserMenu.propTypes = {
  openSupportModal: PropTypes.func,
  onLogoutClick: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  clientConfig: PropTypes.shape({
    site: PropTypes.objectOf(bool),
  }),
};

UserMenu.defaultProps = {
  firstName: '',
  lastName: '',
  clientConfig: { site: {} },
};
