import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import {
  IconButton,
  HeadingH3,
  Heading,
  Button,
  Editor,
  Modal,
  Input,
  Icon,
  Grid,
  S,
} from '@poly/site-book';

import { Rows } from '../../util/forms/index.js';
import ServerError from '../server-error/index.js';
import InputNumber from '../input-number/index.js';
import ButtonLoader from '../loader/button.js';
import {
  AddressSection,
  addressObjectPropTypes,
} from '../address-section/index.js';
import { DuplicateUserContactEmailInput } from './DuplicateUserContactEmailInput.js';

const ModalGrid = Modal.Item.withComponent(Grid);

function PeopleModal({
  isAdd,
  loading,
  closeModal,
  onSubmit,
  setRef,
  remarks,
  onChange,
  validateOnBlur,
  resetError,
  errors,
  firstName,
  lastName,
  title,
  workPhone,
  mobile,
  fax,
  email,
  address,
  setAddress,
  setEmail,
  linkId,
  linkProp,
}) {
  return (
    <Modal show>
      <Modal.Item margin={30}>
        <Heading>
          <HeadingH3 lighter>{isAdd ? 'Add' : 'Edit'} People</HeadingH3>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <ServerError error={R.prop('server', errors)} style={{ maxWidth: 445 }} />
      <form {...{ onSubmit }}>
        <ModalGrid columns="130px minmax(200px, 320px)" margin={30} simple>
          <Rows
            items={[
              {
                title: { value: 'First Name', props: ['required'] },
                error: R.prop('firstName', errors),
                item: (
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    defaultValue={firstName}
                    invalid={!!R.prop('firstName', errors)}
                    {...{ onChange }}
                  />
                ),
              },
              {
                title: { value: 'Last Name', props: ['required'] },
                error: R.prop('lastName', errors),
                item: (
                  <Input
                    name="lastName"
                    placeholder="Last Name"
                    defaultValue={lastName}
                    invalid={!!R.prop('lastName', errors)}
                    {...{ onChange }}
                  />
                ),
              },
              {
                title: { value: 'Title' },
                item: (
                  <Input
                    name="title"
                    placeholder="Title"
                    defaultValue={title}
                  />
                ),
              },
              {
                title: { value: 'Email' },
                error: R.prop('email', errors),
                item: (
                  <DuplicateUserContactEmailInput
                    name="email"
                    type="email"
                    placeholder="Email"
                    defaultValue={email}
                    invalid={!!R.prop('email', errors)}
                    skipSearch={!isAdd}
                    entityId={linkId}
                    entityPropName={linkProp}
                    email={email}
                    closeModal={closeModal}
                    onChange={(e) => {
                      onChange(e);
                      setEmail(e.target.value);
                    }}
                  />
                ),
              },
              {
                title: {
                  value: 'Work',
                },
                error: R.prop('workPhone', errors),
                item: (
                  <InputNumber
                    name="workPhone"
                    type="tel"
                    placeholder="Work Phone"
                    format="(###) ###-####"
                    mask="_"
                    defaultValue={workPhone}
                    invalid={!!R.prop('workPhone', errors)}
                    onBlur={validateOnBlur}
                    onFocus={resetError}
                  />
                ),
              },
              {
                title: {
                  value: 'Mobile',
                },
                error: R.prop('mobile', errors),
                item: (
                  <InputNumber
                    name="mobile"
                    type="tel"
                    placeholder="Mobile Phone"
                    format="(###) ###-####"
                    mask="_"
                    defaultValue={mobile}
                    invalid={!!R.prop('mobile', errors)}
                    onBlur={validateOnBlur}
                    onFocus={resetError}
                  />
                ),
              },
              {
                title: {
                  value: 'Fax',
                },
                error: R.prop('fax', errors),
                item: (
                  <InputNumber
                    name="fax"
                    type="tel"
                    placeholder="Fax"
                    format="(###) ###-####"
                    mask="_"
                    defaultValue={fax}
                    invalid={!!R.prop('fax', errors)}
                    onBlur={validateOnBlur}
                    onFocus={resetError}
                  />
                ),
              },
              {
                item: (
                  <AddressSection
                    value={address}
                    key="address-section"
                    onChange={(newValue) => {
                      setAddress(newValue);
                      onChange({
                        target: { name: 'address', value: newValue },
                      });
                    }}
                    onBlur={validateOnBlur}
                    error={R.prop('address', errors)}
                  />
                ),
              },
              {
                title: {
                  value: 'Remarks',
                  props: ['', 'description'],
                },
                item: (
                  <Editor
                    placeholder="Remarks"
                    defaultValue={remarks}
                    registerRef={setRef}
                  />
                ),
              },
            ]}
          />
        </ModalGrid>
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">
              Save
              {isAdd ? 'Contact' : 'Changes'}
            </S>
          </Button>
        </Modal.Buttons>
      </form>
    </Modal>
  );
}

PeopleModal.propTypes = {
  isAdd: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  validateOnBlur: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  remarks: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errors: PropTypes.shape({
    server: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  workPhone: PropTypes.string,
  mobile: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  address: addressObjectPropTypes,
  setAddress: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  linkId: PropTypes.string,
  linkProp: PropTypes.string,
};

PeopleModal.defaultProps = {
  errors: {},
  firstName: '',
  lastName: '',
  title: '',
  workPhone: '',
  mobile: '',
  fax: '',
  email: '',
  address: '',
  remarks: null,
};

export default PeopleModal;
