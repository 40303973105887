import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { USAStates } from '@poly/constants';
import { Input, Holder, Checkbox, S, Select } from '@poly/site-book';
import { pathEqLegacy } from '@poly/utils';

import { Rows } from '../../util/forms/index.js';
import { GeoSelect } from '../geoselect/index.js';
import { addressObjectPropTypes } from './types.js';
import { formatPlaceholder } from '../../util/select.js';

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = { label: PropTypes.string.isRequired };

const HolderS = styled(Holder)`
  flex-direction: column;
  padding-top: 5px;

  & > input {
    margin-bottom: 20px;
  }
`;

// isCanadianAddress :: Address -> Bool
const isCanadianAddress = pathEqLegacy(['address_parts', 'country'], 'CA');

export function AddressSectionComp({
  value,
  error,
  onBlur,
  setCity,
  setZipCode,
  setZipCodeSuffix,
  setStreetName,
  setAddressTwo,
  setAddressState,
  setStreetNumber,
  isAddressManual,
  setAddressSearch,
  setAddressManual,
  isRequiredSearchAddressField,
}) {
  return (
    <Rows
      items={[
        {
          title: {
            value: 'Address Search',
            props: isRequiredSearchAddressField ? ['required'] : [],
          },
          item: (
            <GeoSelect
              name="geo-select"
              placeholder="Start typing address"
              onChange={setAddressSearch}
              {...{ onBlur }}
            />
          ),
        },
        {
          title: { value: 'Address', props: ['required'] },
          error,
          item: (
            <Input
              name="address"
              value={R.pathOr('', ['formatted_address'], value)}
              invalid={!!error}
              readOnly
            />
          ),
        },
        {
          title: { value: 'Address 2', props: ['', 'description'] },
          item: (
            <HolderS>
              <Input
                name="addressTwo"
                placeholder="Additional address"
                value={R.pathOr('', ['addressTwo'], value)}
                onChange={(e) => setAddressTwo(e.target.value)}
              />
              <Checkbox
                id="isAddressManual"
                content="Set Address Manually"
                value={isAddressManual}
                checked={isAddressManual}
                onChange={() => setAddressManual(!isAddressManual)}
              />
            </HolderS>
          ),
        },
        ...(isAddressManual
          ? [
              {
                title: { value: 'Street #' },
                item: (
                  <Input
                    name="streetNumber"
                    placeholder="Street Number"
                    value={R.pathOr(
                      '',
                      ['address_parts', 'street_number'],
                      value,
                    )}
                    onChange={(e) => setStreetNumber(e.target.value)}
                  />
                ),
              },
              {
                title: { value: 'Street Name' },
                item: (
                  <Input
                    name="streetName"
                    placeholder="Street Name"
                    onChange={(e) => setStreetName(e.target.value)}
                    value={R.pathOr('', ['address_parts', 'route'], value)}
                  />
                ),
              },
              {
                title: { value: 'City' },
                item: (
                  <Input
                    name="city"
                    placeholder="City"
                    onChange={(e) => setCity(e.target.value)}
                    value={R.pathOr('', ['address_parts', 'locality'], value)}
                  />
                ),
              },
              ...(isCanadianAddress(value)
                ? []
                : [
                    {
                      title: { value: 'State' },
                      item: (
                        <Select
                          placeholder={formatPlaceholder('State')}
                          value={R.pathOr(
                            '',
                            ['address_parts', 'administrative_area_level_1'],
                            value,
                          )}
                          options={R.pipe(
                            R.toPairs,
                            R.map(([key, label]) => ({
                              value: key,
                              label,
                            })),
                          )(USAStates)}
                          clearable={false}
                          optionRenderer={SimpleTitle}
                          onChange={(e) => setAddressState(e.value)}
                        />
                      ),
                    },
                  ]),
              {
                title: { value: 'Zip', props: ['required'] },
                item: (
                  <Input
                    name="zipCode"
                    placeholder="Zip"
                    maxLength={isCanadianAddress(value) ? 7 : 5}
                    value={R.pathOr(
                      '',
                      ['address_parts', 'postal_code'],
                      value,
                    )}
                    onChange={R.compose(
                      setZipCode,
                      R.path(['target', 'value']),
                    )}
                  />
                ),
              },
              ...(isCanadianAddress(value)
                ? []
                : [
                    {
                      title: { value: 'Suffix' },
                      item: (
                        <Input
                          name="suffix"
                          placeholder="Suffix"
                          maxLength={4}
                          value={R.pathOr(
                            '',
                            ['address_parts', 'postal_code_suffix'],
                            value,
                          )}
                          onChange={R.compose(
                            setZipCodeSuffix,
                            R.path(['target', 'value']),
                          )}
                        />
                      ),
                    },
                  ]),
            ]
          : []),
      ]}
    />
  );
}

AddressSectionComp.propTypes = {
  error: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  setCity: PropTypes.func.isRequired,
  setZipCode: PropTypes.func.isRequired,
  setZipCodeSuffix: PropTypes.func.isRequired,
  setStreetName: PropTypes.func.isRequired,
  setAddressTwo: PropTypes.func.isRequired,
  setStreetNumber: PropTypes.func.isRequired,
  setAddressState: PropTypes.func.isRequired,
  isAddressManual: PropTypes.bool.isRequired,
  setAddressSearch: PropTypes.func.isRequired,
  setAddressManual: PropTypes.func.isRequired,
  value: addressObjectPropTypes,
  isRequiredSearchAddressField: PropTypes.bool.isRequired,
};

AddressSectionComp.defaultProps = {
  error: null,
  value: '',
};
