import * as R from 'ramda';
import { assocBy, removePropDeep } from '@poly/utils';

import { setUser } from '../actions/index.js';
import { apolloClient } from '../reducers/apollo.js';
import { currentUserQuery, getClientConfigs } from '../../queries/index.js';
import { EUREST_TIAA_ID } from '../../constants/index.js';

const userClientPath = ['clients', 0];

/**
 * prepareAccessItemsTypes :: User -> User
 */
export const prepareAccessItemsTypes = R.over(
  R.lensProp('accessItems'),
  R.map(R.over(R.lensProp('types'), R.reject(R.isNil))),
);

/**
 * prepareAccessItemsAmount :: User -> User
 */
export const prepareAccessItemsAmount = R.over(
  R.lensProp('accessItems'),
  R.map(
    R.when(
      R.pathSatisfies(R.complement(R.isNil), ['types', 'poAmount']),
      R.over(R.lensPath(['types', 'poAmount']), R.reject(R.isNil)),
    ),
  ),
);

/**
 * prepareUserData :: User -> User
 */
export const prepareUserData = R.compose(
  prepareAccessItemsAmount,
  prepareAccessItemsTypes,
  assocBy('clientConfig', R.pathOr({}, [...userClientPath, 'configs'])),
  assocBy('clientId', R.pathOr(null, [...userClientPath, '_id'])),
  assocBy('clientGLCodes', R.pathOr({}, [...userClientPath, 'glCodes'])),
  removePropDeep('__typename'),
);

const glCodesInput = { isActive: true };

// fetchDefaultClientP :: User -> Promise _ User
const fetchDefaultClientP = async (user) => {
  try {
    const { data } = await apolloClient.query({
      query: getClientConfigs,
      variables: {
        clientId: EUREST_TIAA_ID,
        glCodesInput,
      },
    });

    return R.assocPath(userClientPath, data.client, user);
  } catch (e) {
    return user;
  }
};

/**
 * setCurrentUserActionP :: _ -> Promise Error Object
 */
export const setCurrentUserActionP = R.composeWith(R.andThen, [
  setUser,
  prepareUserData,
  R.when(
    R.pathSatisfies(R.either(R.isNil, R.isEmpty), userClientPath),
    fetchDefaultClientP,
  ),
  R.path(['data', 'me']),
  R.partial(apolloClient.query, [
    {
      query: currentUserQuery,
      variables: {
        glCodesInput,
      },
    },
  ]),
]);
