import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AbsoluteLoader } from '@poly/site-ui';
import {
  highlightField,
  highlightProject,
} from '../../utils/highlight/index.js';
import { useProjectFilters } from '../../utils/projects/projects-filter.js';
import { recurringProjectStatusTransform } from '../../utils/mr-projects/index.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';
import { dateFormatter } from '../../utils/projects/index.js';
import usePagination from '../../hooks/usePagination.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  setReportProjectsPaginationCurrentPage,
  setReportProjectsPaginationDropdownLabel,
  setReportProjectsPaginationPageSize,
  setReportProjectsPaginationTotal,
} from '../../redux/actions/index.js';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { assignedToFormatter } from '../../components/master-recurring-work-orders/assigned-to.js';
import { useRecurringProjectsQuery } from '../../hooks/projects.js';

const getColumns = (search) => [
  ['WO #', R.identity, highlightProject(search)],
  ['WO Type', R.always('Master Recurring'), highlightField(search)],
  ['Description', R.prop('description'), highlightField(search)],
  ['Property', R.path(['property', 'name']), highlightField(search)],
  ['Assigned To', R.identity, assignedToFormatter(search)],
  ['Start', R.prop('startDate'), dateFormatter(search)],
  ['End', R.prop('endDate'), dateFormatter(search)],
  ['', R.prop('status'), recurringProjectStatusTransform, true],
];

export default function (props) {
  const dispatch = useDispatch();

  const report = useSelector((state) => state.report);
  const locationType = useSelector((state) => state.location.type);

  const {
    currentPage,
    pageSize,
    paginationDropdownLabel,
    tableSearchString,
    typeFilter,
  } = report;

  const from = (currentPage - 1) * pageSize;

  const propsForFilter = {
    typeFilter,
    locationType,
    tableSearchString,
  };

  const { searchTerm, query, sort } = useProjectFilters(propsForFilter);

  const { projects, total, restProjectsProps } = useRecurringProjectsQuery({
    searchTerm,
    query,
    pageSize,
    sort,
    from,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setReportProjectsPaginationTotal,
    setCurrent: setReportProjectsPaginationCurrentPage,
    setPageSize: setReportProjectsPaginationPageSize,
    setPaginationDropdownLabel: setReportProjectsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns(tableSearchString));
  const headers = R.map(R.nth(0), getColumns(tableSearchString));

  const valuesToSort = R.map(R.nth(1), getColumns(tableSearchString));

  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns(tableSearchString),
  );

  const omitSorting = R.map(R.propOr(false, 3), getColumns(tableSearchString));

  const gridColumns = `
      minmax(80px, 150px)
      minmax(80px, 200px)
      repeat(3, minmax(100px, 1fr))
      repeat(2, 80px)
      10px
    `;

  const { rows, sorting, setSorting } = useSortableTable(
    projects,
    valuesToSort,
  );

  if (restProjectsProps.loading) return <AbsoluteLoader />;
  if (!projects.length) return noDataToDisplay('Projects')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restProjectsProps}
      rows={rows}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      sorting={sorting}
      setSorting={setSorting}
      dispatch={dispatch}
      valuesToSort={valuesToSort}
      typeFilter={typeFilter}
      omitSorting={omitSorting}
      locationType={locationType}
      searchTerm={searchTerm}
    />
  );
}
