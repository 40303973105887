import React from 'react';
import { Icon, L, Pip, S } from '@poly/site-book';
import * as R from 'ramda';
import { highlightTextToReactElement } from '@poly/client-utils';
import { formatDate, removeFileExtension } from '@poly/utils';

import { isNotNilOrEmpty } from './general.js';

export function EntityStatusFormatter(color, text, search) {
  return (
    <Pip color={color}>
      <S type="title">
        {isNotNilOrEmpty(search)
          ? highlightTextToReactElement(search, text)
          : text}
      </S>
    </Pip>
  );
}

export const fileTypeFormatter = (value) => {
  const fill = '#a1a9c3';
  const dimensions = { height: 26 };
  const type = R.last(value.split('/'));
  const name = Icon({ name: type }) ? type : 'file';

  return <Icon {...{ name, fill, dimensions }} />;
};

export const fileNameFormatter = (file) => {
  const target = '_blank';
  const { url: href, fileName } = file;
  const fileNameWithoutExt = removeFileExtension(fileName);
  const { searchText } = file;
  return (
    <L {...{ href, target }}>
      {searchText
        ? highlightTextToReactElement(searchText, fileNameWithoutExt)
        : fileNameWithoutExt}
    </L>
  );
};

export const dateFormatter = (value) => (
  <S type="content">{formatDate(value) || '—'}</S>
);

// urlify :: String -> [ReactNode, String]
export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part) => {
    if (part.match(urlRegex)) {
      return (
        <a href={part} key={part}>
          {part}
        </a>
      );
    }
    return part;
  });
};
