import React from 'react';
import * as R from 'ramda';
import { arrayOf, func, shape, string } from 'prop-types';
import { Grid, S } from '@poly/site-book';

const shouldCenterContent = (index) => (index === 0 && 'center') || null;

export const filesTablePropTypes = {
  headers: arrayOf(string.isRequired).isRequired,
  rows: arrayOf(
    shape({
      _id: string,
    }).isRequired,
  ).isRequired,
  columns: arrayOf(func.isRequired).isRequired,
  formats: arrayOf(func.isRequired).isRequired,
  gridColumns: string,
};

export function FilesTable({
  headers,
  rows,
  formats,
  columns,
  gridColumns = '80px auto',
}) {
  return (
    <Grid columns={gridColumns}>
      <Grid.Head style={{ paddingTop: 0 }}>
        {headers.map((header, i) => (
          <Grid.Cell key={header} horizontal={shouldCenterContent(i)}>
            <S type="title" textColor="#90939d">
              {header}
            </S>
          </Grid.Cell>
        ))}
      </Grid.Head>
      <Grid.Body>
        {rows.map((row) => (
          <Grid.Row key={row._id}>
            {columns.map((_, i) => (
              <Grid.Cell
                key={`${row._id}_${headers[i]}`}
                horizontal={shouldCenterContent(i)}
                vertical="center"
              >
                {R.compose(formats[i], columns[i])({ ...row })}
              </Grid.Cell>
            ))}
          </Grid.Row>
        ))}
      </Grid.Body>
    </Grid>
  );
}

FilesTable.propTypes = filesTablePropTypes;
