import { projectStatusUITransform } from '@poly/site-ui';

import { MR_PROJECT, PM_PROJECT, PROJECT } from '../../constants/routes.js';
import { PREVENTIVE_MAINTENANCE } from '../../constants/projects.js';
import { mrPmProjectFormatter } from '../../util/project-utils.js';
import { fieldFormatter, formatCurrency } from '../../util/general.js';
import { assetFormatter, projectFormatter } from '../global-search/index.js';
import {
  statusFormatter,
  supplierFormatter,
} from '../suppliers/constructions.js';
import { propertyLinkFormatter } from '../../util/property-utils.js';
import { assetLinkFormatter } from '../../util/assets/formatters.js';

export const highlightProject =
  (search) =>
  ({ type, projectId }) => {
    if (!type) {
      return projectFormatter(MR_PROJECT, projectId, search);
    }
    if (type === PREVENTIVE_MAINTENANCE) {
      return projectFormatter(PM_PROJECT, projectId, search);
    }
    return projectFormatter(PROJECT, projectId, search);
  };

export const highlightProjectStatus = (search) => (project) =>
  projectStatusUITransform({ ...project, search });

export const highlightAsset = (search) => (asset) =>
  assetFormatter(asset, search);

export const highlightSupplierStatus = (search) => (status) =>
  statusFormatter(status, search);

export const highlightField = (search, bold) => (text) =>
  fieldFormatter(text, search, bold);

export const highlightMaintenance = (search) => (project) =>
  mrPmProjectFormatter(project, search);

export const highlightProperty = (search) => (property) =>
  propertyLinkFormatter(property, search);

export const highlightSupplier = (search) => (supplier) =>
  supplierFormatter(supplier, search);

export const highlightAssetByEquipment = (search) => (asset) =>
  assetLinkFormatter({ ...asset, serial: asset.equipmentType }, search);

export const highlightCurrency = (search) => (num) =>
  fieldFormatter(formatCurrency(num), search);
