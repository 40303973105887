import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { collectionNames } from '@poly/constants';
import { FilesTab, Loader } from '@poly/site-ui';
import { fileColumns } from '../../../utils/files.js';
import { usePropertyDetails } from '../../../hooks/property-details.js';

export default function (props) {
  const dispatch = useDispatch();

  const propertyId = useSelector((state) => state.location.payload.id);
  const { property, loading } = usePropertyDetails(propertyId);

  if (loading) return <Loader />;

  const collection = collectionNames.properties;
  const fileColumnsValue = fileColumns(dispatch);

  return (
    <FilesTab
      {...props}
      id={property?.name}
      fileColumns={fileColumnsValue}
      collection={collection}
      documentId={propertyId}
    />
  );
}
