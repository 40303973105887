import React from 'react';
import { TableSearchComponent } from '@poly/site-ui';
import {
  setPOsPaginationCurrentPage,
  setPOsTableSearchString,
} from '../../../redux/actions/index.js';

export default function (props) {
  return (
    <TableSearchComponent
      {...props}
      setTableSearchString={setPOsTableSearchString}
      setPaginationCurrentPage={setPOsPaginationCurrentPage}
    />
  );
}
