import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@poly/site-book';
import { TableComponent } from '@poly/site-ui';

function Suppliers({ suppliers }) {
  return (
    <Widget.Item>
      <TableComponent
        gridColumns="
        minmax(100px, 195px)
        minmax(150px,1fr)
        minmax(100px, 180px)
        120px
      "
        {...suppliers}
      />
    </Widget.Item>
  );
}

Suppliers.propTypes = {
  suppliers: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
    formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  }).isRequired,
};

export default Suppliers;
