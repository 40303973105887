import React from 'react';
import { Layout } from '@poly/site-book';

import ReportLayout from '../layouts/report.js';
import AuthContainer from '../hooks/auth-container.js';
import ReportInfoContainer from '../containers/master-recurring-work-orders/filter.js';
import Navigation from '../containers/navbar/navigation-container.js';
import { LayoutContent } from '../styled.js';

function Report() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <ReportInfoContainer />
        </Layout.Header>
        <LayoutContent>
          <ReportLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default Report;
