import { Select as PolyBookSelect } from '@poly/site-book';
import React from 'react';

export function SelectBase(props) {
  return (
    <PolyBookSelect
      searchPromptText="Type to Search"
      noResultsText="No Results Found"
      clearValueText="Clear Value"
      clearAllText="Clear All"
      {...props}
    />
  );
}
