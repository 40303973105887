import React from 'react';
import { number, arrayOf, string, shape, func } from 'prop-types';
import { Tabs } from '@poly/site-book';

export function GlobalSearchNavigation({
  configs,
  activeTabIndex,
  setActiveTabIndex,
}) {
  return (
    <Tabs>
      <Tabs.Items>
        {configs.map(({ value, title }, idx) => (
          <Tabs.Item
            bold
            key={value}
            href={`#${value}`}
            active={idx === activeTabIndex}
            onClick={() => setActiveTabIndex(idx)}
          >
            {title}
          </Tabs.Item>
        ))}
      </Tabs.Items>
    </Tabs>
  );
}

GlobalSearchNavigation.propTypes = {
  configs: arrayOf(
    shape({
      title: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  activeTabIndex: number.isRequired,
  setActiveTabIndex: func.isRequired,
};
