import { WorkOrderStatus } from '@poly/constants';

import types from '../types.js';
import { TECHNICIAN_STATS_SIDEBAR_TABS } from '../../containers/technician-stats/constants.js';
import { STAFF_SIDEBAR_TABS } from '../../constants/staff.js';
import {
  activeProjectsQuery,
  overdueProjectsQuery,
} from '../../utils/staff/staff-filters.js';

export const setStaffSidebar = (userId, isManager) => ({
  type: types.SET_STAFF_SIDEBAR,
  payload: {
    userId,
    tabs: {
      tabsNames: [STAFF_SIDEBAR_TABS.ACTIVE, STAFF_SIDEBAR_TABS.OVERDUE],
      tabsQueries: {
        [STAFF_SIDEBAR_TABS.ACTIVE]: activeProjectsQuery,
        [STAFF_SIDEBAR_TABS.OVERDUE]: overdueProjectsQuery,
      },
      projectsResultPropName: isManager
        ? 'searchManagerProjects'
        : 'searchTechnicianProjects',
      activeTab: STAFF_SIDEBAR_TABS.ACTIVE,
    },
  },
});

const completedProjectsWithingRange = (userId, { from, to }) => ({
  bool: {
    must: [
      {
        term: {
          status: WorkOrderStatus.COMPLETED,
        },
      },
      {
        match: {
          technicians: userId,
        },
      },
    ],
    filter: {
      range: {
        workCompletionDate: { gt: from, lte: to },
      },
    },
  },
});

const createdProjectsWithingRange = (userId, { from, to }) => ({
  bool: {
    must: { match: { createdBy: userId } },
    filter: {
      range: {
        createdAt: { gt: from, lte: to },
      },
    },
  },
});

export const setTechniciansSidebar = (userId, period, activeTab) => ({
  type: types.SET_STAFF_SIDEBAR,
  payload: {
    userId,
    tabs: {
      projectsResultPropName: 'searchRelatedProjects',
      tabsNames: [
        TECHNICIAN_STATS_SIDEBAR_TABS.CREATED,
        TECHNICIAN_STATS_SIDEBAR_TABS.COMPLETED,
      ],
      tabsQueries: {
        [TECHNICIAN_STATS_SIDEBAR_TABS.COMPLETED]:
          completedProjectsWithingRange(userId, period),
        [TECHNICIAN_STATS_SIDEBAR_TABS.CREATED]: createdProjectsWithingRange(
          userId,
          period,
        ),
      },
      activeTab,
    },
  },
});

export const setStaffSidebarActiveTab = (tab) => ({
  type: types.SET_STAFF_SIDEBAR_ACTIVE_TAB,
  payload: tab,
});

export const setStaffFilter = (filter) => ({
  type: types.SET_STAFF_FILTER,
  payload: filter,
});

export const setStaffTableSearchString = (query) => ({
  type: types.SET_STAFF_TABLE_SEARCH_STRING,
  payload: query,
});

export const setStaffPaginationCurrentPage = (current) => ({
  type: types.SET_STAFF_PAGINATION_CURRENT_PAGE,
  payload: current,
});

export const setStaffPaginationTotal = (total) => ({
  type: types.SET_STAFF_PAGINATION_TOTAL,
  payload: total,
});

export const setStaffPaginationPageSize = (size) => ({
  type: types.SET_STAFF_PAGINATION_PAGE_SIZE,
  payload: size,
});

export const setStaffPaginationDropdownLabel = (label) => ({
  type: types.SET_STAFF_PAGINATION_DROPDOWN_LABEL,
  payload: label,
});

export const setStaffModal = (modal) => ({
  type: types.SET_STAFF_MODAL,
  payload: modal,
});
