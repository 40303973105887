import * as R from 'ramda';
import React, { useState } from 'react';
import { Layout } from '@poly/site-book';
import { yearPeriodOptions } from '@poly/utils';
import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import Navigation from '../containers/navbar/navigation-container.js';
import EyesWideOpenStats from '../containers/eyes-wide-open/index.js';
import YearSelectorHeader from '../components/date-filter/year-selector-header.js';
import { exportEyesWideOpenStatsToXLS } from '../containers/eyes-wide-open/export-to-xls.js';

const START_PERIOD_YEAR = 2018;

// yearOptions :: Date -> [Object]
const yearOptions = R.compose(
  R.map(R.converge(R.mergeRight, [R.objOf('label'), R.objOf('value')])),
  R.map(R.toString),
  yearPeriodOptions(START_PERIOD_YEAR),
);

function EyesWideOpen() {
  const [year, setYear] = useState(new Date().getFullYear().toString());

  const onYearSelect = (yearProps) => setYear(yearProps?.value || null);

  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <YearSelectorHeader
            title="Eyes Wide Open Stats"
            onYearSelect={onYearSelect}
            options={yearOptions(new Date())}
            year={year}
            exportFunction={exportEyesWideOpenStatsToXLS}
            tablePropsToVariablesForXlsQuery={R.prop('query')}
          />
        </Layout.Header>
        <LayoutContent>
          <EyesWideOpenStats year={Number(year)} />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}
export default EyesWideOpen;
