import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { Icon, IconButton, S } from '@poly/site-book';

import { setProjectAssetsModal } from '../../../../redux/actions/index.js';

const IconButtonS = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

export function AddAssetButton({
  dispatch,
  project,
  projectId,
  isRecurring,
  permissions,
}) {
  const ifHasPermission = useHasUserAccessWithPermission(permissions);

  return (
    ifHasPermission && (
      <IconButtonS
        fill="#ff8d02"
        onClick={() =>
          dispatch(
            setProjectAssetsModal({
              addAsset: {
                projectId,
                documentId: project._id,
                childType: project.childType,
                propertyId: project?.property?._id,
                isRecurring,
              },
            }),
          )
        }
        hover
      >
        <S type="title">Add Asset</S>
        <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
      </IconButtonS>
    )
  );
}

AddAssetButton.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    property: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
    childType: PropTypes.string,
  }).isRequired,
  projectId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isRecurring: PropTypes.bool,
  permissions: PropTypes.string,
};
