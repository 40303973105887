import * as R from 'ramda';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useCallback, useState } from 'react';
import { push } from 'redux-first-router';
import { ConfirmModal } from '@poly/site-ui';

import {
  AddNonWOPOSuccess,
  AddWOPOSuccess,
  GeneralError,
} from '../../constants/alerts.js';
import { makeBold, removeCurrencyFormatting } from '../../util/general.js';
import { setPOsModal } from '../../redux/actions/index.js';
import { useAddPORequest } from '../../hooks/purchase-orders.js';

// getInputData :: Object -> Object
const getInputData = ({
  modalInfo,
  supplier,
  amount,
  propertyId,
  projectId,
}) => ({
  ...R.pick(
    ['isInScope', 'description', 'filesAttachments', 'glCode'],
    modalInfo,
  ),
  supplierId: supplier.value,
  amount: removeCurrencyFormatting(amount),
  ...(propertyId && { propertyId }),
  ...(projectId && { projectId }),
});

function ConfirmPOModal(props) {
  const dispatch = useDispatch();

  const { supplier, amount, project } = props.modalInfo;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { createPORequest } = useAddPORequest();

  const closeModal = useCallback(
    () => dispatch(setPOsModal({ confirmAddPO: null })),
    [dispatch, setPOsModal],
  );

  const onConfirm = useCallback(async () => {
    setLoading(true);

    const propertyId = props.modalInfo.property?.value;
    const projectId = project?.value;

    const input = getInputData({
      modalInfo: props.modalInfo,
      supplier,
      amount,
      propertyId,
      projectId,
    });

    return createPORequest(input)
      .then(({ data }) => {
        setLoading(false);
        setError(null);
        dispatch(setPOsModal({ confirmAddPO: null }));
        push(`/purchase-order/${R.path(['createPurchaseOrder', '_id'], data)}`);
        toast.success(R.isNil(projectId) ? AddNonWOPOSuccess : AddWOPOSuccess);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        toast.error(GeneralError);
      });
  }, [
    dispatch,
    props.modalInfo,
    setLoading,
    createPORequest,
    setError,
    dispatch,
    supplier,
    amount,
    project,
  ]);

  const heading = !project.value ? 'Non Work Order' : `WO# ${project.label}`;

  const text = (
    <>
      You are about to submit a {!project.value && 'Non'} Work Order PO Request
      <br />
      for {makeBold(supplier.label)} for {makeBold(amount)}. Please confirm.
    </>
  );

  return (
    <ConfirmModal
      {...props}
      showModal
      subHeading="Confirmation"
      text={text}
      heading={heading}
      closeModal={closeModal}
      onConfirm={onConfirm}
      loading={loading}
      error={error}
    />
  );
}

ConfirmPOModal.propTypes = {
  modalInfo: PropTypes.shape({
    amount: PropTypes.string,
    supplier: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    property: PropTypes.shape({
      value: PropTypes.string,
    }),
    project: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
};

export default function (props) {
  const modalInfo = useSelector(
    (state) => state.purchaseOrders.modals.confirmAddPO,
  );

  return modalInfo ? <ConfirmPOModal {...props} modalInfo={modalInfo} /> : null;
}
