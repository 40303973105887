import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { L, S, Widget } from '@poly/site-book';
import styled from 'styled-components';
import { highlightTextToReactElement } from '@poly/client-utils';
import { formatCurrency as formatCurrencyUtils, formatDate } from '@poly/utils';
import { mapConfigTableProps } from '@poly/site-ui';

const LinkedText = styled(S.withComponent(L))`
  padding-left: ${({ details }) => (details ? '5px' : '2px')};
  font-size: 14px;
  font-weight: ${({ details }) => !details && '700'};
`;

export const isExist = R.complement(R.anyPass([R.isNil, R.isEmpty]));

const MAX_LENGTH = 150;

function TextLengthChanger(props) {
  const { text: textFromProps, search, bold } = props;

  const [text, setText] = useState(textFromProps);
  const [showDetails, setShowDetails] = useState(false);

  const showButton = text && text.length > MAX_LENGTH;

  useEffect(() => {
    if (showButton && !showDetails) {
      setText(text.substring(0, MAX_LENGTH));
    }
    setText(textFromProps);
  }, [showDetails, showButton, textFromProps]);

  const onClick = () => setShowDetails(!showDetails);

  const textValue =
    isExist(text) && isExist(search)
      ? highlightTextToReactElement(search, text)
      : text;

  return (
    <S type="content" {...{ bold }}>
      {isExist(textValue) ? textValue : '—'}
      {!!showButton && (
        <LinkedText onClick={onClick} details={showDetails}>
          {showDetails ? 'Less' : '. . .'}
        </LinkedText>
      )}
    </S>
  );
}

TextLengthChanger.propTypes = {
  bold: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  search: PropTypes.string,
};

export const centerMessage = {
  textAlign: 'center',
  letterSpacing: '2px',
};

export const makeBold = (item) => (
  <S type="content" bold>
    {item}
  </S>
);

export const fieldFormatter = (text, search, bold) => (
  <TextLengthChanger {...{ text, search, bold }} />
);

export const capitalizeFirstLetter = (string) =>
  string && string.charAt(0).toUpperCase() + string.slice(1);

export const formatDateIfExists = R.ifElse(
  R.either(R.isEmpty, R.isNil),
  R.always(''),
  formatDate,
);

export const formatDateWithDashes = (time) =>
  time && moment(time).format('MM-DD-YYYY');

/**
 * formatCurrency :: Number -> String
 */
export const formatCurrency = R.compose(R.concat('$ '), formatCurrencyUtils);

export const removeCurrencyFormatting = (value) => +value.replace(/[$,]/g, '');

export const noDataToDisplay = (entity) =>
  function () {
    return <div {...{ style: centerMessage }}>{`No ${entity} to Display`}</div>;
  };

export const noDataToDisplayWidget = (entity) =>
  function () {
    return (
      <Widget.Item {...{ style: centerMessage }}>
        {`No ${entity} to Display`}
      </Widget.Item>
    );
  };

export const lessThen = (number) =>
  R.compose(R.lt(R.__, number), R.prop('value'));

export const entityFormatter = mapConfigTableProps;

export const splitBySpaces = (value) => value.trim().split(/\s+/);

export const getValue = (key, event) => R.path(['target', key, 'value'], event);
