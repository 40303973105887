import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { Loader } from '@poly/site-ui';
import { PurchaseOrderUpdates } from './tabs/updates.js';
import PurchaseOrderNavigation from './navigation.js';
import PurchaseOrderInvoices from './tabs/invoices.js';
import { usePrefetchPurchaseOrder } from '../../hooks/prefetch.js';

const tabs = {
  invoices: () => <PurchaseOrderInvoices />,
  updates: () => <PurchaseOrderUpdates />,
};
function PurchaseOrderData({ activeTab, loading }) {
  const Container = tabs[activeTab];

  return (
    <>
      <PurchaseOrderNavigation />
      <section>{loading ? <Loader /> : <Container />}</section>
    </>
  );
}

PurchaseOrderData.propTypes = {
  loading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default function (props) {
  const activeTab = useSelector((state) => state.purchaseOrders.activeTab);
  const id = useSelector((state) => state.location.payload.id);

  const { loading } = usePrefetchPurchaseOrder(id);

  return (
    <PurchaseOrderData {...props} activeTab={activeTab} loading={loading} />
  );
}
