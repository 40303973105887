import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Header, HeadingH1, Tabs } from '@poly/site-book';
import { ALERTS } from '@poly/site-ui';

import {
  generateFileNameByTitle,
  openPrintWindowWithData,
} from '@poly/client-utils';
import { UpRow } from '../../styled.js';
import DateSelector from './date-selector.js';
import TableWOSearch from '../../containers/completed-projects/search.js';
import TablePMSearch from '../../containers/completed-pm-projects/search.js';
import {
  ExportButtonStats,
  PrintAndExportButtons,
} from '../table/print-and-export-buttons.js';
import {
  exportCompletedPMToXLS,
  exportCompletedPMTStatsToXLS,
} from '../../containers/completed-pm-projects/export-to-xls.js';
import {
  exportCompletedWOToXLS,
  exportCompletedWOStatsToXLS,
} from '../../containers/completed-projects/export-to-xls.js';
import {
  PrintLayout,
  printVisibleTable,
} from '../../utils/print-visible-table.js';
import CompletedPMsStats from '../completed-pms-stats/index.js';

const TABS = [
  { name: 'Work Orders', value: 'projects' },
  { name: 'Stats', value: 'stats' },
];

const TabsWrapper = styled(Tabs)`
  align-self: flex-end;
  margin-bottom: 2px;
`;

const TabsItems = styled(Tabs.Items)`
  border-bottom: none;
  padding-left: 0;
`;

const TabsItem = styled(Tabs.Item)`
  font-size: 14px;
  padding-bottom: 20px;

  &:not(:last-child) {
    margin-right: 40px;
  }
`;

const printPMStats =
  ({ title }) =>
  (exportTableProps) => {
    if (R.isEmpty(exportTableProps)) {
      toast.error(ALERTS.NoDataToDisplay);
      return Promise.resolve(null);
    }

    return openPrintWindowWithData({
      ...exportTableProps,
      fileName: generateFileNameByTitle(title),
      Table: CompletedPMsStats,
      metaData: { title },
      Layout: PrintLayout,
    });
  };

function getExportButtonComponent({ activeTab, isPM, isOnHold }) {
  if (activeTab !== 'stats') {
    return null;
  }

  return function () {
    const exportStatsFunction = isPM
      ? exportCompletedPMTStatsToXLS
      : exportCompletedWOStatsToXLS(isOnHold);

    return <ExportButtonStats exportStatsFunction={exportStatsFunction} />;
  };
}
function DateFilter({
  activeTab,
  onClick,
  title,
  isPM,
  isWO,
  isOnHold,
  ...props
}) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>{title}</HeadingH1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            activeTab={activeTab}
            printTitle={title}
            printFunction={
              activeTab === 'stats' && isPM ? printPMStats : printVisibleTable
            }
            exportFunction={
              isPM ? exportCompletedPMToXLS : exportCompletedWOToXLS(isOnHold)
            }
            ExportButtonComponent={getExportButtonComponent({
              activeTab,
              isPM,
              isOnHold,
            })}
          />
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Header.Block>
          <DateSelector {...props} />
        </Header.Block>
        <Header.Block>
          {!!isWO && <TableWOSearch />}
          {!!isPM && <TablePMSearch />}
        </Header.Block>
      </UpRow>
      <UpRow>
        <Header.Block>
          <TabsWrapper>
            <TabsItems>
              {TABS.map(({ value, name }) => (
                <TabsItem
                  bold
                  key={value}
                  href={`#${value}`}
                  active={value === activeTab}
                  onClick={() => onClick(value)}
                >
                  {name}
                </TabsItem>
              ))}
            </TabsItems>
          </TabsWrapper>
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

DateFilter.propTypes = {
  activeTab: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isPM: PropTypes.bool,
  isWO: PropTypes.bool,
  isOnHold: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

DateFilter.defaultProps = {
  isPM: false,
  isWO: false,
  isOnHold: false,
};

export default DateFilter;
