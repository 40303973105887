import React from 'react';
import styled from 'styled-components';
import { HeadingH1, HeadingH4, S, L } from '@poly/site-book';
import { func } from 'prop-types';

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const ErrorCode = styled(HeadingH4)`
  margin-bottom: 35px;
  color: #ee0d45;
`;

const ErrorMessage = styled(HeadingH1)`
  margin-bottom: 15px;
  font-size: 36px;
`;

export function NotFound({ LinkToMain }) {
  return (
    <Wrapper>
      <ErrorCode>404 ERROR</ErrorCode>
      <ErrorMessage>That page doesn&#39;t exist</ErrorMessage>
      <S type="content">
        You can go to your <LinkToMain />
        &nbsp;or&nbsp;
        <L href="mailto:sitesupport@aanthonycorp.com">Contact Us</L> about the
        problem
      </S>
    </Wrapper>
  );
}

NotFound.propTypes = {
  LinkToMain: func,
};
