import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { prepareAccessItemsForUserByTypes } from '@poly/client-utils';
import {
  CLIENT_ID_AIT,
  USER_GROUP_ID_AIT,
  CREATE_USER_PERMISSION,
  FLIPPED_CONTAINS_AIT_OPERATOR,
} from '@poly/security';

import { AbsoluteLoader } from '@poly/site-ui';
import { EditStaffError } from '../../constants/alerts.js';
import { ALL_RESULTS } from '../../constants/pagination.js';
import FilterComponent from '../../components/staff/staff-filter.js';
import { checkPermissionByOneAccessItem } from '../../utils/user/index.js';
import {
  setStaffPaginationCurrentPage,
  setStaffPaginationPageSize,
  setStaffPaginationTotal,
  setStaffFilter,
  setStaffModal,
} from '../../redux/actions/index.js';
import { useUserGroupListQuery } from '../../hooks/useUserGroupListQuery.js';

// getSiteUserGroupIds :: [UserGroup] -> [ID]
const getSiteUserGroupIds = R.map(R.prop('_id'));

export default function StaffFilter(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const clientId = useSelector((state) => state.user.clientId);

  const activeFilter = useSelector((state) => state.staff.typeFilter);

  const label = useSelector((state) => state.staff.paginationDropdownLabel);

  const { userGroups } = useUserGroupListQuery();

  const onClick = useCallback(
    (filter, total) => {
      if (label === ALL_RESULTS) {
        dispatch(setStaffPaginationPageSize(total));
      }

      dispatch(setStaffFilter(filter));
      dispatch(setStaffPaginationTotal(total));
      dispatch(setStaffPaginationCurrentPage(1));
    },
    [label, dispatch],
  );

  const openAddStaffModal = useCallback(() => {
    const siteUserGroupIds = getSiteUserGroupIds(userGroups);

    const ait = {
      [CLIENT_ID_AIT]: user?.clientId,
      [USER_GROUP_ID_AIT]: {
        [FLIPPED_CONTAINS_AIT_OPERATOR]: siteUserGroupIds,
      },
    };

    const preparedUser = prepareAccessItemsForUserByTypes(ait)(user);

    const hasPermission = checkPermissionByOneAccessItem(
      CREATE_USER_PERMISSION,
      preparedUser,
      ait,
    );

    if (hasPermission) {
      return dispatch(setStaffModal({ type: 'add' }));
    }

    return toast.error(EditStaffError);
  }, [user, dispatch, userGroups]);

  if (!clientId) return <AbsoluteLoader />;

  return (
    <FilterComponent
      {...props}
      onClick={onClick}
      activeFilter={activeFilter}
      openAddStaffModal={openAddStaffModal}
      clientId={clientId}
    />
  );
}
