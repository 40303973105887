import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TechniciansModal from '../../../components/pm-projects/technicians-modal.js';
import { setPmProjectsTechnicianModal } from '../../../redux/actions/index.js';

export default function (props) {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const show = useSelector((state) => !!state.PMProjects.techniciansModal);

  if (!show) return null;

  const closeModal = () => dispatch(setPmProjectsTechnicianModal(null));
  const onChange = ({ target: { value } }) => setSearchTerm(value);

  return (
    <TechniciansModal
      {...props}
      show={show}
      onChange={onChange}
      closeModal={closeModal}
      searchTerm={searchTerm}
    />
  );
}
