import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { highlightTextToReactElement } from '@poly/client-utils';
import { Icon, IconButton } from '@poly/site-book';
import { propEqLegacy } from '@poly/utils';

import { Linked } from './index.js';
import store from '../../redux/store.js';
import { setPOsModal } from '../../redux/actions/index.js';
import { usePOPermissionsHelper } from './pos-hooks.js';
import { isExist } from '../../util/general.js';

const openPOModal = (data) =>
  store.dispatch(setPOsModal({ editApprovePO: data }));

// isPOAllowedToApprove :: PurchaseOrder -> Boolean
export const isPOAllowedToApprove = R.both(
  propEqLegacy('isOwn', false),
  propEqLegacy('isNotAllowed', false),
);

/**
 * editPOIcon :: Dispatch -> {User} -> React.Component
 */

export function EditPOIcon(props) {
  const { ifHasPermission, onClick } = usePOPermissionsHelper({
    orderProp: 'order',
    userProp: 'user',
    handlerName: 'onClick',
    restProps: props,
  });

  return ifHasPermission ? (
    <IconButton {...{ onClick }}>
      <Icon name="edit" fill="#9fa1ab" dimensions={{ height: 14 }} />
    </IconButton>
  ) : null;
}

const MineTag = styled.span.attrs(() => ({
  children: 'Mine',
}))`
  color: red;
  margin-left: 5px;
`;

// approvePOFormatter :: String -> {PurchaseOrder} -> React.Component
export const approvePOFormatter = (search) => {
  function FormatterComp(props) {
    return (
      <Linked
        bold
        onClick={
          isPOAllowedToApprove(props) ? () => openPOModal(props) : R.identity
        }
      >
        {[
          isExist(search)
            ? highlightTextToReactElement(search, R.prop('cardNumber', props))
            : R.prop('cardNumber', props),
          !!props.isOwn && <MineTag key={R.prop('cardNumber', props)} />,
        ]}
      </Linked>
    );
  }

  FormatterComp.propTypes = {
    isOwn: PropTypes.bool.isRequired,
  };

  return FormatterComp;
};
