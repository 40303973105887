import React from 'react';
import PropTypes from 'prop-types';
import { Holder, Pagination as PolyBookPagination } from '@poly/site-book';
import styled from 'styled-components';
import { ALL_RESULTS, PAGE_SIZE_OPTIONS } from '../../constants/pagination.js';
import { PaginationSelect } from './PaginationSelect.js';

const StyledHolder = styled(Holder)`
  justify-content: flex-end;
`;

export function Pagination({
  size,
  total,
  onChange,
  currentPage,
  dropdownLabel,
  onShowSizeChange,
}) {
  return (
    <StyledHolder>
      <PolyBookPagination
        pageSize={size}
        current={currentPage}
        {...{
          total,
          onChange,
        }}
      />
      <PaginationSelect
        disabled={!total}
        onChange={onShowSizeChange}
        value={{
          value: size,
          label: dropdownLabel,
        }}
        options={[...PAGE_SIZE_OPTIONS, { value: total, label: ALL_RESULTS }]}
      />
    </StyledHolder>
  );
}

Pagination.propTypes = {
  size: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  dropdownLabel: PropTypes.string.isRequired,
  onShowSizeChange: PropTypes.func.isRequired,
};
