import { useState, useCallback } from 'react';

export const useLoading = (asyncAction) => {
  const [isLoading, setIsLoading] = useState(false);

  const performAsyncAction = useCallback(() => {
    Promise.resolve(setIsLoading(true))
      .then(() => asyncAction())
      // better ui since no blinking loading
      .finally(() => setTimeout(() => setIsLoading(false), 800));
  }, [asyncAction, setIsLoading]);

  return {
    performAsyncAction,
    isLoading,
  };
};
