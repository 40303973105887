import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { SearchGrid } from '@poly/site-ui';

import {
  highlightProject,
  highlightProjectStatus,
} from '../../../utils/highlight/index.js';
import { projectsDescriptionFormatter } from '../../../utils/global-search/index.js';

const columnConfig = (search) => [
  [R.identity, highlightProject(search)],
  [R.identity, projectsDescriptionFormatter(search)],
  [R.identity, highlightProjectStatus(search)],
];

function ProjectsGlobalSearch({ hits, searchTerm }) {
  const gridColumns = `
      minmax(80px, 110px)
      auto
      minmax(80px, 130px)
    `;

  return (
    <SearchGrid
      rows={hits}
      columnConfig={columnConfig}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
    />
  );
}

ProjectsGlobalSearch.propTypes = {
  searchTerm: PropTypes.string,
  hits: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  ),
};

export default ProjectsGlobalSearch;
