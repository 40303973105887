import React from 'react';
import { Layout } from '@poly/site-book';

import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import TechnicianStatsLayout from '../layouts/technician-stats.js';
import Navigation from '../containers/navbar/navigation-container.js';
import TechnicianStatsFilter from '../containers/technician-stats/filter.js';

function TechnicianStats() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <TechnicianStatsFilter />
        </Layout.Header>
        <LayoutContent>
          <TechnicianStatsLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default TechnicianStats;
