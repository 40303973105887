import React from 'react';
import { Layout } from '@poly/site-book';

import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import InvoicesLayout from '../layouts/invoices-layout.js';
import Navigation from '../containers/navbar/navigation-container.js';
import InvoicesFilter from '../components/invoices/invoices-filter.js';

function Invoices() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <InvoicesFilter />
        </Layout.Header>
        <LayoutContent>
          <InvoicesLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default Invoices;
