import React, { useState } from 'react';
import PropTypes, { bool, func, number, shape, string } from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { S, Dropdown } from '@poly/site-book';
import styled from 'styled-components';

const DropdownWrapper = styled(Dropdown)`
  width: auto;
  height: 25px;
  min-width: 0;
  display: inline-flex;
`;

const DropdownHeader = styled(Dropdown.Header)`
  min-height: 0;
  height: 25px;
  padding: 0 40px 0 15px;
  line-height: 25px;
  background-color: #fff;

  &:hover {
    background-color: #fff;
  }
`;

const DropdownPip = styled(Dropdown.Pip)`
  right: 15px;
`;

const DropdownItems = styled(Dropdown.Items)`
  top: auto;
  bottom: calc(100% + 4px);
  z-index: 6;
`;

function PaginationSelectC({
  selected,
  onSelect,
  open,
  toggleOpen,
  options,
  disabled,
}) {
  return (
    <DropdownWrapper onClick={toggleOpen}>
      <DropdownHeader disabled={disabled}>
        <S type="content" textColor="#6f7a9f">
          {selected.label}
        </S>
      </DropdownHeader>
      <DropdownPip {...{ open }} />
      <DropdownItems {...{ open }}>
        {options.map((option) => (
          <Dropdown.Item key={option.label} onClick={() => onSelect(option)}>
            <S type="content" textColor="#6f7a9f">
              {option.label}
            </S>
          </Dropdown.Item>
        ))}
      </DropdownItems>
    </DropdownWrapper>
  );
}

PaginationSelectC.propTypes = {
  selected: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: bool,
};

export function PaginationSelect({
  value,
  onChange,
  onClick,
  disabled,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(value);

  const onSelect = (v) => {
    onChange(v);
    setSelected(v);
  };

  const toggleOpen = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <PaginationSelectC
        open={open}
        selected={selected}
        onSelect={onSelect}
        toggleOpen={toggleOpen}
        value={selected}
        disabled={disabled}
        {...props}
      />
    </OutsideClickHandler>
  );
}

PaginationSelect.propTypes = {
  value: shape({
    value: number.isRequired,
    label: string.isRequired,
  }),
  onChange: func.isRequired,
  onClick: func,
  disabled: bool,
};
