import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { Loader } from '@poly/site-ui';
import People from '../../../../components/property/tabs/people/index.js';
import { entityFormatter, fieldFormatter } from '../../../../util/general.js';
import { setPropertySidebar } from '../../../../redux/actions/properties.js';
import { propertyUserFormatter } from '../../../../util/property-utils.js';
import { emailLinkFormatter } from '../../../../util/project-utils.js';
import { setPropertyPeopleModal } from '../../../../redux/actions/index.js';
import { getUserWorkPhone } from '../../../../utils/staff/index.js';
import { usePropertyUsers } from '../../../../hooks/property/people.js';

const usersColumns = (dispatch, action) => [
  ['Name', R.identity, propertyUserFormatter(dispatch, action)],
  ['Title', R.prop('title'), fieldFormatter],
  ['Work', getUserWorkPhone, fieldFormatter],
  ['Email', R.prop('email'), emailLinkFormatter()],
];

export default function (props) {
  const dispatch = useDispatch();

  const propertyId = useSelector((state) => state.location.payload.id);
  const selectedUser = useSelector((state) => state.properties.modals.sidebar);

  const { data, usersLoading, users, defaultAddress } =
    usePropertyUsers(propertyId);

  const openAddPeopleModal = useCallback(
    () =>
      dispatch(
        setPropertyPeopleModal({
          type: 'add',
          data: { defaultAddress },
        }),
      ),
    [dispatch, setPropertyPeopleModal, defaultAddress],
  );

  const openEditPeopleModal = useCallback(
    () =>
      dispatch(
        setPropertyPeopleModal({
          type: 'edit',
          data: { defaultAddress },
        }),
      ),
    [dispatch, setPropertyPeopleModal, defaultAddress],
  );

  const closeSidebar = useCallback(
    () => dispatch(setPropertySidebar(null)),
    [dispatch, setPropertySidebar],
  );

  const usersObj = entityFormatter(
    users,
    usersColumns(dispatch, setPropertySidebar),
  );

  if (usersLoading) return <Loader />;

  return (
    <People
      {...props}
      {...data}
      users={usersObj}
      selectedUser={selectedUser}
      closeSidebar={closeSidebar}
      openAddPeopleModal={openAddPeopleModal}
      openEditPeopleModal={openEditPeopleModal}
      defaultAddress={defaultAddress}
    />
  );
}
