import { useMutation } from '@apollo/client';
import { InvoicePOStatuses } from '@poly/constants';

import {
  invoicesQuery,
  createInvoice,
  updateInvoice,
  invoicesPOChanged,
  postMultiplePOInvoices,
  blockMultiplePOInvoices,
  approveMultiplePOInvoices,
} from '../queries/index.js';
import { useReactiveEntities } from './useReactiveEntities.js';

export const useCreateInvoice = (withJobCostRefetch) => {
  const [mutate] = useMutation(createInvoice, {
    alias: 'useCreateInvoice',
    refetchQueries: [withJobCostRefetch ? 'projectJobCosts' : 'getPOInvoices'],
  });

  return { createInvoice: (input) => mutate({ variables: { input } }) };
};

export const useBlockMultipleInvoices = () => {
  const [mutate] = useMutation(blockMultiplePOInvoices, {
    alias: 'useBlockMultipleInvoices',
  });

  return {
    blockInvoices: (input) => mutate({ variables: { input } }),
  };
};

export const useUpdateInvoice = () => {
  const [mutate] = useMutation(updateInvoice, {
    alias: 'useUpdateInvoice',
    refetchQueries: ['getPOInvoices'],
  });

  return {
    updateInvoice: (id, update) => mutate({ variables: { id, update } }),
  };
};

export const useInvoicesQuery = ({
  searchTerm,
  query,
  pageSize,
  from,
  sort,
}) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: invoicesQuery,
      gqlChangedQuery: invoicesPOChanged,
      query,
      sort,
      from,
      pageSize,
      searchTerm,
      alias: 'useInvoicesQuery',
    });

  const restInvoicesProps = {
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    ...result,
  };

  const invoices = data?.searchPOInvoices.hits || [];
  const total = data?.searchPOInvoices.total || 0;

  return {
    invoices,
    total,
    invoicesLoading: loading,
    restInvoicesProps,
  };
};

export const useApproveMultipleInvoices = () => {
  const [mutate] = useMutation(approveMultiplePOInvoices, {
    alias: 'useApproveMultipleInvoices',
  });

  return {
    approveInvoices: (input) => mutate({ variables: { input } }),
  };
};

export const usePostMultipleInvoices = () => {
  const [mutate] = useMutation(postMultiplePOInvoices, {
    alias: 'usePostMultipleInvoices',
  });

  return {
    postInvoices: (input) => mutate({ variables: { input } }),
  };
};

export const usePendingInvoicesCountQuery = () => {
  const query = { term: { status: InvoicePOStatuses.pending } };

  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: invoicesQuery,
      gqlChangedQuery: invoicesPOChanged,
      query,
      alias: 'usePendingInvoicesCountQuery',
    });

  const pendingInvoicesCount = data?.searchPOInvoices.total || 0;

  return {
    pendingInvoicesCount,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};

export const useApprovedInvoicesCountQuery = () => {
  const query = { term: { status: InvoicePOStatuses.approved } };

  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: invoicesQuery,
      gqlChangedQuery: invoicesPOChanged,
      query,
      alias: 'useApprovedInvoicesCountQuery',
    });

  const approvedInvoicesCount = data?.searchPOInvoices.total || 0;

  return {
    approvedInvoicesCount,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};
