import React, { useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils';
import { Loader } from '@poly/site-ui';

import { MAX_SIZE, SORTING } from '../../../../../constants/index.js';
import AssetsHistory from '../../../../../components/project/assets/history.js';
import { getLinkByProjectType } from '../../../../../utils/projects/get-link-by-type.js';
import {
  fieldFormatter,
  noDataToDisplayWidget,
} from '../../../../../util/general.js';
import { dateFormatter } from '../../../../../util/job-costs.js';

const getProjectAsset = gql`
  query getProjectAsset($projectId: ID) {
    project(projectId: $projectId) {
      _id
      searchAssets {
        hits {
          _id
        }
      }
    }
  }
`;

const getRecurringProjectAsset = gql`
  query getProjectAsset($projectId: ID) {
    recurringProject(projectId: $projectId) {
      _id
      assets {
        _id
      }
    }
  }
`;

const getAssetProjects = gql`
  query getAssetProjects($input: CollectionSearchParams) {
    searchProjects(input: $input) {
      hits {
        _id
        type
        description
        endDate
        expiresAt
        projectId
      }
    }
  }
`;

const assetProjectsSubscription = gql`
  subscription assetProjectsSubscription($input: CollectionSearchParams!) {
    searchProjectChanged(input: $input) {
      id
      type
    }
  }
`;

export const useGetAssetIdByProject = (entity) => {
  const projectId = useSelector((state) => state.location.payload.id);

  const projectQuery =
    entity === 'recurringProject' ? getRecurringProjectAsset : getProjectAsset;

  const assetPath =
    entity === 'recurringProject'
      ? [entity, 'assets', 0, '_id']
      : [entity, 'searchAssets', 'hits', 0, '_id'];

  const { loading, data } = useQuery(projectQuery, {
    variables: { projectId },
  });

  const assetId = R.path(assetPath, data);

  return { assetId, projectLoading: loading };
};

function AssetsLoader() {
  return <Loader size={26} minHeight={54} />;
}

const historyColumns = [
  ['WO #', R.identity, getLinkByProjectType],
  ['Description', R.prop('description'), fieldFormatter],
  ['Completed', R.prop('endDate'), dateFormatter],
];
function AssetsHistoryWrapper({ entity }) {
  const { projectLoading, assetId } = useGetAssetIdByProject(entity);

  const queryOptions = {
    variables: {
      input: {
        from: 0,
        size: MAX_SIZE,
        query: assetId ? { term: { assetIds: assetId } } : null,
        ...SORTING,
      },
    },
    skip: !assetId,
  };
  const { loading, data, debouncedRefetch } = useReactiveQuery(
    getAssetProjects,
    assetProjectsSubscription,
    {
      queryOptions: { ...queryOptions, fetchPolicy: 'network-only' },
      subscriptionOptions: queryOptions,
    },
  );

  useEffect(() => {
    debouncedRefetch();
  }, []);

  const projects = data?.searchProjects.hits;

  if (projectLoading || loading) return <AssetsLoader />;

  if (!assetId) return noDataToDisplayWidget('WO History');

  return <AssetsHistory historyColumns={historyColumns} projects={projects} />;
}

AssetsHistoryWrapper.propTypes = { entity: PropTypes.string.isRequired };

export function ProjectsAssetsHistory() {
  return <AssetsHistoryWrapper entity="project" />;
}

export function RecurringProjectsAssetsHistory() {
  return <AssetsHistoryWrapper entity="recurringProject" />;
}
