import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ConfirmModal } from '@poly/site-ui';

import {
  GeneralError,
  PostSeveralInvoicesSuccess,
} from '../../constants/alerts.js';
import {
  setApprovedSelectedInvoices,
  setInvoicesModal,
} from '../../redux/actions/index.js';
import { usePostMultipleInvoices } from '../../hooks/invoices.js';

export default function (props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const showModal = useSelector((state) => state.invoices.modals.postInvoice);

  const selectedInvoices = useSelector(
    (state) => state.approvedInvoices.selectedInvoices,
  );

  const { postInvoices } = usePostMultipleInvoices();

  const closeModal = useCallback(
    () => dispatch(setInvoicesModal({ postInvoice: false })),
    [setInvoicesModal, dispatch],
  );

  const onConfirm = useCallback(async () => {
    setLoading(true);
    return postInvoices({ poInvoicesIds: selectedInvoices })
      .then(() => {
        setLoading(false);
        dispatch(setApprovedSelectedInvoices([]));
        dispatch(setInvoicesModal({ postInvoice: false }));
        toast.success(PostSeveralInvoicesSuccess(selectedInvoices.length));
      })
      .catch(() => {
        toast.error(GeneralError);
        setLoading(false);
      });
  }, [selectedInvoices, postInvoices, setLoading, dispatch]);

  const text = `You have posted ${selectedInvoices.length} invoices which 
    will be moved to the Invoice Log`;

  if (!showModal) return null;

  return (
    <ConfirmModal
      {...props}
      showModal
      closeModal={closeModal}
      subHeading="Confirmation"
      text={text}
      onConfirm={onConfirm}
      loading={loading}
      error={error}
      setError={setError}
    />
  );
}
