import React from 'react';
import { Layout } from '@poly/site-book';

import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import Navigation from '../containers/navbar/navigation-container.js';

export function ContractExpirationReport() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
        </Layout.Header>
        <LayoutContent>Under development</LayoutContent>
      </Layout>
    </AuthContainer>
  );
}
