import React from 'react';
import * as R from 'ramda';
import Numeral from 'numeral';
import moment from 'moment';
import { Pip } from '@poly/site-book';
import { ensureIsDate } from '@poly/utils';
import {
  differenceInWeeks,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  getMonth,
  getTime,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import {
  RecurringProjectStatuses,
  RecurringProjectStatusesText,
  ScheduleRepeats,
} from '@poly/constants';

import {
  DAY,
  DAYS,
  MASTER_PROJECT_STATUS_COLORS,
  MONTH,
  QUARTER,
  SEMI_ANNUAL,
  WEEK,
  YEAR,
} from '../../constants/report/master-recurring.js';

const formatNumber = (number) => Numeral(number).format('Oo');

export const recurringProjectStatusTransform = (status) => {
  const color =
    MASTER_PROJECT_STATUS_COLORS[
      status === RecurringProjectStatuses.ACTIVE
        ? RecurringProjectStatusesText.ACTIVE
        : RecurringProjectStatusesText.CLOSED
    ];

  return <Pip {...{ color }} />;
};

const modifyDays = DAYS.map((day, index) => ({ label: day, value: index }));

export const checkRepeats = R.cond([
  [R.equals(WEEK), R.always(modifyDays)],
  [R.equals(MONTH), R.always(MONTH)],
  [R.equals(QUARTER), R.always(QUARTER)],
  [R.equals(SEMI_ANNUAL), R.always(SEMI_ANNUAL)],
  [R.T, R.always([])],
]);

const checkWeekDifference = (point, date) =>
  differenceInWeeks(date, point(date));

const checkWeekInMonth = (date) => {
  const trueDate = ensureIsDate(date);

  if (checkWeekDifference(startOfMonth, trueDate) === 0) {
    return 'First';
  }
  if (checkWeekDifference(endOfMonth, trueDate) === 0) {
    return 'Last';
  }
  // +1 is the fix for correct day display
  return formatNumber(checkWeekDifference(startOfMonth, trueDate) + 1);
};

const checkWeekInQuarter = (date) => {
  const trueDate = ensureIsDate(date);

  if (checkWeekDifference(startOfQuarter, trueDate) === 0) {
    return 'First';
  }
  if (checkWeekDifference(endOfQuarter, trueDate) === 0) {
    return 'Last';
  }
  // +1 is the fix for correct day display
  return formatNumber(checkWeekDifference(startOfQuarter, trueDate) + 1);
};

const inMs = (wrapper, date) => getTime(wrapper(date));

const checkWeekInSemi = (date) => {
  const trueDate = ensureIsDate(date);

  // eslint-disable-next-line
  const halfOfYear = (d) =>
    (inMs(endOfYear, d) - inMs(startOfYear, d)) / 2 + inMs(startOfYear, d);
  const startOfSemi = getMonth(trueDate) <= 6 ? startOfYear : halfOfYear;
  const endOfSemi = getMonth(trueDate) <= 6 ? halfOfYear : endOfYear;

  if (checkWeekDifference(startOfSemi, trueDate) === 0) {
    return 'First';
  }
  if (checkWeekDifference(endOfSemi, trueDate) === 0) {
    return 'Last';
  }
  // +1 is the fix for correct day display
  return formatNumber(checkWeekDifference(startOfSemi, trueDate) + 1);
};

const putString = (date) =>
  R.cond([
    [R.equals(MONTH), () => checkWeekInMonth(date)],
    [R.equals(QUARTER), () => checkWeekInQuarter(date)],
    [R.T, () => checkWeekInSemi(date)],
  ]);

export const formatRepeatsEvery = (momentDate, it, isDayWeek) => {
  const date = moment.isMoment(momentDate) ? momentDate.toDate() : momentDate;

  return `${it}ly on the ${
    it !== WEEK && isDayWeek ? putString(date)(it) : ''
  } ${format(ensureIsDate(date), isDayWeek ? 'EEEE' : 'do')}`;
};

export const formatRepeatsArray = (arr, date) =>
  arr.map((it, index) => {
    const beforeFormatDate = moment.isMoment(date) ? date.toDate() : date;
    const formatString = (form) =>
      `${it}ly on the ${
        R.equals(index, 1) ? putString(beforeFormatDate)(it) : ''
      } ${format(ensureIsDate(beforeFormatDate), form)}`;
    if (index === 0) {
      return {
        label: formatString('do'),
        value: formatString('do'),
      };
    }
    return {
      label: formatString('EEEE'),
      value: formatString('EEEE'),
    };
  });

export const periodsValuesMap = {
  [DAY]: ScheduleRepeats.DAILY,
  [SEMI_ANNUAL]: ScheduleRepeats.SEMIANNUALLY,
  [MONTH]: ScheduleRepeats.MONTHLY,
  [WEEK]: ScheduleRepeats.WEEKLY,
  [QUARTER]: ScheduleRepeats.QUARTERLY,
  [YEAR]: ScheduleRepeats.YEARLY,
};

export const formatPeriods = R.cond([
  [R.equals(DAY), R.always(ScheduleRepeats.DAILY)],
  [R.equals(SEMI_ANNUAL), R.always(ScheduleRepeats.SEMIANNUALLY)],
  [R.T, (period) => `${period}ly`.toLowerCase()],
]);

export const checkRepeatsValue = (repeat) => {
  if (R.is(String, repeat)) {
    const array = repeat.split(' ');
    return !R.isEmpty(array[3]) ? R.indexOf(array[4], DAYS) : null;
  }
  return repeat;
};
