import * as R from 'ramda';
import {
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from '@poly/client-utils';
import { forceTitleCase } from '@poly/utils';

const COLUMN_WIDTH = [30, 25, 25, 25, 25];

const TITLE_ROW = ['Month', "Total WO's", 'Client', 'Facilities'];

// getEyesWideOpenStatsRowConfig :: Stat -> [ExcelExportDataCell]
const getEyesWideOpenStatsRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 5),
  ]),
  R.juxt([
    R.compose(forceTitleCase, R.propOr('', 'monthName')),
    R.compose(Number, R.prop('total')),
    R.compose(Number, R.prop('externalCount')),
    R.compose(Number, R.prop('internalCount')),
  ]),
);

// getEyesWideOpenStatsExcelConfig :: [Stat] -> Object
export const getEyesWideOpenStatsExcelConfig = (stats) => ({
  exportFileName: 'eyes_wide_open_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 5)],
      TITLE_ROW,
    ),
    ...R.map(getEyesWideOpenStatsRowConfig, stats),
  ],
});

export const exportEyesWideOpenStatsToXLS = R.compose(
  performExcelExport,
  getEyesWideOpenStatsExcelConfig,
  R.pathOr([], ['data', 'client', 'eyesWideOpenStats', 'byMonth']),
);
