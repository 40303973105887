import React from 'react';
import PropTypes from 'prop-types';
import { TableComponent } from '@poly/site-ui';
import { Widget } from '@poly/site-book';

import { commonTablePropTypes } from '../../common-prop-types.js';

function PropertyAssets({ assets }) {
  return (
    <Widget.Item>
      <TableComponent
        gridColumns="
        minmax(50px, 150px)
        minmax(100px, 300px)
        minmax(100px, 200px)
        minmax(75px, 170px)
        minmax(75px, 150px)
        minmax(50px, 80px)
      "
        {...assets}
      />
    </Widget.Item>
  );
}

PropertyAssets.propTypes = {
  assets: PropTypes.shape(commonTablePropTypes).isRequired,
};

export default PropertyAssets;
