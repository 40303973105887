import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { L } from '@poly/site-book';
import {
  OldVersionDetectorMessage,
  useOldVersionDetector,
} from '@poly/client-utils/src/useOldVersionDetector.js';

const OldVersionDetectorMessages = styled(OldVersionDetectorMessage)`
  margin-bottom: 10px;
  font-weight: bold;
`;

const LinkS = styled(L)`
  font-weight: bold;
`;

function NewVersionAvailableNotification({ reloadPage, version }) {
  return (
    <>
      <OldVersionDetectorMessages version={version} />
      <LinkS onClick={reloadPage}>Reload</LinkS>
    </>
  );
}

NewVersionAvailableNotification.propTypes = {
  reloadPage: func.isRequired,
  version: string.isRequired,
};

export function SiteOldVersionDetector() {
  const notifyUserAboutNewVersion = (latestVersion, reloadPage) => {
    toast.warning(
      <NewVersionAvailableNotification
        version={latestVersion}
        reloadPage={reloadPage}
      />,
      { autoClose: false, closeButton: false },
    );
  };

  useOldVersionDetector(notifyUserAboutNewVersion);

  return null;
}
