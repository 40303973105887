import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { AssetStatuses } from '@poly/constants';
import { Pip, S } from '@poly/site-book';

import Select from '../../select/index.js';
import { AssetStatusColors } from '../../../constants/asset.js';
import { formatPlaceholder } from '../../../util/select.js';

function ColoredTitle({ label, value }) {
  return (
    <Pip color={AssetStatusColors[value]}>
      <S type="title">{R.toUpper(label)}</S>
    </Pip>
  );
}

ColoredTitle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export function StatusSelect({ name, value: selectValue, onChange }) {
  const options = R.compose(
    R.map(R.applySpec({ label: R.prop(0), value: R.prop(1) })),
    R.toPairs,
  )(AssetStatuses);

  const onStatusSelect = (option) =>
    onChange({ target: { name, value: option ? option.value : option } });

  return (
    <Select
      name={name}
      options={options}
      clearable={false}
      value={selectValue}
      onChange={onStatusSelect}
      optionRenderer={ColoredTitle}
      placeholder={formatPlaceholder('Status')}
    />
  );
}

StatusSelect.displayName = 'AssetModalFormStatusSelect';
StatusSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
