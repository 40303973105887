import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import {
  Conversation,
  IconButton,
  HeadingH3,
  HeadingH5,
  Heading,
  Modal,
  Input,
  Icon,
  Grid,
  S,
} from '@poly/site-book';

import { Loader } from '@poly/site-ui';
import { fieldFormatter, noDataToDisplay } from '../../../util/general.js';
import { assetFormatter } from '../../../utils/global-search/index.js';

function GridCell(props) {
  const GridComponent = Grid.Cell;
  return <GridComponent {...props} vertical="center" />;
}

const Content = styled(Modal.Item.withComponent(Modal.Content))`
  width: 550px;
  height: 300px;
  overflow: auto;
`;

const assetType = {
  _id: PropTypes.string.isRequired,
  equipmentType: PropTypes.string,
  description: PropTypes.string,
};

function GridRow({ addAssetToProject, ...props }) {
  return (
    <Grid.Row>
      <GridCell>
        {assetFormatter(R.pick(['_id', 'equipmentType'], props))}
      </GridCell>
      <GridCell>
        {fieldFormatter(R.prop('description', props), null, true)}
      </GridCell>
      <GridCell>
        <IconButton
          type="button"
          fill="#ff8c00"
          hover
          onClick={addAssetToProject}
        >
          <Icon name="add" fill="#ff8c00" dimensions={{ width: 30 }} />
        </IconButton>
      </GridCell>
    </Grid.Row>
  );
}

GridRow.propTypes = {
  ...assetType,
  addAssetToProject: PropTypes.func.isRequired,
};

function AddAssetToProjectComp({
  assets,
  loading,
  projectId,
  closeModal,
  mutationLoading,
  setAssetsSearch,
  attachAssetToProject,
}) {
  return (
    <Modal show>
      <Modal.Item>
        <Heading>
          <HeadingH5>{projectId}</HeadingH5>
          <IconButton onClick={closeModal}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item margin={20}>
        <HeadingH3 lighter>Add Asset</HeadingH3>
      </Modal.Item>
      <Modal.Item margin={25}>
        <Input
          autoFocus
          name="search-asset"
          placeholder="Start typing assets"
          onChange={setAssetsSearch}
        />
      </Modal.Item>
      <Content>
        {loading || mutationLoading ? (
          <Loader />
        ) : (
          <>
            <Conversation.Item badge>
              <S type="badge" uppercase>
                Available
              </S>
            </Conversation.Item>
            <Conversation.Item>
              {R.isEmpty(assets) ? (
                noDataToDisplay('Data')()
              ) : (
                <Grid columns="1fr 1fr 36px" simple>
                  {R.map(
                    (asset) => (
                      <GridRow
                        {...asset}
                        key={asset._id}
                        addAssetToProject={() =>
                          attachAssetToProject(asset._id)
                        }
                      />
                    ),
                    assets,
                  )}
                </Grid>
              )}
            </Conversation.Item>
          </>
        )}
      </Content>
    </Modal>
  );
}

AddAssetToProjectComp.propTypes = {
  loading: PropTypes.bool,
  mutationLoading: PropTypes.bool.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape(assetType)),
  projectId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  setAssetsSearch: PropTypes.func.isRequired,
  attachAssetToProject: PropTypes.func.isRequired,
};

AddAssetToProjectComp.defaultProps = { loading: false };

export default AddAssetToProjectComp;
