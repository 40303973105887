import React from 'react';
import { useSelector } from 'react-redux';
import { Loader, usePaginatorState } from '@poly/site-ui';
import ProjectUpdatesComponent from '../../../components/project/project-updates.js';
import { useSupplierUpdatesQuery } from '../../../hooks/suppliers.js';
import useFilesUpdates from '../../../hooks/useFiles.js';

export default function (props) {
  const supplierId = useSelector((state) => state.location.payload.id);

  const { paginator } = usePaginatorState();

  const { size, currentPage } = paginator;

  const from = (currentPage - 1) * size;

  const { result } = useSupplierUpdatesQuery({
    additionalVars: { supplierId },
    queryEndpointName: 'supplier',
    from,
    size,
  });

  const documentId = result.supplier?._id;

  const additionalFilesProps = useFilesUpdates({
    documentId,
    collection: 'suppliers',
  });

  if (!result.supplier || result.loading) return <Loader />;

  const updates = result?.supplier.documentUpdates.hits || [];
  const total = result?.supplier.documentUpdates.total;

  return (
    <ProjectUpdatesComponent
      {...props}
      {...result}
      {...additionalFilesProps}
      updates={updates}
      total={total}
    />
  );
}
