import React from 'react';
import { Layout } from '@poly/site-book';

import AuthContainer from '../hooks/auth-container.js';
import ProjectPageLayout from '../layouts/project-page-layout.js';
import Navigation from '../containers/navbar/navigation-container.js';
import ProjectInfoContainer from '../containers/navbar/project-info-container.js';

function Project() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <ProjectInfoContainer />
        </Layout.Header>
        <Layout.Content columns="450px 1fr">
          <ProjectPageLayout />
        </Layout.Content>
      </Layout>
    </AuthContainer>
  );
}

export default Project;
