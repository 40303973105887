import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Grid, Icon, IconButton, S } from '@poly/site-book';
import { highlightTextToReactElement } from '@poly/client-utils';

import { generateAssignedTeam } from './assignedToUtils.js';
import { linkFormatter } from '../../util/project-utils.js';
import { isExist } from '../../util/general.js';

const AssignedToWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AssignedTeamWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & a:not(:last-child) {
    padding-bottom: 5px;
  }
`;

const activeStyles = css`
  transform: scale(1);
`;

const AssignedToIcon = styled(IconButton)`
  transform: scale(0);
  transition: transform 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  will-change: transform;
  background-color: transparent !important;

  &:focus {
    ${activeStyles};
  }
`;

export const HoveredGridRow = styled(Grid.Row)`
  &:hover {
    ${AssignedToIcon} {
      ${activeStyles};
    }
  }
`;

function AssignedToComponent({ search, dispatch, action, project, heading }) {
  const assignedTeam = generateAssignedTeam(project);
  return (
    <AssignedToWrapper>
      <AssignedTeamWrapper>
        {R.isEmpty(assignedTeam) ? (
          <S type="content" textColor="#90939d">
            {isExist(search)
              ? highlightTextToReactElement(search, heading)
              : heading}
          </S>
        ) : (
          R.map(linkFormatter({ bold: false, search }), assignedTeam)
        )}
      </AssignedTeamWrapper>
      <AssignedToIcon
        onClick={() => {
          dispatch(
            action({
              projectId: project.projectId,
              projectDbId: project._id,
            }),
          );
        }}
      >
        <Icon name="edit" fill="#9dacdc" dimensions={{ height: 14 }} />
      </AssignedToIcon>
    </AssignedToWrapper>
  );
}

AssignedToComponent.propTypes = {
  search: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  project: PropTypes.shape({
    _id: PropTypes.string,
    projectId: PropTypes.string,
    technicians: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        fullName: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

AssignedToComponent.defaultProps = {
  search: null,
};

export const assignedToFormatter = (
  search,
  dispatch,
  action,
  heading = 'Queue',
) =>
  function (project) {
    return (
      <AssignedToComponent
        {...{
          dispatch,
          action,
          project,
          heading,
          search,
        }}
      />
    );
  };
