import React from 'react';
import { useDispatch } from 'react-redux';

import { useOutSidebarContext } from '@poly/client-utils';
import { setStaffSidebar } from '../../redux/actions/staff.js';
import UserSidebarContent from '../../containers/staff/sidebar.js';
import { hasUserTechnicianGroup } from '../user/index.js';

export const useOpenUserStaffSidebar = () => {
  const dispatch = useDispatch();

  const { openOutSidebar } = useOutSidebarContext();

  const afterCloseSidebar = () => dispatch(setStaffSidebar(null));

  return (user, isTechnicianFn = hasUserTechnicianGroup) => {
    dispatch(setStaffSidebar(user._id, !isTechnicianFn(user)));
    openOutSidebar({
      id: user._id,
      afterClose: afterCloseSidebar,
      content: <UserSidebarContent />,
    });
  };
};
