import React from 'react';
import PropTypes from 'prop-types';
import { S, Header, Holder, HeadingH1 } from '@poly/site-book';

import { UpRow } from '../../styled.js';
import { AddAssetButton } from './add-asset-btn.js';
import TableSearchContainer from '../../containers/assets-filter/search.js';
import FilterAssetsByProperty from '../../containers/assets-filter/select.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { exportAssetDirectoryToXLS } from './export-to-xls.js';
import { AddAssetModal } from '../../containers/asset/add-asset-modal.js';

function AssetsFilter({ openAddAssetModal, isAddModalOpened }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>Asset Directory</HeadingH1>
        </Header.Block>
        <Header.Block>
          <AddAssetButton {...{ openAddAssetModal }} />
          {isAddModalOpened && <AddAssetModal />}
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={20} center>
          <S type="badge" style={{ flexShrink: 0 }} uppercase>
            Filter by:
          </S>
          <FilterAssetsByProperty />
        </Holder>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Asset Directory"
            exportFunction={exportAssetDirectoryToXLS}
            marginRight="20px"
          />
          <TableSearchContainer />
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

AssetsFilter.propTypes = {
  isAddModalOpened: PropTypes.bool.isRequired,
  openAddAssetModal: PropTypes.func.isRequired,
};

export default AssetsFilter;
