import React from 'react';
import PropTypes from 'prop-types';
import { TableComponent } from '@poly/site-ui';
import { Widget } from '@poly/site-book';

import { commonTablePropTypes } from '../../common-prop-types.js';

function PropertyProjects({ projects }) {
  return (
    <Widget.Item>
      <TableComponent
        gridColumns="
        minmax(75px, 150px)
        minmax(150px, 1fr)
        minmax(75px, 150px)
        minmax(80px, 160px)
      "
        {...projects}
      />
    </Widget.Item>
  );
}

PropertyProjects.propTypes = {
  projects: PropTypes.shape(commonTablePropTypes).isRequired,
};

export default PropertyProjects;
