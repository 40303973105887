import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { getTime, isAfter } from 'date-fns';
import { ensureIsDate } from '@poly/utils';

import {
  centerMessage,
  fieldFormatter,
  formatDateWithDashes,
} from '../../util/general.js';

export function SelectDateRangeMessage({ message }) {
  return <div {...{ style: centerMessage }}>{message}</div>;
}

SelectDateRangeMessage.propTypes = {
  message: PropTypes.string,
};

SelectDateRangeMessage.defaultProps = {
  message: 'Please select a date range for the report then hit Preview',
};

// getTimeByProp :: String -> Object -> Float
// Object = {
//    workCompletionDate: Date
//    startDate: Date
// }
const getTimeByProp = (prop) => R.compose(getTime, ensureIsDate, R.prop(prop));

const dateMath = (item) =>
  (
    (getTimeByProp('workCompletionDate')(item) -
      getTimeByProp('startDate')(item)) /
    86400000
  ).toFixed(2);

// checkInTime :: [Project] -> [Project]
export const checkInTime = (projects) =>
  projects.filter((project) =>
    isAfter(
      ensureIsDate(project.endDate),
      ensureIsDate(project.workCompletionDate),
    ),
  );

export const convertToPercents = (value, all) =>
  // eslint-disable-next-line
  all === 0
    ? '0%'
    : `${((value / all) * 100).toFixed(2).replace(/\.00$/, '')}%`;

export const getAverageDays = (projects) =>
  R.compose(
    R.divide(R.__, projects.length || 1),
    R.sum,
    R.map(dateMath),
  )(projects)
    .toFixed(2)
    .replace(/\.00$/, '');

export const dateFormatter = (search) => (value) =>
  fieldFormatter(formatDateWithDashes(value), search);

/**
 * validateAdditionalEmails :: [SelectedEmail] -> InvalidEmail
 */
export const validateAdditionalEmails = R.find(
  R.propSatisfies(R.complement(R.test(/\S+@\S+\.\S+/)), 'value'),
);
