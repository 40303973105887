import React from 'react';
import * as R from 'ramda';
import { TechnicianSkills } from '@poly/constants';

import { forceTitleCase } from '@poly/utils';
import { MultiSelect } from '../../multiselect/index.js';

const technicianSkillsOptions = R.compose(
  R.map(([label, value]) => ({ label: forceTitleCase(label), value })),
  R.toPairs,
);

export const skillsSelect = ({
  areSkillsEnabled,
  selectTechnicianSkills,
  errors,
  technicianSkills,
}) => ({
  hide: !areSkillsEnabled,
  title: {
    value: 'Skills',
    props: ['required'],
  },
  error: R.prop('technicianSkills', errors),
  item: (
    <MultiSelect
      options={technicianSkillsOptions(TechnicianSkills)}
      handleChange={(skills) => selectTechnicianSkills(skills || [])}
      invalid={R.prop('technicianSkills', errors)}
      value={technicianSkillsOptions(
        R.zipObj(technicianSkills, technicianSkills),
      )}
      placeholder="Please Select Skills..."
    />
  ),
});
