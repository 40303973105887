import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Layout } from '@poly/site-book';
import styled from 'styled-components';
import { useIsTokenValid } from '@poly/client-utils';

import Navigation from '../containers/navbar/navigation-container.js';
import NotFound from '../components/404/index.js';

const WithoutLayout = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function WithLayout(props) {
  return (
    <Layout>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      {R.prop('children', props)}
    </Layout>
  );
}

export function NotFoundPage({ isPageChecking }) {
  const { isTokenValid } = useIsTokenValid();

  const Wrapper = isTokenValid ? WithLayout : WithoutLayout;

  return <Wrapper>{!isPageChecking && <NotFound />}</Wrapper>;
}

NotFoundPage.propTypes = {
  isPageChecking: PropTypes.bool,
};

NotFoundPage.defaultProps = {
  isPageChecking: false,
};
