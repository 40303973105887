import { combineReducers } from 'redux';
import {
  globalSearchReducer as globalSearch,
  imagesGalleryReducer as imagesGallery,
  setFileModalReducer as fileModal,
  paginationReducer,
} from '@poly/site-ui';

import { reducer as location } from './router/index.js';
import completedPMProjects from './reducers/completed-pm-projects.js';
import completedProjects from './reducers/completed-projects.js';
import approvedInvoices from './reducers/approved-invoices.js';
import technicianStats from './reducers/technician-stats.js';
import purchaseOrders from './reducers/purchase-orders.js';
import projectAssets from './reducers/project-assets.js';
import authorization from './reducers/authorization.js';
import MRProjectWOS from './reducers/mr-project-wos.js';
import pageLoading from './reducers/page-loading.js';
import approvedPOs from './reducers/approved-pos.js';
import invoiceLog from './reducers/invoice-log.js';
import PMProjects from './reducers/pm-projects.js';
import properties from './reducers/properties.js';
import suppliers from './reducers/suppliers.js';
import userMenu from './reducers/user-menu.js';
import reportPM from './reducers/report-pm.js';
import projects from './reducers/projects.js';
import invoices from './reducers/invoices.js';
import report from './reducers/report.js';
import assets from './reducers/assets.js';
import POLog from './reducers/po-log.js';
import asset from './reducers/asset.js';
import staff from './reducers/staff.js';
import user from './reducers/user.js';

export default combineReducers({
  paginator: paginationReducer,
  completedPMProjects,
  completedProjects,
  approvedInvoices,
  technicianStats,
  purchaseOrders,
  imagesGallery,
  projectAssets,
  authorization,
  MRProjectWOS,
  globalSearch,
  pageLoading,
  approvedPOs,
  invoiceLog,
  PMProjects,
  properties,
  suppliers,
  fileModal,
  userMenu,
  reportPM,
  location,
  projects,
  invoices,
  report,
  assets,
  staff,
  asset,
  POLog,
  user,
});
