import React from 'react';
import { Holder, Icon, S } from '@poly/site-book';
import styled from 'styled-components';

import { centerMessage } from '../general.js';

const Wrapper = styled(Holder)`
  margin-top: 30px;
  align-self: flex-start;
`;

export const noDataToDisplay = () => (
  <Wrapper margin={20} center>
    <Icon name="folder" fill="#5e6271" dimensions={{ width: 22 }} />
    <S type="content" size={22} bold>
      No Assets to Display
    </S>
  </Wrapper>
);

export const selectPropertyMessage = () => (
  <div {...{ style: centerMessage }}>
    Please Select Property From Filter to Display Assets
  </div>
);
