import React from 'react';
import * as R from 'ramda';
import { bool, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Widget, S, Heading, IconButton, Icon } from '@poly/site-book';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { CREATE_FILE_PERMISSION } from '@poly/security';

import { DeleteFileModal } from '../Modal/DeleteFileModal.js';
import { FilesTable, filesTablePropTypes } from './FilesTable.js';
import { EditFileModal } from '../Modal/EditFileModal.js';
import { AddFileModal } from '../Modal/AddFileModal.js';

const Wrapper = styled.div`
  text-align: center;
  letter-spacing: 2px;
`;

const shouldDisplayTable = (entity) => !!R.path(['rows', 'length'], entity);

const StyledHeading = styled(Heading)`
  justify-content: flex-end;
`;

function AddFileButton({ openAddFileModal }) {
  const hasAccess = useHasUserAccessWithPermission(CREATE_FILE_PERMISSION);

  if (!hasAccess) {
    return null;
  }

  return (
    <StyledHeading>
      <IconButton fill="#ff8d02" onClick={openAddFileModal} hover>
        <S type="title">Add File</S>
        <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
      </IconButton>
    </StyledHeading>
  );
}

AddFileButton.propTypes = {
  openAddFileModal: func,
};

export function FilesTabComp({
  id,
  files,
  withAddNewBtn = true,
  collection,
  documentId,
  openAddFileModal,
}) {
  return (
    <Widget.Item>
      {withAddNewBtn && <AddFileButton {...{ openAddFileModal }} />}
      {!shouldDisplayTable(files) && !withAddNewBtn && (
        <Wrapper>No Files to Display</Wrapper>
      )}
      {shouldDisplayTable(files) && (
        <FilesTable
          {...files}
          gridColumns="80px auto minmax(75px,200px) repeat(2,20px)"
        />
      )}
      <AddFileModal heading={id} />
      <DeleteFileModal
        collection={collection}
        documentId={documentId}
        heading={id}
      />
      <EditFileModal heading={id} />
    </Widget.Item>
  );
}

FilesTabComp.propTypes = {
  id: string,
  files: shape(filesTablePropTypes),
  withAddNewBtn: bool,
  collection: string,
  documentId: string,
  openAddFileModal: func,
};
