import * as R from 'ramda';
import { string, bool, shape, arrayOf, object } from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ProjectType } from '@poly/constants';
import { GlobalSearchNavigation } from '@poly/site-ui';
import { ofArrayLegacy, propEqLegacy } from '@poly/utils';

import SearchLoader from '../../components/loader/search.js';
import StaffContainer from './tabs/staff.js';
import AssetsContainer from './tabs/assets.js';
import ProjectsContainer from './tabs/projects.js';
import RecurringProjectsContainer from './tabs/recurring-projects.js';
import SuppliersContainer from './tabs/suppliers.js';
import PropertiesContainer from './tabs/properties.js';
import POContainer from './tabs/po.js';
import { STANDARD_SIZE } from '../../constants/index.js';
import { noDataToDisplay } from '../../util/general.js';
import { allStaffQuery } from '../../utils/staff/staff-filters.js';
import { siteGlobalSearchQuery } from './global-search-query.js';

const Wrapper = styled.section`
  padding-top: 10px;
  overflow: auto;
  height: calc(100% - 40px);
`;

// siteGlobalSearchConfigs :: [SiteSearchConfig]
const siteGlobalSearchConfigs = [
  {
    title: "WO's",
    value: 'searchProjectsWO',
    container: ProjectsContainer,
  },
  {
    title: "PM'S",
    value: 'searchProjectsPM',
    container: ProjectsContainer,
  },
  {
    title: "Master WO's",
    value: 'searchRecurringProjects',
    container: RecurringProjectsContainer,
  },
  {
    title: 'Properties',
    value: 'searchProperties',
    container: PropertiesContainer,
  },
  { title: 'Assets', value: 'searchAssets', container: AssetsContainer },
  {
    title: 'Suppliers',
    value: 'searchSuppliers',
    container: SuppliersContainer,
  },
  { title: 'Staff', value: 'searchUsers', container: StaffContainer },
  { title: 'PO', value: 'searchPurchaseOrders', container: POContainer },
];

// filterProjectWO :: searchProjects -> [ProjectsWO]
const filterProjectWO = R.compose(
  R.filter(propEqLegacy('type', ProjectType.WORK_ORDER)),
  R.propOr([], 'hits'),
);

// filterProjectPM :: searchProjects -> [ProjectsPM]
const filterProjectPM = R.compose(
  R.filter(propEqLegacy('type', ProjectType.PREVENTIVE_MAINTENANCE)),
  R.propOr([], 'hits'),
);

// calculateMaxScore :: (Number, Number, Number) -> Number
const calculateMaxScore = R.curry(
  (hitsFilteredCount, hitsCount, maxScore) =>
    (hitsFilteredCount / hitsCount) * maxScore,
);

// prepareSearchProject :: Function -> SearchProject
// SearchProject = Object
const prepareSearchProject = (filter) =>
  R.applySpec({
    hits: filter,
    maxScore: R.converge(calculateMaxScore, [
      R.compose(R.length, filter),
      R.compose(R.length, R.prop('hits')),
      R.prop('maxScore'),
    ]),
  });

// prepareSearchProjectsData :: SearchProjects -> SearchProjects
// SearchProjects = Object
const prepareSearchProjectsData = R.converge(R.mergeRight, [
  R.compose(
    R.applySpec({
      searchProjectsWO: prepareSearchProject(filterProjectWO),
      searchProjectsPM: prepareSearchProject(filterProjectPM),
    }),
    R.prop('searchProjects'),
  ),
  R.omit(['searchProjects']),
]);

function GlobalSearchResults({ currentConfig, searchTerm, loading, hits }) {
  if (loading) {
    return <SearchLoader />;
  }

  if (hits.length === 0) {
    return noDataToDisplay(currentConfig.title)();
  }

  const Container = currentConfig.container;
  return <Container hits={hits} searchTerm={searchTerm} />;
}

GlobalSearchResults.propTypes = {
  currentConfig: shape({ title: string.isRequired }).isRequired,
  loading: bool,
  searchTerm: string,
  // eslint-disable-next-line react/forbid-prop-types
  hits: arrayOf(object).isRequired,
};

// sortConfigsByRelevance :: Object -> [SiteSearchConfig]
const sortConfigsByRelevance = (data) =>
  R.sortBy(
    R.compose(
      R.multiply(-1), // descending order
      R.pathOr(0, R.__, data),
      R.append('maxScore'),
      ofArrayLegacy,
      R.prop('value'),
    ),
  )(siteGlobalSearchConfigs);

function GlobalSearchDataContainer() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const searchTerm = useSelector(
    R.path(['globalSearch', 'globalSearchString']),
  );

  const clientId = useSelector(R.path(['user', 'clientId']));

  const isSearchFilled = useSelector(
    R.path(['globalSearch', 'isSearchFilled']),
  );

  // reset tab after every hide/show of panel
  useEffect(() => {
    setActiveTabIndex(0);
  }, [isSearchFilled]);

  const commonQueryInput = { searchTerm, size: STANDARD_SIZE };
  const { data, loading } = useQuery(siteGlobalSearchQuery, {
    variables: {
      input: commonQueryInput,
      staffInput: { ...commonQueryInput, query: allStaffQuery(clientId) },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: searchTerm.length < 3,
  });

  const preparedData = prepareSearchProjectsData(data);

  const sortedConfigs = sortConfigsByRelevance(preparedData);
  const activeSearchConfig = sortedConfigs[activeTabIndex];

  const hits = R.pathOr([], [activeSearchConfig.value, 'hits'], preparedData);

  return (
    <>
      <GlobalSearchNavigation
        configs={sortedConfigs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      <Wrapper>
        <GlobalSearchResults
          currentConfig={activeSearchConfig}
          searchTerm={searchTerm}
          hits={hits}
          loading={loading}
        />
      </Wrapper>
    </>
  );
}

export default GlobalSearchDataContainer;
