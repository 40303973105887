import { useMutation } from '@apollo/client';
import { useEntitySubscriptionQuery } from '@poly/client-utils';

import {
  getPOInvoices,
  createPORequest,
  updatePORequest,
  invoicesPOChanged,
  purchaseOrderQuery,
  approveMultiplePOs,
  PURCHASE_ORDER_SUB,
  purchaseOrdersQuery,
  purchaseOrdersChanged,
} from '../queries/index.js';
import { PENDING_APPROVAL, APPROVED } from '../constants/purchase-orders.js';
import { formQuery } from '../utils/purchase-orders/pos-filters.js';
import {
  useSearchChangedSubscription,
  useReactiveEntities,
} from './useReactiveEntities.js';

export const usePurchaseOrdersQuery = ({
  searchTerm,
  query,
  pageSize,
  from,
  sort,
}) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: purchaseOrdersQuery,
      gqlChangedQuery: purchaseOrdersChanged,
      query,
      sort,
      from,
      pageSize,
      searchTerm,
      alias: 'usePurchaseOrdersQuery',
    });

  const total = data?.searchPurchaseOrders.total || 0;
  const requests = data?.searchPurchaseOrders.hits || [];

  const restPurchaseOrdersProps = {
    ...data,
    ...result,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
  };

  return {
    total,
    requests,
    requestsLoading: loading,
    restPurchaseOrdersProps,
  };
};

export const usePurchaseOrderSub = (props) => {
  const { restProps } = useEntitySubscriptionQuery({
    gqlQuery: purchaseOrderQuery,
    queryEndpointName: 'purchaseOrder',
    gqlSubscription: PURCHASE_ORDER_SUB,
    subscriptionEndpointName: 'purchaseOrderChanged',
    propsOfComponent: props,
    alias: 'usePurchaseOrderSub',
  });

  useSearchChangedSubscription({
    gqlChangedQuery: invoicesPOChanged,
    result: restProps,
    refetch: restProps.refetch,
  });

  return {
    restProps,
  };
};

export const useUpdatePORequest = (projectJobCostsRelation) => {
  const [mutate] = useMutation(updatePORequest, {
    alias: 'useUpdatePORequest',
    refetchQueries: projectJobCostsRelation
      ? ['projectJobCosts']
      : ['purchaseOrderQuery'],
  });

  return {
    updatePORequest: (id, input) =>
      mutate({
        variables: { id, input },
      }),
  };
};

export const useApproveMultiplePOs = () => {
  const [mutate] = useMutation(approveMultiplePOs, {
    alias: 'useApproveMultiplePOs',
    refetchQueries: ['purchaseOrdersQuery'],
  });

  return {
    approveMultiplePOs: (ids) =>
      mutate({
        variables: { ids },
      }),
  };
};

export const usePOInvoicesQuery = ({ additionalVars }) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: getPOInvoices,
      gqlChangedQuery: invoicesPOChanged,
      additionalVars,
      alias: 'usePOInvoicesQuery',
    });

  return {
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};

export const useAddPORequest = () => {
  const [mutate] = useMutation(createPORequest, { alias: 'useAddPORequest' });

  return { createPORequest: (input) => mutate({ variables: { input } }) };
};

export const usePORequestsCountQuery = () => {
  const query = formQuery({ type: PENDING_APPROVAL });

  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: purchaseOrdersQuery,
      gqlChangedQuery: purchaseOrdersChanged,
      query,
      alias: 'usePORequestsCountQuery',
    });

  const requestsCount = data?.searchPurchaseOrders.total || 0;

  return {
    requestsCount,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};

export const useApprovedPOsCountQuery = () => {
  const query = formQuery({ type: APPROVED });

  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: purchaseOrdersQuery,
      gqlChangedQuery: purchaseOrdersChanged,
      query,
      alias: 'useApprovedPOsCountQuery',
    });

  const approvedPOsCount = data?.searchPurchaseOrders.total || 0;

  return {
    approvedPOsCount,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};
