import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@poly/site-ui';
import ProjectDetails from '../../components/pm-project/details.js';
import {
  companyPhoneFormatter,
  getEmailByType,
} from '../../utils/suppliers/index.js';
import { SUPPLIERS_EMAILS } from '../../constants/suppliers.js';
import { setResponseTeamModal } from '../../redux/actions/index.js';
import {
  getManagerByProject,
  getTechniciansByProject,
} from '../mr-project/details.js';
import { useProjectPMDetails } from '../../hooks/project-details.js';

// getProject :: Project -> Object
function getProject(project) {
  return {
    ...R.pick(
      [
        'maintenancePlan',
        'description',
        'property',
        'location',
        '_id',
        'status',
        'type',
      ],
      project,
    ),
    details: project.invoiceDescription,
    addressParts: project.property.address.address_parts,
    client: project.client.name,
    startDate: project.startDate,
    endDate: project.endDate,
    manager: getManagerByProject(project),
    technicians: getTechniciansByProject(project),
    suppliers: R.map(
      (supplier) => ({
        _id: supplier._id,
        name: supplier.company.name,
        email: getEmailByType(
          supplier.company.emails,
          SUPPLIERS_EMAILS.SERVICE,
        ),
        phone: companyPhoneFormatter(supplier.company.phones || []),
      }),
      project.suppliers,
    ),
  };
}

export default function (props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);

  const { project, restProjectPMDetailsProps } = useProjectPMDetails(projectId);

  const openModal = useCallback(
    () =>
      dispatch(setResponseTeamModal({ projectId, projectDbId: project._id })),
    [projectId, project],
  );

  if (!project) return <Loader />;

  const projectObj = getProject(project);

  return (
    <ProjectDetails
      {...props}
      {...restProjectPMDetailsProps}
      openModal={openModal}
      project={projectObj}
    />
  );
}
