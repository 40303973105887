import * as R from 'ramda';
import React, { useState, useCallback } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SupplierEmailsTypes } from '@poly/constants';
import { debounce } from '@poly/utils/src/general.js';
import {
  GeneralError,
  SupplierNameError,
  AddSupplierSuccess,
} from '../../constants/alerts.js';
import AddEditSupplier from '../../components/supplier/add-edit-modal.js';
import {
  checkSupplierNameError,
  filterMultipleEmails,
} from '../../utils/suppliers/index.js';
import { routeSupplier } from '../../redux/actions/router.js';
import { setAddSupplierModal } from '../../redux/actions/index.js';
import {
  getCommonMutationObj,
  pickValueByName,
  getCommonErrors,
  useCommonState,
} from '../../utils/suppliers/common.js';
import {
  useCreateSupplier,
  useSupplierNames,
  useSupplierServiceTypes,
} from '../../hooks/suppliers.js';
import useValidation from '../../hooks/useValidation.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';

const initialEmails = [{ id: uuidV4(), email: '' }];

function AddSupplierModal(props) {
  const dispatch = useDispatch();

  const [emails, setEmails] = useState(initialEmails);
  const [accEmails, setAccEmails] = useState(initialEmails);

  const clientId = useSelector((state) => state.user.clientId);

  const supplierSource = useSelector(
    (state) => state.user.clientConfig.supplierSource,
  );

  const {
    address,
    loading,
    typeSearch,
    nameSearch,
    selectedType,
    activeField,
    activeAccField,
    setAddress,
    setLoading,
    setTypeSearch,
    setNameSearch,
    setActiveField,
    setSelectedType,
    setActiveAccField,
  } = useCommonState();

  const { names } = useSupplierNames(nameSearch);

  const { typesLoading, serviceTypes } = useSupplierServiceTypes({
    typeSearch,
    supplierSource,
  });

  const { createSupplier } = useCreateSupplier();

  const { errors, setError, onChange, validateOnBlur, resetError, validate } =
    useValidation({
      validationRules: () => ({
        name: [{ rule: 'required' }, { rule: 'onlyUSKeyboardCharacters' }],
        address: [{ rule: 'zipLength' }, { rule: 'zip' }, { rule: 'address' }],
        phone: [{ rule: 'phone' }, { rule: 'required' }],
        email: [{ rule: 'email' }, { rule: 'required' }],
        accEmail: [{ rule: 'email' }, { rule: 'required' }],
        ...filterMultipleEmails('email', emails),
        ...filterMultipleEmails('accEmail', accEmails),
      }),
    });

  const { setRef, getRef } = useRefHandlers();

  const onAddField = useCallback(() => {
    setError(null);
    setEmails(emails.concat([{ id: uuidV4(), email: '' }]));
  }, [setError, setEmails]);

  const onAddAccField = useCallback(() => {
    setError(null);
    setAccEmails(accEmails.concat([{ id: uuidV4(), email: '' }]));
  }, [setError, setAccEmails]);

  const onRemoveEmail = useCallback(
    (id) => {
      setError(null);
      setEmails(emails.filter((item) => item.id !== id));
    },
    [setError, setEmails],
  );

  const onRemoveAccEmail = useCallback(
    (id) => {
      setError(null);
      setAccEmails(accEmails.filter((item) => item.id !== id));
    },
    [setError, setAccEmails],
  );

  const debouncedSearch = useCallback(debounce(300)(setNameSearch), []);

  const onSetName = ({ target: { value } }) =>
    debouncedSearch(R.toLower(value.trim()));

  const closeModal = useCallback(
    () => dispatch(setAddSupplierModal()),
    [dispatch, setAddSupplierModal],
  );

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setLoading(true);

      const errorsValue = getCommonErrors(
        validate,
        event,
        emails,
        accEmails,
        address,
      );

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const input = {
        type: 'SUBCONTRACTOR',
        status: 'ACTIVE',
        ...getCommonMutationObj(event, getRef(), address, supplierSource),
      };

      const fax = pickValueByName('fax', event);
      const type = selectedType?.value;

      if (type) {
        input.company.servicesIds = [type];
      }

      if (fax) {
        input.company.fax = fax;
      }

      if (emails.length > 1) {
        R.drop(1, emails).forEach((item, index) => {
          const name = `email${index + 1}`;
          if (pickValueByName(name, event).length > 0) {
            input.company.emails.push({
              email: pickValueByName(name, event),
              type: SupplierEmailsTypes.SERVICE,
            });
          }
        });
      }

      if (accEmails.length > 1) {
        R.drop(1, accEmails).forEach((item, index) => {
          const name = `accEmail${index + 1}`;
          if (pickValueByName(name, event).length > 0) {
            input.company.emails.push({
              email: pickValueByName(name, event),
              type: SupplierEmailsTypes.ACCOUNT,
            });
          }
        });
      }

      return createSupplier(input)
        .then((response) => {
          setLoading(false);
          setError(null);
          dispatch(setAddSupplierModal());
          dispatch(
            routeSupplier({
              id: response.data.createSupplier.supplier._id,
            }),
          );
          toast.success(AddSupplierSuccess);
        })
        .catch((err) => {
          setLoading(false);
          const name = input.company?.name;
          if (checkSupplierNameError(name, err.message)) {
            setError({ name: SupplierNameError(name) });
            return;
          }
          setError({ server: err.message });
          toast.error(GeneralError);
        });
    },
    [
      dispatch,
      createSupplier,
      setLoading,
      setError,
      validate,
      emails,
      address,
      accEmails,
      selectedType,
      getRef,
      supplierSource,
    ],
  );

  return (
    <AddEditSupplier
      {...props}
      errors={errors}
      loading={loading}
      name=""
      phone=""
      address={address}
      emails={emails}
      accEmails={accEmails}
      activeField={activeField}
      activeAccField={activeAccField}
      fax=""
      remarks=""
      setRef={setRef}
      closeModal={closeModal}
      onChange={onChange}
      validateOnBlur={validateOnBlur}
      resetError={resetError}
      onSubmit={onSubmit}
      onAddField={onAddField}
      onAddAccField={onAddAccField}
      onRemoveEmail={onRemoveEmail}
      onRemoveAccEmail={onRemoveAccEmail}
      setAddress={setAddress}
      setActiveField={setActiveField}
      setActiveAccField={setActiveAccField}
      selectedType={selectedType}
      setTypeSearch={setTypeSearch}
      setSelectedType={setSelectedType}
      serviceTypes={serviceTypes}
      typesLoading={typesLoading}
      names={names}
      onSetName={onSetName}
      clientId={clientId}
    />
  );
}

export default function (props) {
  const addSupplierModal = useSelector(
    (state) => state.suppliers.addSupplierModal,
  );

  return addSupplierModal ? (
    <AddSupplierModal {...props} addSupplierModal={addSupplierModal} />
  ) : null;
}
