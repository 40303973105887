import * as R from 'ramda';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { S, Conversation } from '@poly/site-book';
import { ofArrayLegacy } from '@poly/utils';

import { MultiSelect } from '../multiselect/index.js';

const errorText = css`
  padding-left: 15px;
  font-style: italic;
  font-size: 12px;
  color: #ee0d45;
  bottom: -22px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  > :first-child {
    display: flex;
    flex-shrink: 0;
    margin-right: 30px;
  }
`;

const MultiSelectS = styled(MultiSelect)`
  width: 100%;

  .multiselect__indicators {
    display: none;
  }
`;

const Hint = styled(S)`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: -30px;
  left: 90px;
  letter-spacing: normal;

  ${({ invalid }) => invalid && errorText};
`;

const TopLine = styled(Conversation.Item)`
  margin: 15px 0 !important;
`;

function NewProjectMailTo({
  hint,
  value,
  newEmail,
  fieldError,
  withTopLine,
  emailOptions,
  handleChange,
  onInputChange,
}) {
  return (
    <>
      {withTopLine && <TopLine badge />}
      <Wrapper margin={30} center>
        <S type="badge" uppercase>
          Mail To:
        </S>
        <MultiSelectS
          options={emailOptions}
          invalid={!!fieldError}
          placeholder="Start typing emails"
          menuIsOpen={R.complement(R.isEmpty)(newEmail)}
          {...{ value, handleChange, onInputChange }}
        />
        <Hint type={fieldError ? 'content' : 'badge'} invalid={!!fieldError}>
          {fieldError || hint}
        </Hint>
      </Wrapper>
    </>
  );
}

const emailOption = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

NewProjectMailTo.propTypes = {
  newEmail: PropTypes.string,
  withTopLine: PropTypes.bool,
  fieldError: PropTypes.string,
  hint: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(emailOption).isRequired,
  emailOptions: PropTypes.arrayOf(emailOption).isRequired,
};

NewProjectMailTo.defaultProps = {
  newEmail: null,
  fieldError: null,
  withTopLine: false,
};

// getEmailOptions :: String -> Array
const getEmailOptions = R.ifElse(
  R.isNil,
  R.always([]),
  R.pipe(R.applySpec({ value: R.identity, label: R.identity }), ofArrayLegacy),
);

function MailTo(props) {
  const [newEmail, setNewEmail] = useState('');

  const handleChange = (emails) => {
    props.onChange(emails || []);
    setNewEmail('');
  };

  const onInputChange = (input) => setNewEmail(input);

  const fieldError = props.errors?.mailTo;

  const emailOptions = getEmailOptions(newEmail);

  const hint =
    'Need to send a copy of the Work Order to others? ' +
    "Just enter the email addresses here and we'll handle it";

  return (
    <NewProjectMailTo
      {...props}
      handleChange={handleChange}
      onInputChange={onInputChange}
      fieldError={fieldError}
      emailOptions={emailOptions}
      hint={hint}
      newEmail={newEmail}
    />
  );
}

MailTo.propTypes = {
  withTopLine: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(emailOption).isRequired,
  errors: PropTypes.shape({
    mailTo: PropTypes.string,
  }),
};

MailTo.displayName = 'MailTo';

export default MailTo;
