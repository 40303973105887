import React, { useContext } from 'react';

import {
  A,
  ExportTableContext,
  PrintAndExportButtons as PrintAndExportButtonsBase,
} from '@poly/site-ui';
import PropTypes, { func } from 'prop-types';
import { EXPORT_XLS_CAPTION } from '@poly/constants';
import { printVisibleTable } from '../../utils/print-visible-table.js';

export function ExportButtonStats({ exportStatsFunction }) {
  const { exportTableProps } = useContext(ExportTableContext);

  const handleExport = () => {
    exportStatsFunction(exportTableProps);
  };

  return <A onClick={handleExport}>{EXPORT_XLS_CAPTION}</A>;
}

ExportButtonStats.propTypes = {
  exportStatsFunction: PropTypes.func.isRequired,
};

export function PrintAndExportButtons({ ExportButtonComponent, ...props }) {
  const buttonProps = ExportButtonComponent ? { ExportButtonComponent } : {};

  return (
    <PrintAndExportButtonsBase
      printFunction={printVisibleTable}
      {...buttonProps}
      {...props}
    />
  );
}

PrintAndExportButtons.propTypes = {
  ExportButtonComponent: func,
};
