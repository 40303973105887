import React from 'react';
import * as R from 'ramda';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Input } from '@poly/site-book';
import { toast } from 'react-toastify';
import { bool, func, string } from 'prop-types';
import { validateEmail } from '@poly/utils';

import { DuplicateContactMsgBtn } from '../project/EmailInputWithSearchUser.js';
import { updateContactUser } from '../../queries/index.js';

const FIND_USER_WITH_LINKS_BY_EMAIL = gql`
  query FIND_USER_WITH_LINKS_BY_EMAIL($email: Email!) {
    findUserContactByEmail(email: $email) {
      _id
      links {
        clientId
        propertyId
        supplierId
      }
    }
  }
`;

// prepareLinksBeforeUpdate :: [UserLink] -> [UserLink]
const prepareLinksBeforeUpdate = R.map(
  R.compose(R.reject(R.isNil), R.dissoc('__typename')),
);

export function DuplicateUserContactEmailInput({
  email,
  skipSearch,
  entityId,
  entityPropName,
  closeModal,
  ...props
}) {
  const { data, loading } = useQuery(FIND_USER_WITH_LINKS_BY_EMAIL, {
    variables: {
      email,
    },
    skip: skipSearch || !validateEmail(email),
  });

  const [updateUserContact] = useMutation(updateContactUser);

  const userContact = R.prop('findUserContactByEmail', data);

  const addUserContactToEntity = async () => {
    const oldLinks = R.propOr([], 'links', userContact);

    const links = prepareLinksBeforeUpdate([
      ...oldLinks,
      { [entityPropName]: entityId },
    ]);

    await updateUserContact({
      variables: {
        update: {
          links,
          userId: userContact._id,
        },
      },
    });

    toast.success('Contact was added');
    closeModal();
  };

  return (
    <>
      <Input {...props} />
      <DuplicateContactMsgBtn
        userContact={userContact}
        loading={loading}
        onClick={addUserContactToEntity}
      />
    </>
  );
}

DuplicateUserContactEmailInput.propTypes = {
  email: string.isRequired,
  skipSearch: bool.isRequired,
  entityId: string.isRequired,
  entityPropName: string.isRequired,
  closeModal: func.isRequired,
};
