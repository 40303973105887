import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { collectionNames } from '@poly/constants';
import { FilesTab } from '@poly/site-ui';
import { useAssetDetailsSub } from '../../../hooks/assets.js';
import { fileColumns } from '../../../utils/files.js';

export default function (props) {
  const dispatch = useDispatch();

  const assetId = useSelector((state) => state.location.payload.id);

  const propsForAssetDetailsSub = { assetId, ...props };

  const { restProps } = useAssetDetailsSub(propsForAssetDetailsSub);

  const id = restProps?.data?.asset.equipmentType;
  const collection = collectionNames.assets;
  const fileColumnsObj = fileColumns(dispatch);

  return (
    <FilesTab
      {...props}
      {...restProps}
      id={id}
      documentId={assetId}
      fileColumns={fileColumnsObj}
      collection={collection}
    />
  );
}
