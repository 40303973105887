import { format, setDate, setDay } from 'date-fns';
import { ScheduleRepeats } from '@poly/constants';

const getDays = (days) => {
  const date = new Date();

  return days.reduce(
    (p, day, i) =>
      `${p}${i !== 0 ? ', ' : ''}${format(setDay(date, day), 'EEEE')}`,
    '',
  );
};
export const generateRange = ({ from = 0, to, step }) => {
  const items = [];

  for (let item = from; item <= to; item += step) {
    items.push({
      value: item,
      label: item,
    });
  }

  return items;
};
export const schedulerFormatter = ({
  repeats,
  days = [],
  startDate,
  weekDay,
  every,
}) => {
  const startDateDateObject = new Date(startDate);
  const day =
    (weekDay && startDateDateObject.getDay()) ||
    startDateDateObject.getDate() ||
    1;
  const weekDayString = weekDay
    ? `first ${getDays([day])}`
    : format(setDate(new Date(), day), 'do');
  const types = {
    [ScheduleRepeats.DAILY]: () => `Every ${every} days`,
    [ScheduleRepeats.WEEKLY]: () =>
      every > 1
        ? `Every ${every} weeks on ${getDays(days)}`
        : `Weekly on ${getDays(days)}`,
    [ScheduleRepeats.MONTHLY]: () =>
      `${
        every > 1 ? `Every ${every} months` : 'Monthly'
      } on the ${weekDayString}`,
    [ScheduleRepeats.QUARTERLY]: () =>
      `${
        every > 1 ? `Every ${every} quarters` : 'Quarterly'
      } on the ${weekDayString}`,
    [ScheduleRepeats.SEMIANNUALLY]: () =>
      `${
        every > 1 ? `Every ${every} semi-annuals` : 'Semi-Annually'
      } on the ${weekDayString}`,
    [ScheduleRepeats.YEARLY]: () =>
      `${
        every > 1 ? `Every ${every} years ` : 'Annually'
      } on the ${weekDayString}`,
  };
  return types[repeats] ? types[repeats]() : '';
};
