import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Avatar, Conversation, Message, Username } from '@poly/site-book';
import { getFirstLetterOfNames } from '@poly/client-utils';
import { removeFileExtension } from '@poly/utils';

import { urlify } from '../../utils/formatters.js';
import {
  MessageHeader,
  Content,
  DateTime,
  ImagesRow,
  ImageWrapper,
  AttachmentsWrapper,
} from './components.js';
import {
  formatDateWithSlashes,
  checkAvatarType,
  getKeyFromURL,
  getAttachmentIcon,
  filterImages,
} from './utils.js';

export function UpdateItemComment({ user, message, createdAt, attachments }) {
  const { date, time } = formatDateWithSlashes(createdAt);
  const images = filterImages(attachments);
  const restAttachments = R.without(images, attachments);

  return (
    <Conversation.Item>
      <Message>
        <MessageHeader>
          <Username showLink {...user}>
            <Avatar
              type={checkAvatarType(user.firstName)}
              content={getFirstLetterOfNames(user.firstName, user.lastName)}
              size={24}
            />
          </Username>
          <DateTime
            type="badge"
            textColor="#5e6271"
            size={14}
            title={`${date} ${time}`}
          >
            {`${date}   ${time}`}
          </DateTime>
        </MessageHeader>
        <Message.Content>
          <Content type="content" bold>
            {urlify(message || '')}
          </Content>
        </Message.Content>
      </Message>
      <AttachmentsWrapper>
        <ImagesRow>
          {images.map(({ url, fileName }) => (
            <ImageWrapper key={getKeyFromURL(url)}>
              <img src={url} alt={fileName} />
            </ImageWrapper>
          ))}
        </ImagesRow>
        {restAttachments.map(({ url, fileName, fileType }) => (
          <div key={getKeyFromURL(url)}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {getAttachmentIcon(fileType)} {removeFileExtension(fileName)}
            </a>
          </div>
        ))}
      </AttachmentsWrapper>
    </Conversation.Item>
  );
}

UpdateItemComment.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    _id: PropTypes.string.isRequired,
  }),
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      fileType: PropTypes.string.isRequired,
    }),
  ),
  onUserClick: PropTypes.func,
  onImageClick: PropTypes.func,
};
