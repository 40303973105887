import React, { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { UserMenu } from '@poly/site-ui';

import { setSupportModal } from '../../redux/actions/index.js';
import logOut from '../../redux/thunks/logOut.js';

export default function (props) {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const onLogoutClick = () => dispatch(logOut(client));

  const openSupportModal = useCallback(
    () => dispatch(setSupportModal(true)),
    [dispatch, setSupportModal],
  );

  return (
    <UserMenu
      {...props}
      {...user}
      onLogoutClick={onLogoutClick}
      openSupportModal={openSupportModal}
    />
  );
}
