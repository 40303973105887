import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { highlightTextToReactElement } from '@poly/client-utils';
import { Grid, Holder, L, Pip, S } from '@poly/site-book';
import {
  toggleGlobalSearchFocusAction,
  setGlobalSearchStringAction,
} from '@poly/site-ui';

import {
  ASSET,
  PROPERTY,
  SUPPLIER,
  PURCHASE_ORDER,
} from '../../constants/routes.js';
import store from '../../redux/store.js';
import { ALL_STAFF, TECHNICIANS } from '../../constants/staff.js';
import { poStatusUIData } from '../purchase-orders/pos-utils.js';
import { fieldFormatter, isExist } from '../../util/general.js';
import { routeStaff } from '../../redux/actions/router.js';
import { hasUserTechnicianGroup } from '../user/index.js';
import { setStaffSidebar, setStaffFilter } from '../../redux/actions/index.js';
import { UserStaffLink } from '../staff/UserStaffLink.js';

const Wrapper = styled(Grid.Cell)`
  grid-row-gap: 10px;
`;

const A = styled(L.withComponent('span'))`
  display: inline-block;
`;

const linkDispatch = () => {
  store.dispatch(setGlobalSearchStringAction(''));
  store.dispatch(toggleGlobalSearchFocusAction(false));
};

const goToStaff = (user) => {
  const hasTechGroup = hasUserTechnicianGroup(user);

  linkDispatch();
  store.dispatch(setStaffSidebar(user._id, hasTechGroup));

  if (hasTechGroup) {
    store.dispatch(setStaffFilter(TECHNICIANS));
  } else {
    store.dispatch(setStaffFilter(ALL_STAFF));
  }
};

export const projectsDescriptionFormatter = (search) =>
  function (project) {
    const { description, client, property } = project;
    return (
      <Wrapper>
        {fieldFormatter(description, search)}
        <S type="content" bold>
          {highlightTextToReactElement(search, client.name)} -{' '}
          {highlightTextToReactElement(search, property.name)}
        </S>
      </Wrapper>
    );
  };

export const poDescriptionFormatter = (search) =>
  function (po) {
    const { description, supplier, property } = po;
    return (
      <Wrapper>
        {fieldFormatter(description, search)}
        <S type="content" bold>
          {highlightTextToReactElement(
            search,
            R.pathOr('', ['company', 'name'], supplier),
          )}{' '}
          -{' '}
          {highlightTextToReactElement(
            search,
            R.pathOr('', ['name'], property),
          )}
        </S>
      </Wrapper>
    );
  };

// getPOStatus :: { status: String } -> String
const getPOStatus = R.propOr('', 'status');

// getPOParamByStatus :: String -> { status: String } -> String
const getPOParamByStatus = (param) =>
  R.compose(R.prop(param), poStatusUIData, getPOStatus);

export const poStatusFormatter = (search) =>
  function (props) {
    return (
      <Pip color={getPOParamByStatus('color')(props)}>
        <S type="title">
          {isExist(search)
            ? highlightTextToReactElement(
                search,
                getPOParamByStatus('title')(props),
              )
            : getPOStatus(props)}
        </S>
      </Pip>
    );
  };

export const propertiesDescriptionFormatter = (search) =>
  function (property) {
    const { _id: id, name, client, address } = property;
    return (
      <Wrapper>
        <Link to={{ type: PROPERTY, payload: { id } }} onClick={linkDispatch}>
          <A bold>{highlightTextToReactElement(search, name)}</A>
        </Link>
        <S type="content">
          {highlightTextToReactElement(search, R.prop('name', client))}
        </S>
        <S type="content" bold>
          {highlightTextToReactElement(
            search,
            R.prop('formatted_address', address),
          )}
        </S>
      </Wrapper>
    );
  };

// getAssetParamsForFormatter :: Asset -> { serial: String, description: String }
const getAssetParamsForFormatter = R.applySpec({
  serial: R.propOr('', 'serial'),
  description: R.propOr('', 'description'),
});

export const assetsDescriptionFormatter = (search) =>
  function (props) {
    const { serial, description } = getAssetParamsForFormatter(props);

    const element = isExist(search)
      ? highlightTextToReactElement(search, serial)
      : serial;

    return (
      <Wrapper>
        {fieldFormatter(description, search, true)}
        <Holder margin={15}>
          <S type="title">Serial#</S>
          <S type="title">{isExist(serial) ? element : 'No Record Found'}</S>
        </Holder>
      </Wrapper>
    );
  };

// getSupplierParamsForFormatter :: Supplier -> SupplierFormatterParams
// SupplierFormatterParams = {
//   id: ID
//   name: String
//   address: String
// }
const getSupplierParamsForFormatter = R.applySpec({
  id: R.prop('_id'),
  name: R.pathOr('', ['company', 'name']),
  address: R.pathOr('', ['company', 'address', 'formatted_address']),
});

export const suppliersDescriptionFormatter = (search) =>
  function (props) {
    const { id, name, address } = getSupplierParamsForFormatter(props);

    const element = isExist(search)
      ? highlightTextToReactElement(search, name)
      : name;

    return (
      <Wrapper>
        <Link to={{ type: SUPPLIER, payload: { id } }} onClick={linkDispatch}>
          <A bold>{isExist(name) ? element : '—'}</A>
        </Link>
        {fieldFormatter(address, search, true)}
      </Wrapper>
    );
  };

export const projectFormatter = (type, id, search) => (
  <Link to={{ type, payload: { id } }} onClick={linkDispatch}>
    <A bold>{isExist(search) ? highlightTextToReactElement(search, id) : id}</A>
  </Link>
);

// getPOParamsForFormatter :: PurchaseOrder -> { id: ID, cardNumber: String }
const getPOParamsForFormatter = R.applySpec({
  id: R.prop('_id'),
  cardNumber: R.propOr('', 'cardNumber'),
});

export const poNumberFormatter = (search) =>
  function (props) {
    const { id, cardNumber } = getPOParamsForFormatter(props);
    return (
      <Link
        to={{ type: PURCHASE_ORDER, payload: { id } }}
        onClick={linkDispatch}
      >
        <A bold>
          {isExist(search)
            ? highlightTextToReactElement(search, cardNumber)
            : cardNumber}
        </A>
      </Link>
    );
  };

export const assetFormatter = ({ _id: id, equipmentType }, search) => (
  <Link to={{ type: ASSET, payload: { id } }} onClick={linkDispatch}>
    <A bold>
      {isExist(search)
        ? highlightTextToReactElement(search, equipmentType)
        : equipmentType}
    </A>
  </Link>
);

export const staffLinkFormatter = (search) =>
  function (user) {
    return (
      <Link to={routeStaff()} onClick={() => goToStaff(user)}>
        <UserStaffLink user={user} searchText={search} />
      </Link>
    );
  };

// getStaffParamsForFormatter :: User -> { email: String, phone: String }
const getStaffParamsForFormatter = R.applySpec({
  email: R.propOr('', 'email'),
  phone: R.pathOr('', ['profile', 'cellPhoneNumber']),
});

export const staffDescriptionFormatter = (search) =>
  function (props) {
    const { email, phone } = getStaffParamsForFormatter(props);

    return (
      <Wrapper>
        {fieldFormatter(email, search, true)}
        {fieldFormatter(phone, search, true)}
      </Wrapper>
    );
  };
