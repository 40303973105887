import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { Loader } from '@poly/site-ui';
import PropertyRecurringProjectsContainer from './tabs/recurring.js';
import PropertySuppliersContainer from './tabs/suppliers.js';
import PropertyWorkOrderContainer from './tabs/projects.js';
import PropertyNavigationContainer from './navigation.js';
import PropertyUpdatesContainer from './tabs/updates.js';
import PropertyPeopleContainer from './tabs/people/index.js';
import PropertyAssetsContainer from './tabs/assets.js';
import PropertyFilesContainer from './tabs/files.js';
import { usePrefetchProperty } from '../../hooks/prefetch.js';

const tabs = {
  projects: PropertyWorkOrderContainer,
  recurring: PropertyRecurringProjectsContainer,
  files: PropertyFilesContainer,
  people: PropertyPeopleContainer,
  assets: PropertyAssetsContainer,
  suppliers: PropertySuppliersContainer,
  updates: PropertyUpdatesContainer,
};

function PropertyDataContainer({ activeTab, loading }) {
  const Container = tabs[activeTab];

  return (
    <>
      <PropertyNavigationContainer />
      <section>{loading ? <Loader /> : <Container />}</section>
    </>
  );
}

PropertyDataContainer.propTypes = {
  activeTab: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default function (props) {
  const activeTab = useSelector((state) => state.properties.activeTab);
  const propertyId = useSelector((state) => state.location.payload.id);

  const { loading } = usePrefetchProperty(propertyId);

  return (
    <PropertyDataContainer {...props} activeTab={activeTab} loading={loading} />
  );
}
