import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Header, HeadingH1, HeadingH4, Holder, Title } from '@poly/site-book';

import BackArrow from '../back-arrow/index.js';
import InfoLoader from '../loader/info.js';
import { EditAssetButton } from './edit-asset-btn.js';
import { EditAssetModal } from '../../containers/asset/edit-asset-modal.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

function AssetInfo({ asset, loading, openEditAssetModal, isEditModalOpened }) {
  return (
    <Header.Sub>
      <HeaderRow>
        <Header.Block>
          <Holder margin={20}>
            <BackArrow />
            {loading ? (
              <InfoLoader />
            ) : (
              <Title>
                <Title.Block>
                  <Title.Item>
                    <HeadingH1 lighter>{asset.equipmentType}</HeadingH1>
                  </Title.Item>
                  <Title.Item>
                    <HeadingH4 lighter>Asset</HeadingH4>
                  </Title.Item>
                </Title.Block>
              </Title>
            )}
          </Holder>
        </Header.Block>
        <Header.Block>
          <EditAssetButton {...{ loading, openEditAssetModal }} />
          {isEditModalOpened && <EditAssetModal asset={asset} />}
        </Header.Block>
      </HeaderRow>
    </Header.Sub>
  );
}

AssetInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  asset: PropTypes.shape({
    _id: PropTypes.string,
    equipmentType: PropTypes.string,
  }),
  openEditAssetModal: PropTypes.func.isRequired,
  isEditModalOpened: PropTypes.bool.isRequired,
};

AssetInfo.defaultProps = {
  asset: {},
};

export default AssetInfo;
