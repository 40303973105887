import React from 'react';
import { Layout } from '@poly/site-book';

import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import InvoiceLogLayout from '../layouts/invoice-log-layout.js';
import Navigation from '../containers/navbar/navigation-container.js';
import InvoiceLogFilter from '../components/invoices/invoice-log-filter.js';

function InvoiceLog() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <InvoiceLogFilter />
        </Layout.Header>
        <LayoutContent>
          <InvoiceLogLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default InvoiceLog;
