import * as R from 'ramda';
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { MAX_FILE_SIZE } from '@poly/constants';
import { ALERTS, setFileModal } from '@poly/site-ui';
import { useUpdateMutation } from './project-updates.js';
import { useRefHandlers } from './useRefHandlers.js';

const useFilesUpdates = ({ documentId, collection }) => {
  const dispatch = useDispatch();

  const { sendUpdate } = useUpdateMutation({ refetchQueries: {} });

  const [attachments, setAttachments] = useState([]);
  const [scrollbar, setScrollbar] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setRef, getRef } = useRefHandlers();

  const addUpdate = useCallback(
    (e) => {
      setLoading(true);
      e.preventDefault();
      const message = e.target.message.value.trim();
      e.target.message.value = '';
      e.target.message.style.height = '40px';
      e.target.message.focus();

      if (!message && !attachments.length) {
        setLoading(false);
        return;
      }

      Promise.all([
        sendUpdate({
          collection,
          attachments: R.map(R.omit(['key']), attachments),
          documentId,
          message,
        }),
        setAttachments([]),
      ])
        .then(() => {
          setLoading(false);
          toast.success(ALERTS.AddUpdateSuccess);
        })
        .catch(() => {
          setLoading(false);
          toast.error(ALERTS.GeneralError);
        });
    },
    [sendUpdate, setLoading, documentId, collection, attachments],
  );

  const onFileSelect = useCallback(
    (event) => {
      const file = event.target.files[0];

      if (file) {
        dispatch(
          setFileModal({ type: 'edit', data: { file, useMutation: false } }),
        );
      }
    },
    [dispatch, setFileModal],
  );

  const onFileConfirmed = useCallback(
    (file) => {
      const fileSize = file.upload.size || 0;

      if (fileSize > MAX_FILE_SIZE) {
        toast.error(ALERTS.MaxFileSizeError);
      } else {
        setAttachments([...attachments, file]);
      }
    },
    [setAttachments, attachments],
  );

  const onFileRemove = useCallback(
    (key) => {
      setAttachments(
        R.reject((attachment) => attachment.key === key, attachments),
      );
      setRef('');
    },
    [setAttachments, attachments, setRef],
  );

  const onTextAreaChange = useCallback(
    (event) => {
      const textArea = event.target;

      if (textArea.offsetHeight < textArea.scrollHeight) {
        textArea.style.height = `${textArea.scrollHeight}px`;
      } else {
        textArea.style.height = '1px';
        textArea.style.height = `${textArea.scrollHeight}px`;
      }

      const isScrollbar = textArea.offsetHeight >= 230;

      textArea.style['overflow-y'] = isScrollbar ? 'scroll' : 'hidden';
      setScrollbar(isScrollbar);
    },
    [setScrollbar],
  );

  return {
    addUpdate,
    onFileSelect,
    onFileConfirmed,
    onFileRemove,
    onTextAreaChange,
    attachments,
    scrollbar,
    loading,
    setRef,
    getRef,
  };
};

export default useFilesUpdates;
