import { RecurringProjectStatuses } from '@poly/constants';
import { PROJECT_STATUS_COLORS } from '@poly/site-ui';

const { active, closed } = PROJECT_STATUS_COLORS;

export const recurringProjectStatusesText = {
  [RecurringProjectStatuses.ACTIVE]: 'ACTIVE',
  [RecurringProjectStatuses.CLOSED]: 'CLOSED',
};

export const recurringProjectStatusesColors = {
  [RecurringProjectStatuses.ACTIVE]: active,
  [RecurringProjectStatuses.CLOSED]: closed,
};
