import React from 'react';
import Link from 'redux-first-router-link';
import { L } from '@poly/site-book';
import { NotFound as NotFoundComp } from '@poly/site-ui';

import { routeMain } from '../../redux/actions/router.js';

const A = L.withComponent(Link);

function LinkToMain() {
  return <A to={routeMain()}>Homepage</A>;
}

function NotFound() {
  return <NotFoundComp LinkToMain={LinkToMain} />;
}

export default NotFound;
