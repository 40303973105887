import React from 'react';
import PropTypes from 'prop-types';
import { S } from '@poly/site-book';

import Select from '../select/index.js';
import { formatSearchPlaceholder } from '../../util/select.js';

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function FilterAssetByProperty({
  selectedProperty,
  selectProperty,
  properties,
  propertyLoading,
  setPropertySearch,
  noPropertyResults,
}) {
  return (
    <Select
      value={selectedProperty}
      onChange={selectProperty}
      onInputChange={setPropertySearch}
      isLoading={propertyLoading}
      placeholder={formatSearchPlaceholder('Property')}
      noResultsText={noPropertyResults}
      options={properties}
      optionRenderer={SimpleTitle}
      style={{ width: 300 }}
    />
  );
}

FilterAssetByProperty.propTypes = {
  selectedProperty: PropTypes.string,
  selectProperty: PropTypes.func.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  propertyLoading: PropTypes.bool.isRequired,
  setPropertySearch: PropTypes.func.isRequired,
  noPropertyResults: PropTypes.string,
};

FilterAssetByProperty.defaultProps = {
  properties: [],
  selectedProperty: null,
  noPropertyResults: 'No Results Found',
};

export default FilterAssetByProperty;
