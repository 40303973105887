import * as R from 'ramda';
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { isChildRecurringProject } from '@poly/client-utils';
import PropTypes from 'prop-types';
import { getValue } from '../../util/general.js';
import { ACTIVE } from '../../constants/staff.js';
import EditProject from '../../components/project/edit-project.js';
import { EditProjectSuccess, GeneralError } from '../../constants/alerts.js';
import { setEditProjectModal } from '../../redux/actions/index.js';
import { checkEmailError } from '../../utils/staff/index.js';
import { useRequesters } from '../../hooks/staff.js';
import { usePropertiesQuery } from '../../hooks/properties.js';
import { useServiceTypes, useUpdateProject } from '../../hooks/projects.js';
import { useCreateContactUser } from '../../hooks/property/people.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';
import useValidation from '../../hooks/useValidation.js';

function EditWO(props) {
  const dispatch = useDispatch();

  const { projects, editProjectModal } = props;

  const [search, setSearch] = useState('');
  const [searchService, setSearchService] = useState('');
  const [searchRequestersState, setSearchRequestersState] = useState('');
  const [newRequester, setNewRequester] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [isNewRequester, setIsNewRequester] = useState(false);
  const [selectedRequester, setSelectedRequester] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedService, setSelectedService] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { status, requester, priority, property, service } =
      editProjectModal.data;

    setSelectedStatus(status);
    setSelectedProperty({
      value: property._id,
      label: property.name,
    });
    setSelectedService(service?._id);
    setSelectedPriority(priority);
    if (requester) {
      setSelectedRequester({
        ...requester,
        label: requester.fullName,
      });
    }
  }, []);

  const typeFilter = projects?.typeFilter;

  const user = useSelector((state) => state.user);

  const clientId = user?.clientId;

  const projectId = projects.activeProject._id;

  const activeProjectId = projects.activeProject.projectId;

  const canSelectPriority = isChildRecurringProject(projects.activeProject);

  const activeProjectStartDate = projects.activeProject.startDate;

  const supplierSource = user?.clientConfig.supplierSource;

  const setPropertySearch = useCallback(
    (searchValue) => setSearch(searchValue),
    [setSearch],
  );
  const setServiceSearch = useCallback(
    (searchServiceValue) => setSearchService(searchServiceValue),
    [setSearchService],
  );
  const setSearchRequesters = useCallback(
    (searchRequestersValue) => setSearchRequestersState(searchRequestersValue),
    [setSearchRequestersState],
  );

  const { requesters, requestersLoading, noRequesterResults } = useRequesters(
    searchRequestersState,
  );

  const { propertyLoading, properties, noPropertyResults } = usePropertiesQuery(
    search,
    typeFilter,
  );

  const { serviceTypes, serviceLoading } = useServiceTypes(
    supplierSource,
    searchService,
  );

  const { updateProject } = useUpdateProject();

  const { createContactUser } = useCreateContactUser();

  const { setRef, getRef } = useRefHandlers();

  const { errors, setError, onChange, validate, validateField } = useValidation(
    {
      validationRules: () => ({
        priority: [{ rule: 'required', message: 'Please Select Priority' }],
        service: [{ rule: 'required', message: 'Please Select Service Type' }],
        description: [{ rule: 'required' }],
        property: [{ rule: 'required', message: 'Please Select Property' }],
        ...(isNewRequester && {
          requesterName: [{ rule: 'required' }],
          requesterPhone: [{ rule: 'phone' }, { rule: 'required' }],
          requesterEmail: [{ rule: 'email' }, { rule: 'required' }],
        }),
      }),
    },
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const requesterName = getValue('requesterName', e);
      const requesterPhone = getValue('requesterPhone', e);
      const requesterEmail = getValue('requesterEmail', e);

      const errorsValue = validate({
        priority: selectedPriority,
        property: selectedProperty.value,
        service: selectedService,
        description: getValue('description', e),

        ...(isNewRequester && {
          requesterName,
          requesterPhone,
          requesterEmail,
        }),
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const requestData = {
        propertyId: selectedProperty.value,
        serviceTypeId: selectedService,
        contactId: selectedRequester?._id || null,
        status: selectedStatus,
        description: getValue('description', e),
        invoiceDescription: getRef().getEditor().getContents(),
        location: getValue('location', e),
        priority: selectedPriority,
      };

      const finalMutation = () =>
        updateProject(projectId, requestData)
          .then(() => {
            setLoading(false);
            setError(null);
            dispatch(setEditProjectModal(null));
            toast.success(EditProjectSuccess);
          })
          .catch((error) => {
            setLoading(false);
            setError({ server: error.message });
            toast.error(GeneralError);
          });

      if (isNewRequester) {
        const requesterNameParts = requesterName.split(' ');
        return createContactUser({
          status: ACTIVE,
          firstName: requesterNameParts[0],
          lastName: requesterNameParts
            .splice(1, requesterNameParts.length)
            .join(' '),
          links: [
            {
              propertyId: R.propOr(selectedProperty, 'value', selectedProperty),
            },
          ],
          emails: [requesterEmail],
          profile: { workPhoneNumber: requesterPhone },
        })
          .then(
            ({
              data: {
                createContactUser: {
                  user: { _id },
                },
              },
            }) => {
              requestData.contactId = _id;
            },
          )
          .then(() => finalMutation())
          .catch((error) => {
            setLoading(false);
            if (checkEmailError(error.message)) {
              setError({
                requesterEmail: 'This email address is already in use',
              });
              return;
            }
            setError({ server: error.message });
            toast.error(GeneralError);
          });
      }

      return finalMutation();
    },
    [
      projectId,
      selectedPriority,
      selectedProperty,
      selectedService,
      selectedRequester,
      updateProject,
      createContactUser,
      getRef,
      dispatch,
      setError,
      validate,
      setLoading,
      isNewRequester,
      selectedStatus,
      editProjectModal,
    ],
  );

  const closeModal = useCallback(
    () => dispatch(setEditProjectModal(null)),
    [dispatch],
  );

  const toggleIsNewRequester = useCallback(() => {
    if (isNewRequester) {
      setError({
        ...errors,
        requesterName: null,
        requesterPhone: null,
        requesterEmail: null,
      });
    }
    setIsNewRequester(!isNewRequester);
  }, [isNewRequester, errors, setError, setIsNewRequester]);

  const selectPriority = useCallback(
    (priority) => {
      const value = priority?.value;
      setSelectedPriority(value);
      validateField('priority', value);
    },
    [setSelectedPriority, validateField],
  );

  const selectStatus = useCallback(
    (status) => {
      const selectedStatusValue = status?.value;
      setSelectedStatus(selectedStatusValue);
    },
    [setSelectedStatus],
  );

  const selectProperty = useCallback(
    (property) => {
      setSelectedProperty(property);
      validateField('property', property?.value);
    },
    [setSelectedProperty, validateField],
  );

  const selectService = useCallback(
    (service) => {
      const value = service?.value;
      setSelectedService(value);
      validateField('service', value);
    },
    [setSelectedService, validateField],
  );

  const selectRequester = useCallback(
    (requester) => setSelectedRequester(requester),
    [setSelectedRequester],
  );

  const onNewRequesterChange = useCallback(
    (field) => (e) => {
      const { name, value } = e.target;
      setNewRequester({ ...newRequester, [field]: value });
      validateField(name, value);
    },
    [newRequester, setNewRequester, validateField],
  );

  return (
    <EditProject
      {...props}
      canSelectPriority={canSelectPriority}
      activeProjectId={activeProjectId}
      isNewRequester={isNewRequester}
      isModalOpen={!!editProjectModal}
      onSubmit={onSubmit}
      closeModal={closeModal}
      onNewRequesterChange={onNewRequesterChange}
      onChange={onChange}
      setRef={setRef}
      toggleIsNewRequester={toggleIsNewRequester}
      selectRequester={selectRequester}
      selectPriority={selectPriority}
      selectProperty={selectProperty}
      selectService={selectService}
      selectStatus={selectStatus}
      setPropertySearch={setPropertySearch}
      setSearchRequesters={setSearchRequesters}
      selectedRequester={selectedRequester}
      selectedPriority={selectedPriority}
      selectedProperty={selectedProperty}
      selectedStatus={selectedStatus}
      selectedService={selectedService}
      serviceTypes={serviceTypes}
      newRequester={newRequester}
      properties={properties}
      requesters={requesters}
      loading={loading}
      errors={errors}
      propertyLoading={propertyLoading}
      requestersLoading={requestersLoading}
      serviceLoading={serviceLoading}
      setServiceSearch={setServiceSearch}
      noPropertyResults={noPropertyResults}
      noRequesterResults={noRequesterResults}
      setNewRequester={setNewRequester}
      clientId={clientId}
      activeProjectStartDate={activeProjectStartDate}
    />
  );
}

EditWO.propTypes = {
  projects: PropTypes.shape({
    typeFilter: PropTypes.string,
    activeProject: PropTypes.shape({
      _id: PropTypes.string,
      projectId: PropTypes.string,
      startDate: PropTypes.string,
    }),
  }),
  editProjectModal: PropTypes.shape({
    data: PropTypes.shape({
      status: PropTypes.string,
      requester: PropTypes.shape({
        email: PropTypes.string,
        fullName: PropTypes.string,
        _id: PropTypes.string,
      }),
      priority: PropTypes.string,
      property: PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.string,
        address: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      }),
      service: PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.string,
      }),
    }),
  }),
};

export default function (props) {
  const projects = useSelector((state) => state.projects);

  const editProjectModal = projects.modals.editProject;

  return editProjectModal ? (
    <EditWO
      {...props}
      projects={projects}
      editProjectModal={editProjectModal}
    />
  ) : null;
}
