import * as R from 'ramda';
import { ProjectType } from '@poly/constants';
import { formatDateMongo } from '@poly/client-utils';
import {
  ensureIsDate,
  isNilOrEmpty,
  ofArrayLegacy,
  propEqLegacy,
} from '@poly/utils';
import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
} from 'date-fns';

import { eyesWideOpenTypes } from '../../constants/stats.js';

const toDate = R.constructN(1, Date);
const formatCurry = R.curryN(2, format);
const monthYearFormat = 'MMMM, GGGG';
const yearFormat = 'GGGG';

// getMonthRange :: Date -> Object
const getMonthRange = R.compose(
  R.mergeAll,
  R.juxt([
    R.compose(R.objOf('gte'), formatDateMongo, startOfMonth),
    R.compose(R.objOf('lte'), formatDateMongo, endOfMonth),
  ]),
  ensureIsDate,
);

// getYearRange :: Date -> Object
const getYearRange = R.compose(
  R.mergeAll,
  R.juxt([
    R.compose(R.objOf('gte'), formatDateMongo, startOfYear),
    R.compose(R.objOf('lte'), formatDateMongo, endOfYear),
  ]),
  ensureIsDate,
);

// getDateQueryFilter :: (Year, Month) -> [Object]
// Year = Number
// Month = Number
const getDateQueryFilter = R.unapply(
  R.compose(
    R.ifElse(
      isNilOrEmpty,
      R.always([]),
      R.compose(ofArrayLegacy, R.assocPath(['range', 'startDate'], R.__, {})),
    ),
    R.reject(isNilOrEmpty),
    R.ifElse(
      R.prop(1),
      R.compose(getMonthRange, toDate),
      R.compose(getYearRange, toDate),
    ),
  ),
);

// getProjectsStatsQueries :: (String, Number, Number) -> String -> Object
export const getProjectsStatsQueries = (clientId, year, month) =>
  R.cond([
    [
      R.equals(eyesWideOpenTypes.FACILITIES),
      R.always({
        bool: {
          must_not: [
            { match: { type: ProjectType.PREVENTIVE_MAINTENANCE } },
            { exists: { field: 'externalSource' } },
          ],
          must: [{ match: { clientId } }, ...getDateQueryFilter(year, month)],
        },
      }),
    ],
    [
      R.equals(eyesWideOpenTypes.CLIENT),
      R.always({
        bool: {
          must_not: [{ match: { type: ProjectType.PREVENTIVE_MAINTENANCE } }],
          must: [
            { match: { clientId } },
            { exists: { field: 'externalSource' } },
            ...getDateQueryFilter(year, month),
          ],
        },
      }),
    ],
    [
      R.equals(eyesWideOpenTypes.TOTAL),
      R.always({
        bool: {
          must_not: [{ match: { type: ProjectType.PREVENTIVE_MAINTENANCE } }],
          must: [{ match: { clientId } }, ...getDateQueryFilter(year, month)],
        },
      }),
    ],
  ]);

// getDate :: (Year, Moth) -> String
// Year = Number
// Month = Number
export const getDate = R.unapply(
  R.ifElse(
    propEqLegacy('1', 'all'),
    R.compose(formatCurry(R.__, yearFormat), toDate, R.dropLast(1)),
    R.compose(formatCurry(R.__, monthYearFormat), toDate),
  ),
);
