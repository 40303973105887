import React from 'react';
import { Layout } from '@poly/site-book';

import ReportPMLayout from '../layouts/report-pm.js';
import AuthContainer from '../hooks/auth-container.js';
import ReportsPMInfo from '../containers/report-pm/info.js';
import Navigation from '../containers/navbar/navigation-container.js';
import { LayoutContent } from '../styled.js';

function ReportPM() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <ReportsPMInfo />
        </Layout.Header>
        <LayoutContent>
          <ReportPMLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default ReportPM;
