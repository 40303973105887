import { PurchaseOrderStatus } from '@poly/constants';
import types from '../types.js';
import { INITIAL_PAGINATION_STATE } from '../../constants/pagination.js';
import { APPROVED_POS } from '../../constants/routes.js';

const defaultState = {
  ...INITIAL_PAGINATION_STATE,
  type: PurchaseOrderStatus.APPROVED,
  tableSearchString: '',
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case APPROVED_POS: {
      return {
        ...state,
        tableSearchString: '',
      };
    }
    case types.SET_APPROVED_POS_TABLE_SEARCH_STRING: {
      return {
        ...state,
        tableSearchString: action.payload,
      };
    }
    case types.SET_APPROVED_POS_PAGINATION_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case types.SET_APPROVED_POS_PAGINATION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case types.SET_APPROVED_POS_PAGINATION_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.payload,
      };
    }
    case types.SET_APPROVED_POS_PAGINATION_DROPDOWN_LABEL: {
      return {
        ...state,
        paginationDropdownLabel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
