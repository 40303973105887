import * as R from 'ramda';

import { createAccessItem, hasUserAccessWithAi } from '@poly/security';

/**
 * checkPermissionByOneAccessItem :: String -> User -> Boolean
 */
export const checkPermissionByOneAccessItem = R.curry((name, user, types) =>
  hasUserAccessWithAi(createAccessItem(name, types), user),
);

// hasUserTechnicianGroup :: User -> Bool
export const hasUserTechnicianGroup = R.compose(
  R.any(
    R.compose(R.startsWith('Technician'), R.pathOr('', ['userGroup', 'name'])),
  ),
  R.propOr([], 'userGroups'),
);
