import { useMutation } from '@apollo/client';
import { updatesSubscription } from '@poly/site-ui';
import { addUpdate } from '../queries/index.js';
import { useReactiveUpdates } from './useReactiveUpdates.js';
import {
  getProjectUpdatesQuery,
  getRecurringProjectUpdates,
} from '../queries/projects/get-updates.js';

export const useProjectUpdatesQuery = ({
  queryEndpointName,
  additionalVars,
  from,
  size,
}) => {
  const { result, loading } = useReactiveUpdates({
    gqlQuery: getProjectUpdatesQuery,
    gqlSubscription: updatesSubscription,
    queryEndpointName,
    additionalVars,
    from,
    size,
    alias: 'useProjectUpdatesQuery',
  });

  return {
    result,
    loading,
  };
};

export const useRecurringProjectUpdatesQuery = ({
  queryEndpointName,
  additionalVars,
  from,
  size,
}) => {
  const { result, loading } = useReactiveUpdates({
    gqlQuery: getRecurringProjectUpdates,
    gqlSubscription: updatesSubscription,
    queryEndpointName,
    additionalVars,
    from,
    size,
    alias: 'useRecurringProjectUpdatesQuery',
  });

  return {
    result,
    loading,
  };
};

export const useUpdateMutation = ({ refetchQueries }) => {
  const [mutate] = useMutation(addUpdate, {
    alias: 'useUpdateMutation',
    refetchQueries,
  });

  return {
    sendUpdate: ({ attachments, collection, documentId, message }) =>
      mutate({
        variables: {
          input: {
            filesAttachments: attachments,
            collection,
            documentId,
            message,
          },
        },
      }),
  };
};
