import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIsTokenValid } from '@poly/client-utils';
import PropTypes from 'prop-types';
import { routeLogin } from '../redux/actions/index.js';

function Authorization({ children }) {
  const dispatch = useDispatch();

  const { isTokenValid } = useIsTokenValid();

  useEffect(() => {
    if (!isTokenValid) {
      dispatch(routeLogin());
    }
  }, [isTokenValid]);

  return children;
}

Authorization.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Authorization;
