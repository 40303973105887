import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Widget } from '@poly/site-book';

import { Table } from '../../table/table.js';
import { commonTablePropTypes } from '../../common-prop-types.js';

const WidgetBlock = styled(Widget.Item)`
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: min-content;
  grid-template-rows: auto 1fr;
`;

function MRProjects({ projects, ...props }) {
  return (
    <WidgetBlock>
      <Table
        gridColumns="
        minmax(75px, 150px)
        minmax(150px, 1fr)
        minmax(75px, 150px)
        minmax(80px, 160px)
      "
        {...projects}
        {...props}
      />
    </WidgetBlock>
  );
}

MRProjects.propTypes = {
  projects: PropTypes.shape(commonTablePropTypes).isRequired,
};

export default MRProjects;
