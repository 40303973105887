import * as R from 'ramda';
import { gql } from '@apollo/client';
import { projectAssetsQuery } from '@poly/site-ui';
import { useEntityMultipleSubscription } from '@poly/client-utils/src/hooks/useEntityMultipleSubscriptions.js';
import { useReactiveQuery } from '@poly/client-utils/src/hooks/useReactiveQuery.js';

import { getRecurringProjectAssets } from '../queries/index.js';

const projectChangedSubscription = gql`
  subscription projectChangedSubscription($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
    }
  }
`;

const recurringProjectChangedSubscription = gql`
  subscription recurringProjectChangedSubscription($input: SingleDocSubInput!) {
    recurringProjectChanged(input: $input) {
      id
      type
    }
  }
`;

export const useProjectAssets = (projectId) => {
  const subscriptionOptions = {
    variables: {
      input: {
        projectId,
      },
    },
    skip: !projectId,
  };

  const { data, loading } = useReactiveQuery(
    projectAssetsQuery,
    projectChangedSubscription,
    {
      queryOptions: {
        variables: { id: projectId },
        fetchPolicy: 'network-only',
        skip: !projectId,
      },
      subscriptionOptions,
    },
  );

  return {
    data,
    loading,
    project: data?.project,
  };
};

// getRecurringProjectSubOptions :: Boolean -> MasterRecurringProjectResult -> SubscriptionOptions
const getRecurringProjectSubOptions = R.applySpec({
  skip: R.compose(R.isNil, R.path(['recurringProject', '_id'])),
  variables: R.compose(
    R.objOf('input'),
    R.objOf('id'),
    R.path(['recurringProject', '_id']),
  ),
});

export const useRecurringProjectAssets = (projectId) => {
  const { data, loading } = useEntityMultipleSubscription(
    getRecurringProjectAssets,
    [recurringProjectChangedSubscription],
    {
      queryOptions: {
        variables: { id: projectId },
        fetchPolicy: 'network-only',
      },
      getSubscriptionOptions: [getRecurringProjectSubOptions],
    },
  );

  return {
    data,
    loading,
    project: data?.recurringProject,
  };
};
