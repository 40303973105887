import React from 'react';
import PropTypes from 'prop-types';
import { Header, HeadingH1, HeadingH4 } from '@poly/site-book';
import styled from 'styled-components';

import TableSearchContainer from '../../containers/projects/filter/search.js';
import { UpRow } from '../../styled.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { exportWorkOrdersDirectoryToXLS } from '../projects/export-to-xls.js';

const UpRowS = styled(UpRow)`
  justify-content: flex-end;
  padding-bottom: 10px;
`;

function ProjectsStatsFilter({ date, title }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>{title}</HeadingH1>
        </Header.Block>

        <Header.Block>
          <HeadingH4 lighter>{date}</HeadingH4>
        </Header.Block>

        <Header.Block>
          <PrintAndExportButtons
            printTitle="Work Orders"
            exportFunction={exportWorkOrdersDirectoryToXLS}
          />
        </Header.Block>
      </Header.Row>
      <UpRowS>
        <div>
          <TableSearchContainer />
        </div>
      </UpRowS>
    </Header.Sub>
  );
}

ProjectsStatsFilter.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProjectsStatsFilter;
