import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Grid, S } from '@poly/site-book';
import styled, { css } from 'styled-components';
import { ValidationMessage } from './ValidationMessage.js';

export const InputTitle = styled(S)`
  ${({ description }) => description && 'margin-top: 20px'};
  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: #ee0d45;
      }
    `};
`;

export function FormRows({ items }) {
  return items
    .filter(R.complement(R.prop('hide')))
    .map(({ title, item, error = null, horizontal = 'stretch' }) => {
      if (!title) {
        return item;
      }
      const {
        props: [required, description] = [],
        value = '\u00a0',
        style,
        key,
      } = title;
      return (
        <Grid.Row key={key || value} style={style}>
          <Grid.Cell vertical={description ? 'start' : 'center'}>
            <InputTitle type="title" {...{ required, description }}>
              {value}
            </InputTitle>
          </Grid.Cell>
          <Grid.Cell vertical="center" {...{ horizontal }}>
            {item}
          </Grid.Cell>
          <ValidationMessage {...{ error }} />
        </Grid.Row>
      );
    });
}

FormRows.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      hide: PropTypes.bool,
      title: PropTypes.shape({
        value: PropTypes.string,
        props: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        ),
      }),
      item: PropTypes.node.isRequired,
      horizontal: PropTypes.string,
      error: PropTypes.string,
    }),
  ).isRequired,
};
