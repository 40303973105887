import * as R from 'ramda';
import { NOTHING_UI_STRING } from '@poly/constants';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from '@poly/client-utils';

// getAssetCellByPath :: String -> Asset -> ExcelExportDataCell
const getAssetCellByPath = (path) =>
  R.applySpec({
    value: R.pathOr(NOTHING_UI_STRING, path),
    cellType: R.always(ExcelExportCellType.default),
  });

// getAssetDirectoryRow :: Asset -> [ExcelExportDataCell]
const getAssetDirectoryRow = R.juxt([
  getAssetCellByPath(['equipmentType']),
  getAssetCellByPath(['property', 'name']),
  getAssetCellByPath(['description']),
  getAssetCellByPath(['serial']),
  getAssetCellByPath(['warrantyEnd']),
]);

const getExcelExportConfig = (reportData) => ({
  exportFileName: 'asset_directory_export.xlsx',
  columnWidths: [15, 25, 25, 15, 15],
  rows: [
    [
      {
        value: 'Asset Directory',
        cellType: ExcelExportCellType.title,
      },
    ],

    R.zipWith(
      createExcelExportCell,
      R.repeat(ExcelExportCellType.tableHeader, 5),
      [
        'Equipment type',
        'Property',
        'Description',
        'Serial Number',
        'Warranty EXP',
      ],
    ),

    ...reportData.map(getAssetDirectoryRow),
  ],
});

/**
 * exportAssetDirectoryToXLS :: AssetsSearchResult -> Promise Error _
 */
export const exportAssetDirectoryToXLS = R.compose(
  performExcelExport,
  getExcelExportConfig,
  R.defaultTo([]),
  R.path(['data', 'searchAssets', 'hits']),
);
