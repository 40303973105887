import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@poly/site-ui';
import Checklist from '../../../../components/mr-pm-project/right-block/checklist.js';
import { makeListFromString } from '../../../../utils/mr-pm-projects/index.js';
import { useMRProjectChecklist } from '../../../../hooks/mr-pm-project.js';

export default function (props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const { loading, checklist, materials, notes, searchFiles } =
    useMRProjectChecklist(projectId);

  if (loading) return <Loader />;

  const checklistValue = makeListFromString(checklist);
  const materialsValue = makeListFromString(materials);
  const filesValue = searchFiles.hits || [];

  return (
    <Checklist
      {...props}
      checklist={checklistValue}
      materials={materialsValue}
      files={filesValue}
      notes={notes || ''}
    />
  );
}
