import React from 'react';
import styled from 'styled-components';
import { Logo, LogoWrapper } from '@poly/common-ui';

const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0e285d;
`;

const Container = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.div`
  color: white;
  margin-bottom: 10px;
`;

export function IncorrectDevicePage() {
  return (
    <PageWrapper>
      <Container>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Text>You are registered as a Mobile user</Text>
        <Text>
          Please setup your password through the Poly Mobile app on your mobile
          device
        </Text>
      </Container>
    </PageWrapper>
  );
}
