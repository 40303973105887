import React from 'react';
import { Editor, Grid, S } from '@poly/site-book';
import * as R from 'ramda';
import styled from 'styled-components';

import { any, string } from 'prop-types';
import { Loader } from './Loader.js';
import { isNotNilOrEmpty } from '../utils/general.js';

const EditorS = styled(Editor)`
  .ql-editor {
    font-family: 'FFMarkWebProNarrowMedium', sans-serif;
    min-height: auto;
    max-height: none;
    padding: 0;
  }
`;

const LabelValue = R.cond([
  [R.prop('loading'), R.always(<Loader size={10} />)],
  [R.prop('withEditor'), (props) => <EditorS value={props.value} readOnly />],
  [
    R.T,
    (props) => (
      <span>
        {R.ifElse(
          R.propSatisfies(isNotNilOrEmpty, 'value'),
          R.prop('value'),
          R.always('—'),
        )(props)}
      </span>
    ),
  ],
]);

export function DetailsRow(props) {
  return (
    <Grid.Row>
      <Grid.Cell {...(!props.value && { vertical: 'center' })}>
        <S type="title" textColor="#5d688c">
          {props.title}
        </S>
      </Grid.Cell>
      <Grid.Cell>
        {props.color ? (
          <S type="title" textColor={props.color}>
            <LabelValue {...props} />
          </S>
        ) : (
          <S type="content" bold>
            <LabelValue {...props} />
          </S>
        )}
      </Grid.Cell>
    </Grid.Row>
  );
}

DetailsRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
  title: string,
  color: string,
};
