import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Loader } from '@poly/site-ui';
import { ofArrayLegacy } from '@poly/utils';

import { SUPPLIERS_EMAILS } from '../../../constants/suppliers.js';
import ProjectPmInfo from '../../../components/mr-pm-project/left-block/index.js';
import { schedulerFormatter } from '../../../util/project-scheduler-utils.js';
import { useMRProjectInfoQuery } from '../../../hooks/mr-pm-project.js';
import { formatDateWithDashes } from '../../../util/general.js';
import {
  companyPhoneFormatter,
  getEmailByType,
} from '../../../utils/suppliers/index.js';

// preparePMProjectTechnicians :: MaintenancePlan -> [User]
const preparePMProjectTechnicians = R.compose(
  R.ifElse(R.isNil, R.always([]), ofArrayLegacy),
  R.prop('technician'),
);

export default function (props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const { loading, info } = useMRProjectInfoQuery(projectId);

  if (loading) return <Loader />;

  const project = {
    status: info.status,
    description: info.description,
    client: info.client.name,
    startDate: formatDateWithDashes(info.startDate),
    endDate: formatDateWithDashes(info.endDate),
    repeat: schedulerFormatter(info.schedule),
    manager: info.manager,
    suppliers: R.map(
      (supplier) => ({
        _id: supplier._id,
        name: supplier.company.name,
        email: getEmailByType(
          supplier.company.emails,
          SUPPLIERS_EMAILS.SERVICE,
        ),
        phone: companyPhoneFormatter(supplier.company.phones || []),
      }),
      info.suppliers,
    ),
    technicians: preparePMProjectTechnicians(info),
  };

  return <ProjectPmInfo {...props} project={project} />;
}
