import React from 'react';
import * as R from 'ramda';
import { Input, S, L } from '@poly/site-book';
import { gql, useQuery } from '@apollo/client';
import { ButtonLoader } from '@poly/site-ui';
import styled from 'styled-components';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import {
  getUserCellPhoneNumber,
  isNilOrEmpty,
  validateEmail,
} from '@poly/utils';

const FIND_USER_CONTACT_BY_EMAIL = gql`
  query FIND_USER_CONTACT_BY_EMAIL($email: Email!) {
    findUserContactByEmail(email: $email) {
      _id
      fullName
      email
      profile {
        cellPhoneNumber
      }
    }
  }
`;
const LinkS = S.withComponent(L);

const AddBtnWrapperS = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5px;
`;

const LoaderWrapper = styled.div`
  position: relative;
  margin-left: 10px;
`;

export function DuplicateContactMsgBtn({ userContact, loading, onClick }) {
  if (isNilOrEmpty(userContact)) {
    return null;
  }

  return (
    <AddBtnWrapperS>
      <S textColor="#ee0d45">A contact with this address already exists.</S>
      {loading ? (
        <LoaderWrapper>
          <ButtonLoader size={15} />
        </LoaderWrapper>
      ) : (
        <LinkS onClick={onClick}>Click here to add them</LinkS>
      )}
    </AddBtnWrapperS>
  );
}

DuplicateContactMsgBtn.propTypes = {
  loading: bool.isRequired,
  onClick: func.isRequired,
  userContact: shape({
    _id: string.isRequired,
    fullName: string,
    email: string,
    phone: string,
    links: arrayOf(
      shape({
        clientId: string,
        propertyId: string,
        supplierId: string,
      }),
    ),
  }),
};

// getUserContact :: QueryData -> UserContact
const getUserContact = R.compose(
  R.when(
    R.identity,
    R.compose(
      R.mergeAll,
      R.juxt([
        R.compose(R.objOf('phone'), getUserCellPhoneNumber),
        R.pick(['_id', 'fullName', 'email']),
      ]),
    ),
  ),
  R.prop('findUserContactByEmail'),
);

export function EmailInputWithSearchUser({ value, setFoundUser, ...props }) {
  const { data, loading } = useQuery(FIND_USER_CONTACT_BY_EMAIL, {
    variables: {
      email: value,
    },
    skip: !validateEmail(value),
  });

  const userContact = getUserContact(data);
  const addUserContact = () => setFoundUser(userContact);

  return (
    <>
      <Input value={value} {...props} />
      <DuplicateContactMsgBtn
        userContact={userContact}
        loading={loading}
        onClick={addUserContact}
      />
    </>
  );
}

EmailInputWithSearchUser.propTypes = {
  value: string.isRequired,
  setFoundUser: func.isRequired,
};
