import React from 'react';
import * as R from 'ramda';
import PropTypes, { string } from 'prop-types';

import { LoaderS, SelectS } from './styles.js';

function LoadingIndicator() {
  return (
    <LoaderS size={20}>
      <LoaderS.Circle size={20} />
    </LoaderS>
  );
}

function PolySelect({
  name,
  value,
  style,
  options,
  disabled,
  className,
  clearable,
  noResultsText,
  optionRenderer,
  ...props
}) {
  const formattedValue = R.when(
    R.allPass([
      R.anyPass([R.is(String), R.is(Number)]),
      R.complement(R.isEmpty),
    ]),
    (val) => R.find(R.propEq(val, 'value'), options),
  )(value);
  const openMenuOnClick = R.complement(R.isEmpty)(options);

  return (
    <SelectS
      {...props}
      name={name}
      aria-label={name}
      hideSelectedOptions
      isDisabled={disabled}
      value={formattedValue}
      isClearable={clearable}
      classNamePrefix="Select"
      {...{ openMenuOnClick, options }}
      components={{ LoadingIndicator }}
      formatOptionLabel={optionRenderer}
      noOptionsMessage={() => noResultsText}
      className={`${className} ${props.invalid ? 'is-invalid' : ''}`}
      styles={{ container: (base) => ({ ...base, ...style }) }}
    />
  );
}

const optionPropTypes = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.object,
  PropTypes.string,
  PropTypes.number,
]);

PolySelect.propTypes = {
  name: string,
  value: optionPropTypes,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  optionRenderer: PropTypes.func,
  noResultsText: PropTypes.string,
  options: PropTypes.arrayOf(optionPropTypes),
};

PolySelect.defaultProps = {
  style: {},
  value: null,
  options: [],
  className: '',
  invalid: false,
  disabled: false,
  clearable: true,
  noResultsText: '',
  name: 'Poly-Select',
  optionRenderer: ({ label }) => label,
};

PolySelect.displayName = 'Select';

export default PolySelect;
