import * as R from 'ramda';
import { useMutation, useQuery } from '@apollo/client';
import { propEqLegacy } from '@poly/utils';

import {
  getPmChecklistQuery,
  removeMaterials,
  jobCostsQuery,
  removeTimelogs,
  updateTimelogs,
  addTimelogs,
  updateMaterials,
  addMaterials,
} from '../queries/index.js';

// removeTimeLog :: (ID, Project) -> Project
const removeTimeLog = (timeLogId, project) =>
  R.over(
    R.lensPath(['timeLogs']),
    R.reject(propEqLegacy('_id', timeLogId)),
    project,
  );

// removeMaterial :: (ID, Project) -> Project
const removeMaterial = (timeLogId, project) =>
  R.over(
    R.lensPath(['materialSpends']),
    R.reject(propEqLegacy('_id', timeLogId)),
    project,
  );

export const usePmProjectChecklist = (projectId) => {
  const { data, loading } = useQuery(getPmChecklistQuery, {
    alias: 'usePmProjectChecklist',
    variables: { id: projectId },
    notifyOnNetworkStatusChange: true,
  });

  const restPmProjectChecklistProps = data?.project
    ? data?.project.maintenancePlan
    : {};

  return {
    project: data?.project,
    loading,
    restPmProjectChecklistProps,
  };
};

export const useRemoveMaterials = () => {
  const [mutate] = useMutation(removeMaterials, {
    alias: 'useRemoveMaterials',
    refetchQueries: ['projectJobCosts'],
  });

  return {
    removeMaterial: (id, projectId) =>
      mutate({
        variables: { id },
        update: (proxy) => {
          const { project } = proxy.readQuery({
            query: jobCostsQuery,
            variables: { id: projectId },
          });
          proxy.writeQuery({
            query: jobCostsQuery,
            data: { project: removeMaterial(id, project) },
          });
        },
      }),
  };
};

export const useRemoveTimelogs = () => {
  const [mutate] = useMutation(removeTimelogs, {
    alias: 'useRemoveTimelogs',
    refetchQueries: ['projectJobCosts'],
  });

  return {
    removeTimelogs: (id, projectId) =>
      mutate({
        variables: { id },
        update: (proxy) => {
          const { project } = proxy.readQuery({
            query: jobCostsQuery,
            variables: { id: projectId },
          });
          proxy.writeQuery({
            query: jobCostsQuery,
            data: { project: removeTimeLog(id, project) },
          });
        },
      }),
  };
};

export const useProjectJobCosts = (projectId) => {
  const { data } = useQuery(jobCostsQuery, {
    alias: 'useProjectJobCosts',
    variables: { id: projectId },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  return {
    project: data?.project,
  };
};

export const useEditTimelogs = () => {
  const [mutate] = useMutation(updateTimelogs, {
    alias: 'useEditTimelogs',
    refetchQueries: ['projectJobCosts'],
  });

  return {
    updateTimelog: (id, update) =>
      mutate({
        variables: {
          id,
          update,
        },
      }),
  };
};

export const useAddTimeLogs = () => {
  const [mutate] = useMutation(addTimelogs, {
    alias: 'useAddTimeLogs',
    refetchQueries: ['projectJobCosts'],
  });

  return {
    createTimelog: (value) =>
      mutate({
        variables: { ...value },
      }),
  };
};

export const useEditMaterials = () => {
  const [mutate] = useMutation(updateMaterials, {
    alias: 'useEditMaterials',
    refetchQueries: ['projectJobCosts'],
  });

  return {
    updateMaterial: (id, update) =>
      mutate({
        variables: {
          id,
          update,
        },
      }),
  };
};

export const useAddMaterials = () => {
  const [mutate] = useMutation(addMaterials, {
    alias: 'useAddMaterials',
    refetchQueries: ['projectJobCosts'],
  });

  return {
    createMaterial: (data) =>
      mutate({
        variables: { ...data },
      }),
  };
};
