import * as R from 'ramda';
import styled from 'styled-components';
import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Conversation, Icon } from '@poly/site-book';

import {
  SectionWrapper,
  ContentWrapper,
  AdditionalReplyInfo,
  RepliesWrapper,
  ToggleRepliesButton,
  ToggleRepliesWrapper,
} from './components.js';
import { getLastUpdateCommentInfo, getUpdateAttachments } from './utils.js';
import { UpdateItemComment } from './UpdatesItemComment.js';

const SectionWrapperS = styled(SectionWrapper)`
  padding: 0 !important;
`;

const ContentWrapperS = styled(ContentWrapper)`
  flex-direction: column;
`;

const Container = styled(Conversation)`
  width: 100%;
  height: 100%;
  padding: 20px 0 0 20px;
`;

function ToggleButton({ isOpen, setIsOpen, updateComments }) {
  const { count, user, time } = getLastUpdateCommentInfo(updateComments);

  const pluralEnding = R.equals(count, 1) ? 'y' : 'ies';
  const buttonText = isOpen
    ? 'Collapse replies'
    : `${count} repl${pluralEnding}`;

  const toggleReplies = () => setIsOpen(!isOpen);

  return (
    <ToggleRepliesWrapper data-testid="update-comments-toggle" {...{ isOpen }}>
      <ToggleRepliesButton
        data-testid="update-comments-toggle-button"
        onClick={toggleReplies}
      >
        <Icon
          name={isOpen ? 'arrow-down-2' : 'arrow-forward-2'}
          dimensions={{ width: 12 }}
        />
        <div>{buttonText}</div>
      </ToggleRepliesButton>
      {!isOpen && (
        <AdditionalReplyInfo>
          Last reply by <span>{user}</span> {time} ago
        </AdditionalReplyInfo>
      )}
    </ToggleRepliesWrapper>
  );
}

const updateCommentsPropTypes = arrayOf(shape({ _id: string.isRequired }));

ToggleButton.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
  updateComments: updateCommentsPropTypes,
};

export function UpdateCommentsSection({ updateComments }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SectionWrapperS data-testid="update-comments-section">
      <ContentWrapperS>
        <ToggleButton {...{ isOpen, setIsOpen, updateComments }} />
        {isOpen && (
          <RepliesWrapper data-testid="update-comments">
            <Container>
              {updateComments.map((updateComment) => (
                <UpdateItemComment
                  key={updateComment._id}
                  message={updateComment.message}
                  createdAt={updateComment.createdAt}
                  user={{
                    ...updateComment.createdBy,
                    name: updateComment?.createdBy?.fullName,
                  }}
                  attachments={getUpdateAttachments(updateComment)}
                />
              ))}
            </Container>
          </RepliesWrapper>
        )}
      </ContentWrapperS>
    </SectionWrapperS>
  );
}

UpdateCommentsSection.propTypes = {
  updateComments: updateCommentsPropTypes,
};
