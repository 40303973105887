import { TableSearchComponent } from '@poly/site-ui';
import React from 'react';
import {
  setReportPmTableSearchString,
  setReportPmPaginationCurrentPage,
} from '../../redux/actions/index.js';

export default function (props) {
  return (
    <TableSearchComponent
      {...props}
      setTableSearchString={setReportPmTableSearchString}
      setPaginationCurrentPage={setReportPmPaginationCurrentPage}
    />
  );
}
