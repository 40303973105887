import React from 'react';
import { useSelector } from 'react-redux';
import { collectionNames } from '@poly/constants';
import { Loader, usePaginatorState } from '@poly/site-ui';
import ProjectUpdatesComponent from '../../../components/project/project-updates.js';
import useFilesUpdates from '../../../hooks/useFiles.js';
import {
  useProjectUpdatesQuery,
  useRecurringProjectUpdatesQuery,
} from '../../../hooks/project-updates.js';

export default function ProjectsUpdate(props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const { paginator } = usePaginatorState();
  const { size, currentPage } = paginator;

  const from = (currentPage - 1) * size;

  const { result } = useProjectUpdatesQuery({
    queryEndpointName: 'project',
    additionalVars: { projectId },
    from,
    size,
  });

  const documentId = result.project?._id;
  const collection = collectionNames.projects;

  const additionalFilesProps = useFilesUpdates({
    documentId,
    collection,
  });

  if (result.loading || !result.project) return <Loader />;

  const updates = result?.project.documentUpdates.hits || [];
  const total = result?.project.documentUpdates.total;

  return (
    <ProjectUpdatesComponent
      {...props}
      {...result}
      {...additionalFilesProps}
      updates={updates}
      total={total}
    />
  );
}

export function RecurringProjectsUpdates(props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const { paginator } = usePaginatorState();
  const { size, currentPage } = paginator;

  const from = (currentPage - 1) * size;

  const { result } = useRecurringProjectUpdatesQuery({
    queryEndpointName: 'recurringProject',
    additionalVars: { projectId },
    from,
    size,
  });

  const documentId = result.recurringProject?._id;
  const collection = collectionNames.recurringProjects;

  const additionalFilesProps = useFilesUpdates({
    documentId,
    collection,
  });

  if (result.loading || !result.recurringProject) return <Loader />;

  const updates = result?.recurringProject.documentUpdates.hits || [];
  const total = result?.recurringProject.documentUpdates.total;

  return (
    <ProjectUpdatesComponent
      {...props}
      {...result}
      {...additionalFilesProps}
      updates={updates}
      total={total}
    />
  );
}
