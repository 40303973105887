import * as R from 'ramda';
import React from 'react';
import { S } from '@poly/site-book';
import {
  bool,
  arrayOf,
  func,
  shape,
  string,
  oneOfType,
  object,
} from 'prop-types';
import { SelectBase } from './SelectBase.js';

function SimpleTitle({ label }) {
  return <S type="title">{label}</S>;
}

SimpleTitle.propTypes = {
  label: string,
};

// formatPropertiesOptions :: [Property] -> [Option]
// Option = { value: String, label: String }
const formatPropertiesOptions = R.map(
  R.applySpec({
    value: R.prop('_id'),
    label: R.prop('name'),
  }),
);

export function PropertySelectorBase({
  value,
  onChange,
  onFocus,
  onBlur,
  properties,
  loading,
  setPropertySearch,
  invalid,
  noResultsText,
}) {
  return (
    <SelectBase
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onInputChange={setPropertySearch}
      isLoading={loading}
      onSelectResetsInput={false}
      placeholder="Start Typing Property"
      options={formatPropertiesOptions(properties)}
      noResultsText={noResultsText}
      optionRenderer={SimpleTitle}
      valueRenderer={SimpleTitle}
      invalid={invalid}
    />
  );
}

PropertySelectorBase.propTypes = {
  properties: arrayOf(
    shape({ _id: string.isRequired, name: string.isRequired }),
  ),
  loading: bool,
  setPropertySearch: func.isRequired,
  onChange: func.isRequired,
  onBlur: func,
  onFocus: func,
  value: oneOfType([string, shape({ value: string, label: string })]),
  invalid: bool,
  noResultsText: oneOfType([string, object]),
};
