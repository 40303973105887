import React from 'react';
import styled from 'styled-components';
import { node, string } from 'prop-types';
import { printVisibleTable as printVisibleTableBase } from '@poly/site-ui';

import AppProviders from '../util/app-providers.js';

const TitleStatus = styled.div`
  font-size: 18px;
  padding: 10px 20px;
`;

export function PrintLayout({ title, status, children }) {
  return (
    <AppProviders>
      <div>
        <h2 style={{ padding: '10px 20px' }}>{title}</h2>
        {status && <TitleStatus text={status.text} color={status.color} />}
        {children}
      </div>
    </AppProviders>
  );
}

PrintLayout.propTypes = {
  title: string.isRequired,
  status: string,
  children: node.isRequired,
};

/**
 * printVisibleTable :: Params -> Object -> Promise _ _
 *
 * Params = {
 *   title: String
 * }
 */
export const printVisibleTable = ({ title }) =>
  printVisibleTableBase({ title, PrintLayout });
