import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { S } from '@poly/site-book';

import { generateAssignedTeam } from '../projects/assignedToUtils.js';
import { linkFormatter } from '../../util/project-utils.js';

const AssignedToWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & a:not(:last-child) {
    padding-bottom: 5px;
  }
`;

function AssignedToComponent({ assignedTeam, search }) {
  return (
    <AssignedToWrapper>
      {R.isEmpty(assignedTeam) ? (
        <S type="content" textColor="#90939d">
          Queue
        </S>
      ) : (
        R.map(linkFormatter({ bold: false, search }), assignedTeam)
      )}
    </AssignedToWrapper>
  );
}

AssignedToComponent.propTypes = {
  search: PropTypes.string,
  assignedTeam: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// assignedToFormatter :: String -> Project -> ReactNode
export const assignedToFormatter = (search) =>
  function (project) {
    return (
      <AssignedToComponent
        search={search}
        assignedTeam={generateAssignedTeam(project)}
      />
    );
  };
