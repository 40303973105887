import * as R from 'ramda';
import { toast } from 'react-toastify';
import { getErrorMessagesByApolloError } from '@poly/apollo-client';
import { is2FASetupError } from '@poly/client-utils';
import {
  CLIENT_MARKUP_RULES_ERROR,
  SETUP_2FA_REQUIRED_ERROR_MESSAGE,
} from '@poly/constants';

// isClientMarkupRulesError :: [String] -> Boolean
const isClientMarkupRulesError = R.includes(CLIENT_MARKUP_RULES_ERROR);

export const createHandleApolloErrorAtSiteUI =
  (logOutHandler, ClientMarkupRulesError) => (error) => {
    const errorsArr = getErrorMessagesByApolloError(error);

    if (is2FASetupError(errorsArr)) {
      logOutHandler();
      return toast.error(SETUP_2FA_REQUIRED_ERROR_MESSAGE);
    }

    if (isClientMarkupRulesError(errorsArr) && ClientMarkupRulesError) {
      return toast.error(ClientMarkupRulesError);
    }

    return errorsArr.forEach(toast.error);
  };
