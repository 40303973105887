import React from 'react';
import { Widget } from '@poly/site-book';
import { Table } from '@poly/site-ui';

function TableWithWidget(props) {
  return (
    <Widget.Item>
      <Table {...props} />
    </Widget.Item>
  );
}

export default TableWithWidget;
