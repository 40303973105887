import React from 'react';
import TransitionGroup from 'react-transition-group/cjs/TransitionGroup.js';
import { Sidebar } from '@poly/site-book';
import { node } from 'prop-types';
import { useSelector } from 'react-redux';
import * as R from 'ramda';

export function GlobalSearchResult({ children }) {
  const isSearchFilled = useSelector(
    R.path(['globalSearch', 'isSearchFilled']),
  );

  return (
    <TransitionGroup>
      {isSearchFilled && (
        <Sidebar
          style={{
            top: 60,
            width: 700,
            maxWidth: 'none',
            padding: 0,
            height: 'calc(100% - 60px)',
          }}
        >
          {children}
        </Sidebar>
      )}
    </TransitionGroup>
  );
}

GlobalSearchResult.propTypes = {
  children: node.isRequired,
};
