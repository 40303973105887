import * as R from 'ramda';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { SITE_APP_NAME, MOBILE_APP_NAME } from '@poly/security';

const USER_GROUP_LIST_QUERY = gql`
  query USER_GROUP_LIST_QUERY($input: ListUserGroupsInput) {
    listUserGroups(input: $input) {
      _id
      name
      defaultLoginApp
    }
  }
`;

// prepareSiteUserGroups :: { listUserGroups: [UserGroup] } -> [UserGroup]
const prepareSiteUserGroups = R.compose(
  R.filter(
    R.propSatisfies(
      R.includes(R.__, [SITE_APP_NAME, MOBILE_APP_NAME]),
      'defaultLoginApp',
    ),
  ),
  R.propOr([], 'listUserGroups'),
);

export const useUserGroupListQuery = () => {
  const { data, loading } = useQuery(USER_GROUP_LIST_QUERY, { variables: {} });

  const userGroups = useMemo(() => prepareSiteUserGroups(data), [data]);

  return { userGroups, loading };
};
