import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';
import { L } from '@poly/site-book';
import { highlightTextToReactElement } from '@poly/client-utils';

import { ASSET } from '../../constants/routes.js';
import { isExist } from '../general.js';

const A = L.withComponent('span');

export function assetLinkFormatter({ _id: id, serial }, search) {
  return (
    <Link to={{ type: ASSET, payload: { id } }}>
      {isExist(serial) ? (
        <A bold>
          {isExist(search)
            ? highlightTextToReactElement(search, serial)
            : serial}
        </A>
      ) : (
        <A bold>—</A>
      )}
    </Link>
  );
}

assetLinkFormatter.propTypes = {
  _id: PropTypes.string.isRequired,
  serial: PropTypes.string,
};

assetLinkFormatter.defaultProps = {
  serial: null,
};
