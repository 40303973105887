import React from 'react';
import PropTypes from 'prop-types';
import {
  Header,
  HeadingH1,
  Holder,
  Icon,
  IconButton,
  S,
} from '@poly/site-book';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { CREATE_SUPPLIER_PERMISSION } from '@poly/security';
import { FilterByStatus } from '@poly/site-ui';

import { UpRow } from '../../styled.js';
import { SUPPLIERS_FILTERS } from '../../constants/suppliers.js';
import TableSearchContainer from '../../containers/suppliers/search.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { useSuppliersFilters } from '../../utils/suppliers/suppliers-filter.js';
import { exportSuppliersDirectoryToXLS } from './export-to-xls.js';
import { useSuppliersCountQuery } from '../../hooks/suppliers.js';

function FilterWrapper(props) {
  const { sort, query } = useSuppliersFilters(props);

  const { count } = useSuppliersCountQuery({ sort, query });

  return <FilterByStatus {...props} count={count} />;
}

FilterWrapper.propTypes = {
  typeFilter: PropTypes.string,
};

function AddSupplierButton({ openSupplierModal }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_SUPPLIER_PERMISSION,
  );

  return (
    ifHasPermission && (
      <IconButton fill="#ff8d02" onClick={openSupplierModal} hover>
        <S type="title">Add Supplier</S>
        <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
      </IconButton>
    )
  );
}
AddSupplierButton.propTypes = {
  openSupplierModal: PropTypes.func.isRequired,
};

function SuppliersFilter({ activeFilter, onClick, openSupplierModal }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>Supplier Directory</HeadingH1>
        </Header.Block>
        <Header.Block>
          <AddSupplierButton {...{ openSupplierModal }} />
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={20} center>
          <S type="badge" uppercase>
            Filter by:
          </S>
          <Holder margin={10} center>
            {SUPPLIERS_FILTERS.map(({ type, ...props }) => (
              <FilterWrapper
                active={activeFilter === type}
                key={type}
                typeFilter={type}
                {...{ onClick }}
                {...props}
              />
            ))}
          </Holder>
        </Holder>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Supplier Directory"
            exportFunction={exportSuppliersDirectoryToXLS}
            marginRight="20px"
          />
          <Holder margin={20}>
            <TableSearchContainer />
          </Holder>
        </Header.Block>
      </UpRow>
    </Header.Sub>
  );
}

SuppliersFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
  openSupplierModal: PropTypes.func.isRequired,
};

export default SuppliersFilter;
