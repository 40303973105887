import { ProjectType } from '@poly/constants';
import { SITE_PROJECT_STATUS_TEXT_MAP } from '@poly/site-ui';

const PROJECT_UI_STATUSES = {
  DUE_TODAY: 'due-today',
  DUE_TOMORROW: 'due-tomorrow',
  PAST_DUE: 'past-due',
  QUEUE: 'queue',
  ACTIVE: 'active',
  ON_HOLD: 'ON_HOLD',
  COMPLETED: 'completed',
};

const { DUE_TODAY, DUE_TOMORROW, PAST_DUE, QUEUE, ACTIVE, ON_HOLD, COMPLETED } =
  PROJECT_UI_STATUSES;

const { WORK_ORDER, PREVENTIVE_MAINTENANCE } = ProjectType;

export {
  DUE_TODAY,
  DUE_TOMORROW,
  PAST_DUE,
  QUEUE,
  ACTIVE,
  ON_HOLD,
  COMPLETED,
  PREVENTIVE_MAINTENANCE,
  WORK_ORDER,
};

// Project filter above the table
export const PROJECT_FILTERS = [
  {
    title: 'Due Today',
    type: DUE_TODAY,
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'Due Tomorrow',
    type: DUE_TOMORROW,
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'Past Due',
    type: PAST_DUE,
    color: '#ee0d45',
    showPip: true,
  },
  {
    title: 'On Hold',
    type: ON_HOLD,
    color: '#ffbc28',
    showPip: true,
  },
  {
    title: 'Active',
    type: ACTIVE,
    color: '#9bdc53',
    showPip: false,
  },
  {
    title: 'Queue',
    type: QUEUE,
    color: '#9bdc53',
    showPip: false,
  },
];

export const ProjectStatusesArray = Object.keys(SITE_PROJECT_STATUS_TEXT_MAP);
