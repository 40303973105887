import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { AbsoluteLoader } from '@poly/site-ui';
import { useSortableTable } from '../../hooks/table-enhancers.js';
import Staff from '../../components/staff/index.js';
import { getUserTypeName, statusFormatter } from '../../util/staff.js';
import { UserStaffLink } from '../../utils/staff/UserStaffLink.js';
import { emailLinkFormatter } from '../../util/project-utils.js';
import { highlightField } from '../../utils/highlight/index.js';
import { noDataToDisplay } from '../../util/general.js';
import { getUserCellPhone } from '../../utils/staff/index.js';

import {
  setStaffPaginationDropdownLabel,
  setStaffPaginationCurrentPage,
  setStaffPaginationPageSize,
  setStaffPaginationTotal,
} from '../../redux/actions/index.js';
import { useStaff } from '../../hooks/staff.js';
import usePagination from '../../hooks/usePagination.js';

const columns = (search) => [
  [
    'User',
    R.identity,
    (user) => <UserStaffLink user={user} searchText={search} />,
  ],
  ['Type', getUserTypeName, highlightField(search)],
  ['Mobile', getUserCellPhone, highlightField(search), true],
  ['Email', R.prop('email'), emailLinkFormatter({ search }), true],
  [
    'Active',
    R.path(['activeProjectsCount', 'total']),
    highlightField(search),
    true,
  ],
  [
    'Overdue',
    R.path(['overdueProjectsCount', 'total']),
    highlightField(search),
    true,
  ],
  [
    'Last 30',
    R.path(['lastThirtyProjectsCount', 'total']),
    highlightField(search),
    true,
  ],
  ['Status', R.identity, statusFormatter(search), false, R.prop('status')],
];

export default function (props) {
  const staff = useSelector((state) => state.staff);
  const user = useSelector((state) => state.user);

  const {
    pageSize,
    typeFilter,
    currentPage,
    tableSearchString,
    paginationDropdownLabel,
  } = staff;

  const from = (currentPage - 1) * pageSize;

  const clientId = user?.clientId;

  const {
    users,
    total,
    data,
    loading,
    subscribeToMore,
    refetch,
    skip,
    unsubscribe,
    networkStatus,
    query,
    additionalVars,
    restProps,
  } = useStaff(typeFilter, clientId, from, pageSize, tableSearchString);

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setStaffPaginationTotal,
    setPageSize: setStaffPaginationPageSize,
    setCurrent: setStaffPaginationCurrentPage,
    setPaginationDropdownLabel: setStaffPaginationDropdownLabel,
    total,
    currentPage,
  });

  const cols = columns(tableSearchString);

  const columnsValue = R.map(R.nth(1), cols);

  const headers = R.map(R.nth(0), cols);

  const formats = R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), cols);

  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(4))(item),
    cols,
  );

  const omitSorting = R.map(R.nth(3), cols);

  const gridColumns = `
        repeat(3, 1fr)
        1.3fr
        repeat(3, 70px)
        100px
      `;

  const { rows, sorting, setSorting } = useSortableTable(users, valuesToSort);

  if ((loading && networkStatus !== 4) || !clientId) return <AbsoluteLoader />;
  if (!users.length) return noDataToDisplay('Staff')();

  return (
    <Staff
      {...props}
      {...restProps}
      total={total}
      typeFilter={typeFilter}
      currentPage={currentPage}
      size={pageSize}
      paginationDropdownLabel={paginationDropdownLabel}
      searchTerm={tableSearchString}
      from={from}
      clientId={clientId}
      cols={cols}
      rows={rows}
      setSorting={setSorting}
      columns={columnsValue}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      headers={headers}
      formats={formats}
      omitSorting={omitSorting}
      gridColumns={gridColumns}
      additionalVars={additionalVars}
      query={query}
      users={users}
      data={data}
      loading={loading}
      subscribeToMore={subscribeToMore}
      refetch={refetch}
      skip={skip}
      unsubscribe={unsubscribe}
      networkStatus={networkStatus}
      sorting={sorting}
      valuesToSort={valuesToSort}
    />
  );
}
