import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AbsoluteLoader } from '@poly/site-ui';
import { useSortableTable } from '../../hooks/table-enhancers.js';
import { useProjectsQuery } from '../../hooks/projects.js';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import { useProjectFilters } from '../../utils/projects/projects-filter.js';
import {
  setProjectsPaginationCurrentPage,
  setProjectsPaginationDropdownLabel,
  setProjectsPaginationPageSize,
  setProjectsPaginationTotal,
} from '../../redux/actions/index.js';
import usePagination from '../../hooks/usePagination.js';
import usePropsToProjectsTable from './hooks.js';
import { noDataToDisplay } from '../../util/general.js';

export default function ActiveProjects(props) {
  const dispatch = useDispatch();

  const projectsValue = useSelector((state) => state.projects);

  const locationType = useSelector((state) => state.location.type);

  const {
    currentPage,
    pageSize,
    typeFilter,
    tableSearchString,
    paginationDropdownLabel,
  } = projectsValue;

  const from = (currentPage - 1) * pageSize;

  const propsForFilter = {
    typeFilter,
    locationType,
    tableSearchString,
  };

  const { searchTerm, query, sort } = useProjectFilters(propsForFilter);

  const { projects, restProjectsProps, total, result } = useProjectsQuery({
    searchTerm,
    query,
    pageSize,
    from,
    sort,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setProjectsPaginationTotal,
    setCurrent: setProjectsPaginationCurrentPage,
    setPageSize: setProjectsPaginationPageSize,
    setPaginationDropdownLabel: setProjectsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const {
    rowsValue,
    columns,
    headers,
    valuesToSort,
    formats,
    propsObj,
    gridColumns,
    GridRowComponent,
  } = usePropsToProjectsTable({ projects, dispatch, tableSearchString });

  const { rows, sorting, setSorting } = useSortableTable(
    rowsValue,
    valuesToSort,
  );

  if (restProjectsProps.loading) return <AbsoluteLoader />;
  if (!projects.length) return noDataToDisplay('Projects')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...result}
      {...restProjectsProps}
      props={propsObj}
      sorting={sorting}
      setSorting={setSorting}
      searchTerm={searchTerm}
      tableSearchString={tableSearchString}
      rows={rows}
      columns={columns}
      headers={headers}
      formats={formats}
      valuesToSort={valuesToSort}
      gridColumns={gridColumns}
      dispatch={dispatch}
      size={pageSize}
      currentPage={currentPage}
      from={from}
      query={query}
      projects={projects}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      paginationDropdownLabel={paginationDropdownLabel}
      locationType={locationType}
      GridRowComponent={GridRowComponent}
      typeFilter={typeFilter}
    />
  );
}
