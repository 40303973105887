import React from 'react';
import styled from 'styled-components';
import { Header, HeadingH1, Holder } from '@poly/site-book';

import TableSearchContainer from '../../containers/invoices/search.js';
import { PrintAndExportButtons } from '../table/print-and-export-buttons.js';
import { exportPendingApprovalInvoicesToXLS } from './export-invoices-to-xls.js';

const Wrapper = styled(Header.Row)`
  padding: 15px 0;
`;

function InvoicesFilter() {
  return (
    <Header.Sub>
      <Wrapper>
        <Header.Block>
          <HeadingH1 lighter>Invoices Pending Approval</HeadingH1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Invoices Pending Approval"
            exportFunction={exportPendingApprovalInvoicesToXLS}
            marginRight="20px"
          />
          <Holder margin={20}>
            <TableSearchContainer />
          </Holder>
        </Header.Block>
      </Wrapper>
    </Header.Sub>
  );
}

export default InvoicesFilter;
