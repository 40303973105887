import * as R from 'ramda';
import { ClientProjectPriorityUnit } from '@poly/constants';
import { propEqLegacy } from '@poly/utils';

// normalizeProjectPrioritiesToDays :: [ProjectPriorityConfigEntry] -> [ProjectPriorityConfigEntry]
export const normalizeProjectPrioritiesToDays = R.map(
  R.when(
    propEqLegacy('unit', ClientProjectPriorityUnit.HOURS),
    R.evolve({
      unit: R.always(ClientProjectPriorityUnit.DAYS),
      amount: R.divide(R.__, 24),
    }),
  ),
);
