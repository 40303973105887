import React from 'react';
import { Widget } from '@poly/site-book';
import styled from 'styled-components';

import SupplierDetailsContainer from '../containers/supplier/details.js';
import SupplierDataContainer from '../containers/supplier/data.js';
import EditSupplierModal from '../containers/supplier/edit-modal.js';

const WidgetBlock = styled(Widget)`
  display: grid;
  grid-auto-rows: min-content;
  grid-template-rows: auto 1fr;
`;

function ProjectPageLayout() {
  return (
    <>
      <Widget>
        <SupplierDetailsContainer />
      </Widget>
      <WidgetBlock>
        <SupplierDataContainer />
        <EditSupplierModal />
      </WidgetBlock>
    </>
  );
}

export default ProjectPageLayout;
