import { MaintenancePlanStatuses } from '@poly/constants';

export const { ACTIVE, INACTIVE } = {
  ACTIVE: MaintenancePlanStatuses.ACTIVE,
  INACTIVE: MaintenancePlanStatuses.CLOSED,
};

export const STATUS_COLORS = {
  [ACTIVE]: '#9bdc53',
  [INACTIVE]: '#babfd2',
};
