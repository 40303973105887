import * as R from 'ramda';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectInfo from '../../components/pm-project/info.js';
import { useProjectPMDetails } from '../../hooks/project-details.js';
import {
  setEditProjectModal,
  setActiveProject,
  setProjectModal,
} from '../../redux/actions/index.js';

// pickProjectFieldsForEditForm :: Project -> Project
const pickProjectFieldsForEditForm = R.pick([
  '_id',
  'type',
  'status',
  'location',
  'property',
  'startDate',
  'serviceType',
  'description',
  'technicians',
  'invoiceDescription',
]);

export default function (props) {
  const dispatch = useDispatch();

  const projectId = useSelector((state) => state.location.payload.id);

  const { project } = useProjectPMDetails(projectId);

  useEffect(() => {
    if (project) {
      dispatch(setActiveProject({ _id: project._id, projectId }));
    }
  }, [project]);

  const openEditProjectModal = useCallback(() => {
    const data = pickProjectFieldsForEditForm(project);

    dispatch(setEditProjectModal({ type: 'edit', data }));
  }, [project, dispatch, setEditProjectModal]);

  const openProjectModal = useCallback(
    () =>
      dispatch(
        setProjectModal({
          type: 'add',
          data: { property: project.property },
        }),
      ),
    [project, dispatch, setProjectModal],
  );

  return (
    <ProjectInfo
      {...props}
      project={project}
      projectId={projectId}
      openProjectModal={openProjectModal}
      openEditProjectModal={openEditProjectModal}
    />
  );
}
