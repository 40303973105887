import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MAX_FILE_NAME_CHARS_LIMIT } from '@poly/constants';
import {
  InputWithLengthLimit,
  IconButton,
  HeadingH5,
  Heading,
  Button,
  Modal,
  Icon,
  Grid,
  S,
} from '@poly/site-book';

import { ErrorText } from '../Form/ValidationMessage.js';
import { ButtonLoader } from '../ButtonLoader.js';

const ButtonS = styled(Button)`
  margin-top: 15px;
  height: calc(100% - 15px);
`;

export function EditFileModalComp({
  closeModal,
  onConfirm,
  onChange,
  fileName,
  errors,
  loading,
  show,
}) {
  return (
    <Modal show={show}>
      <Modal.Item>
        <Heading>
          <HeadingH5>File Name</HeadingH5>
          <IconButton onClick={closeModal} disabled={loading}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Grid columns="minmax(100px, 320px) min-content" simple>
        <Grid.Row>
          <InputWithLengthLimit
            type="text"
            name="fileName"
            placeholder="File Name"
            invalid={!!R.prop('fileName', errors)}
            value={fileName}
            limit={MAX_FILE_NAME_CHARS_LIMIT}
            {...{ onChange }}
          />
          <ButtonS
            type="button"
            mode="orange"
            onClick={onConfirm}
            disabled={loading}
          >
            {loading && <ButtonLoader />}
            <S type="title">Confirm</S>
          </ButtonS>
        </Grid.Row>
        {R.prop('fileName', errors) && (
          <Grid.Row>
            <Grid.Cell>
              <ErrorText>{errors.fileName}</ErrorText>
            </Grid.Cell>
          </Grid.Row>
        )}
      </Grid>
    </Modal>
  );
}

EditFileModalComp.propTypes = {
  fileName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  errors: PropTypes.shape({ fileName: PropTypes.string }),
  loading: PropTypes.bool,
  show: PropTypes.bool,
};

EditFileModalComp.defaultProps = {
  errors: {},
  show: true,
};
