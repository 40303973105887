import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from '@poly/site-book';

import { setPOActiveTab } from '../../redux/actions/index.js';

const tabs = [
  { title: 'Updates', value: 'updates' },
  { title: 'Invoices', value: 'invoices' },
];

function PurchaseOrderNavigation({ activeTab, dispatch }) {
  return (
    <Tabs>
      <Tabs.Items>
        {tabs.map(({ value, title }) => (
          <Tabs.Item
            bold
            key={value}
            href={`#${value}`}
            active={value === activeTab}
            onClick={() => dispatch(setPOActiveTab(value))}
          >
            {title}
          </Tabs.Item>
        ))}
      </Tabs.Items>
    </Tabs>
  );
}

PurchaseOrderNavigation.propTypes = {
  activeTab: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default function (props) {
  const dispatch = useDispatch();

  const activeTab = useSelector((state) => state.purchaseOrders.activeTab);

  return (
    <PurchaseOrderNavigation
      {...props}
      activeTab={activeTab}
      dispatch={dispatch}
    />
  );
}
