import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { highlightTextToReactElement } from '@poly/client-utils';
import { L, Pip, S } from '@poly/site-book';

import { SUPPLIER } from '../../constants/routes.js';
import {
  PEOPLE_STATUS_COLORS,
  STATUS_COLORS,
} from '../../constants/suppliers.js';
import { isExist } from '../../util/general.js';

const A = styled(L.withComponent('span'))`
  display: inline-block;
`;

export const statusFormatter = (status, search) => (
  <Pip color={STATUS_COLORS[status.toLowerCase()]}>
    <S type="title" textColor="#2d2f33">
      {isExist(search) ? highlightTextToReactElement(search, status) : status}
    </S>
  </Pip>
);

export function supplierFormatter({ _id: id, company: { name } }, search) {
  return (
    <Link to={{ type: SUPPLIER, payload: { id } }}>
      <A bold>
        {isExist(search) ? highlightTextToReactElement(search, name) : name}
      </A>
    </Link>
  );
}

supplierFormatter.propTypes = {
  _id: PropTypes.string.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export const peopleStatusFormatter = (status) => (
  <S type="title" textColor={PEOPLE_STATUS_COLORS[status]}>
    {status}
  </S>
);
