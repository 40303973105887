import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { UPDATE_PROJECT_PERMISSION } from '@poly/security';
import { PageHeader } from '@poly/site-ui';
import {
  BreadCrumbs,
  IconButton,
  HeadingH5,
  Widget,
  Grid,
  Icon,
  S,
} from '@poly/site-book';

import {
  AddressRow,
  DetailsRow,
  UserTeamMember,
  SupplierTeamMember,
  MultipleDetailsRow,
} from '../../../utils/wrappers.js';
import EditResponseTeamContainer from '../../../containers/project/details/response-team/edit.js';
import {
  getTechnicianTypeByUser,
  mrProjectFormatter,
  propertyFormatter,
} from '../../../util/project-utils.js';
import {
  formatDateWithDashes,
  noDataToDisplay,
  isExist,
} from '../../../util/general.js';

export function EditResponseTeamIcon({ openModal }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_PROJECT_PERMISSION,
  );

  return (
    ifHasPermission && (
      <IconButton onClick={openModal}>
        <Icon name="edit" fill="#9dacdc" dimensions={{ height: 14 }} />
      </IconButton>
    )
  );
}

EditResponseTeamIcon.propTypes = { openModal: PropTypes.func.isRequired };

function ProjectDetails({ openModal, project = {}, statusUI }) {
  const {
    client,
    parent,
    details,
    endDate,
    manager,
    location,
    property,
    startDate,
    requester,
    suppliers,
    serviceName,
    description,
    technicians,
    addressParts,
  } = project;

  return (
    <>
      <Widget.Item>
        <PageHeader>
          <HeadingH5>Work Order Detail</HeadingH5>
          {statusUI}
        </PageHeader>
        <Grid columns="100px 1fr" simple>
          <DetailsRow title="Type" value={serviceName} color="#ff8c00" />
          <DetailsRow title="Description" value={description} start />
          <DetailsRow title="Details" value={details} withEditor />
          <DetailsRow title="Location" value={location} />
          <DetailsRow title="Property" value={propertyFormatter(property)} />
          <AddressRow title="Address" value={addressParts} />
          <DetailsRow title="Client" value={client} />
          {parent ? (
            <DetailsRow
              title="Master WO"
              value={mrProjectFormatter(parent.projectId)}
            />
          ) : (
            <MultipleDetailsRow
              title="Requester"
              list={R.props(['fullName', 'email', 'phone'], requester)}
            />
          )}
          <Grid.Row>
            <Grid.Cell vertical="center">
              <S type="title">Start & End</S>
            </Grid.Cell>
            <Grid.Cell>
              <BreadCrumbs>
                <BreadCrumbs.Item>
                  <S type="content" bold>
                    {formatDateWithDashes(startDate) || '—'}
                  </S>
                </BreadCrumbs.Item>
                <BreadCrumbs.Item>
                  <S type="content" bold>
                    {formatDateWithDashes(endDate) || '—'}
                  </S>
                </BreadCrumbs.Item>
              </BreadCrumbs>
            </Grid.Cell>
          </Grid.Row>
        </Grid>
      </Widget.Item>
      <Widget.Item>
        <PageHeader>
          <HeadingH5>Response Team</HeadingH5>
          <EditResponseTeamIcon {...{ openModal }} />
          <EditResponseTeamContainer />
        </PageHeader>
        <Grid columns="repeat(2, minmax(100px, 1fr))" simple>
          {[manager, technicians, suppliers].some(isExist) ? (
            <>
              {isExist(manager) && (
                <UserTeamMember member={manager} type="Manager" />
              )}
              {suppliers.map((supplier) => (
                <SupplierTeamMember
                  member={supplier}
                  key={supplier._id}
                  type="Supplier"
                />
              ))}
              {technicians.map((technician) => (
                <UserTeamMember
                  member={technician}
                  key={technician._id}
                  type={getTechnicianTypeByUser(technician)}
                />
              ))}
            </>
          ) : (
            noDataToDisplay('Members')()
          )}
        </Grid>
      </Widget.Item>
    </>
  );
}
ProjectDetails.propTypes = {
  openModal: PropTypes.func.isRequired,
  project: PropTypes.shape({
    status: PropTypes.string,
    serviceName: PropTypes.string,
    description: PropTypes.string,
    details: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    location: PropTypes.string,
    addressParts: PropTypes.objectOf(PropTypes.string),
    property: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    client: PropTypes.string,
    requester: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      cellPhone: PropTypes.string,
    }),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    manager: PropTypes.objectOf(PropTypes.string),
    suppliers: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    ),
    technicians: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    ),
    parent: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  statusUI: PropTypes.node.isRequired,
};

export default ProjectDetails;
