import React from 'react';
import { Layout } from '@poly/site-book';

import AuthContainer from '../hooks/auth-container.js';
import Navigation from '../containers/navbar/navigation-container.js';
import PurchaseOrderLayout from '../layouts/purchase-order-layout.js';
import PurchaseOrderInfo from '../containers/navbar/purchase-order-info-container.js';

function PurchaseOrder() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <PurchaseOrderInfo />
        </Layout.Header>
        <Layout.Content columns="450px 1fr">
          <PurchaseOrderLayout />
        </Layout.Content>
      </Layout>
    </AuthContainer>
  );
}

export default PurchaseOrder;
