import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DatePicker, FileArea } from '@poly/site-ui';
import {
  IconButton,
  HeadingH3,
  HeadingH5,
  TextArea,
  Heading,
  Button,
  Holder,
  Modal,
  Input,
  Grid,
  Icon,
  Pip,
  S,
} from '@poly/site-book';

import Select from '../select/index.js';
import ButtonLoader from '../loader/button.js';
import { Rows } from '../../util/forms/index.js';
import ServerError from '../server-error/index.js';
import InputNumber from '../input-number/index.js';
import { MODAL_STATUSES, STATUS_COLORS } from '../../constants/invoices.js';
import { formatCurrency, lessThen } from '../../util/general.js';
import { formatPlaceholder } from '../../util/select.js';

const Header = Modal.Item.withComponent(Heading);

const Form = Modal.Item.withComponent('form');

const ModalGrid = Modal.Item.withComponent(Grid);

const StyledWrapper = styled.label`
  width: 100%;
  display: block;
  position: relative;
  cursor: text;

  > svg,
  > ${Holder} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
`;

const StyledBlock = styled(Holder)`
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: 20px;
  top: 5px;
`;

const StyledBalance = styled(S)`
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
`;

const StyledTotal = styled(S)`
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

function TitleWithPip({ label }) {
  return (
    <Pip color={STATUS_COLORS[label]}>
      <S type="title">{label}</S>
    </Pip>
  );
}

TitleWithPip.propTypes = {
  label: PropTypes.string.isRequired,
};

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddEditInvoice({
  project,
  closeModal,
  type,
  code,
  file,
  cardNumber,
  amount,
  balance,
  total,
  receiveDate,
  invoiceDate,
  invoiceNumber,
  comments,
  onSubmit,
  errors,
  loading,
  onChange,
  onInvoiceTotalChange,
  onReceiveDateChange,
  onInvoiceDateChange,
  onSelectCode,
  glCodes,
  status,
  onStatusSelect,
  onAddFile,
  onRemoveFile,
}) {
  const items = [
    {
      title: { value: 'Status', props: ['required'] },
      item: (
        <Select
          value={status}
          onChange={onStatusSelect}
          options={R.pipe(
            R.values,
            R.map((value) => ({ value, label: value })),
          )(MODAL_STATUSES)}
          optionRenderer={TitleWithPip}
          clearable={false}
          disabled={R.equals(type, 'add')}
        />
      ),
    },
    {
      title: { value: 'Invoice Number', props: ['required'] },
      error: R.prop('invoiceNumber', errors),
      item: (
        <Input
          name="invoiceNumber"
          placeholder="Invoice Number"
          maxLength={60}
          defaultValue={invoiceNumber}
          invalid={!!R.prop('invoiceNumber', errors)}
          {...{ onChange }}
        />
      ),
    },
    {
      title: { value: 'Invoice Total', props: ['required'] },
      error: R.prop('invoiceTotal', errors),
      item: (
        <StyledWrapper>
          <InputNumber
            name="invoiceTotal"
            style={{ minWidth: 0 }}
            prefix="$"
            thousandSeparator=","
            decimalScale={2}
            isAllowed={lessThen(100000000)}
            allowNegative={false}
            placeholder="$ 0.0"
            defaultValue={total}
            invalid={!!R.prop('invoiceTotal', errors)}
            onChange={onInvoiceTotalChange}
          />
          <StyledBlock key="amount" margin={0}>
            <StyledBalance size={12} textColor="#a1a9c3">
              balance
            </StyledBalance>
            <StyledTotal size={14} textColor="#4b5a8f">
              {formatCurrency(balance)}
            </StyledTotal>
          </StyledBlock>
        </StyledWrapper>
      ),
    },
    {
      title: { value: 'Received Date', props: ['required'] },
      error: R.prop('receiveDate', errors),
      item: (
        <DatePicker
          dateFormat="MM-dd-yyyy"
          selected={receiveDate}
          placeholderText="Received Date"
          onChange={onReceiveDateChange}
          invalid={!!R.prop('receiveDate', errors)}
        />
      ),
    },
    {
      title: { value: 'Invoice Date', props: ['required'] },
      error: R.prop('invoiceDate', errors),
      item: (
        <DatePicker
          dateFormat="MM-dd-yyyy"
          selected={invoiceDate}
          placeholderText="Invoice Date"
          onChange={onInvoiceDateChange}
          invalid={!!R.prop('invoiceDate', errors)}
        />
      ),
    },
    {
      title: { value: 'GL Code', props: ['required'] },
      error: R.prop('code', errors),
      item: (
        <Select
          value={code}
          onChange={onSelectCode}
          placeholder={formatPlaceholder('GL Code')}
          options={glCodes}
          invalid={!!R.prop('code', errors)}
          optionRenderer={SimpleTitle}
        />
      ),
    },
    {
      title: {
        value: 'Comments',
        props: ['', 'description'],
      },
      item: (
        <TextArea
          name="comments"
          placeholder="Comments"
          defaultValue={comments}
        />
      ),
    },
    {
      title: { value: 'Invoice File', props: ['required'] },
      error: R.prop('file', errors),
      item: <FileArea {...{ onAddFile, onRemoveFile, file }} />,
    },
  ];

  return (
    <Modal show>
      <Header margin={20}>
        <HeadingH5 lighter>
          {project ? `WO# ${project}` : 'Non Work Order'}
        </HeadingH5>
        <IconButton onClick={closeModal}>
          <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
        </IconButton>
      </Header>
      <Modal.Item margin={20}>
        <HeadingH3>
          {R.equals(type, 'edit') ? 'Edit ' : 'Add '}
          Supplier Invoice
        </HeadingH3>
      </Modal.Item>
      <Modal.Item margin={20}>
        <Holder center margin={10}>
          <HeadingH5 lighter>
            PO#
            {cardNumber}
          </HeadingH5>
          <S type="badge" style={{ marginTop: -1 }} textColor="#babfd2">
            {' '}
            |{' '}
          </S>
          <HeadingH5 lighter>{formatCurrency(amount)}</HeadingH5>
        </Holder>
      </Modal.Item>
      <Form {...{ onSubmit }}>
        <ModalGrid columns="120px minmax(200px, 320px)" margin={30} simple>
          <Rows {...{ items }} />
        </ModalGrid>
        <ServerError
          error={R.prop('server', errors)}
          style={{ maxWidth: 430 }}
        />
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">{type === 'approve' ? 'Approve PO' : 'Submit'}</S>
          </Button>
        </Modal.Buttons>
      </Form>
    </Modal>
  );
}

AddEditInvoice.propTypes = {
  type: PropTypes.string.isRequired,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  file: PropTypes.shape({
    name: PropTypes.string,
    src: PropTypes.string,
    size: PropTypes.number,
  }),
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    server: PropTypes.string,
    supplier: PropTypes.string,
    amount: PropTypes.string,
    description: PropTypes.string,
  }),

  comments: PropTypes.string,
  invoiceNumber: PropTypes.string,
  cardNumber: PropTypes.string,
  project: PropTypes.string,
  amount: PropTypes.number,
  balance: PropTypes.number,
  total: PropTypes.number,
  receiveDate: PropTypes.instanceOf(Date),
  invoiceDate: PropTypes.instanceOf(Date),
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInvoiceTotalChange: PropTypes.func.isRequired,
  onReceiveDateChange: PropTypes.func.isRequired,
  onInvoiceDateChange: PropTypes.func.isRequired,
  onSelectCode: PropTypes.func.isRequired,
  glCodes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  status: PropTypes.string.isRequired,
  onStatusSelect: PropTypes.func.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
};

AddEditInvoice.defaultProps = {
  errors: {},
  cardNumber: null,
  project: null,
  amount: null,
  balance: null,
  receiveDate: null,
  invoiceDate: null,
  code: null,
  file: null,
  invoiceNumber: null,
  total: null,
  comments: null,
};

export default AddEditInvoice;
