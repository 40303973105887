import * as R from 'ramda';
import { validateUSAZipCode, validateCanadaZipCode } from '@poly/client-utils';
import {
  isNilOrEmpty,
  hasOnlyUSKeyboardCharacters,
  validateEmail,
} from '@poly/utils';
import { INVALID_SUPPLIER_NAME_MESSAGE } from '@poly/constants';

import { removeCurrencyFormatting, splitBySpaces } from '../../util/general.js';

const isNilOrEmptyTrimmed = R.compose(
  isNilOrEmpty,
  R.when(R.is(String), R.trim),
);

const validationRules = {
  required: {
    isNotValid: isNilOrEmptyTrimmed,
    defaultMessage: 'Please Fill Out This Field',
  },
  currency: {
    isNotValid: (value) => R.equals(0, removeCurrencyFormatting(value)),
    defaultMessage: 'Value cannot be zero',
  },
  email: {
    isNotValid: R.complement(validateEmail),
    defaultMessage: 'Email Format is Incorrect',
  },
  phone: {
    isNotValid: (value) => value && !/^\(\d{3}\)\s\d{3}-\d{4}$/.test(value),
    defaultMessage: 'Phone Format is Incorrect',
  },
  fullName: {
    isNotValid: (value) => splitBySpaces(value).length < 2,
    defaultMessage: 'Please Enter First and Last Name',
  },
  onlyUSKeyboardCharacters: {
    isNotValid: (value) => !hasOnlyUSKeyboardCharacters(value),
    defaultMessage: INVALID_SUPPLIER_NAME_MESSAGE,
  },
  address: {
    isNotValid: R.propSatisfies(isNilOrEmpty, 'formatted_address'),
    defaultMessage: 'Select address or set it manually',
  },
  zip: {
    isNotValid: R.pathSatisfies(isNilOrEmpty, ['address_parts', 'postal_code']),
    defaultMessage: 'Zip is required. You can set it manually',
  },
  zipLength: {
    isNotValid: R.pipe(
      R.pathOr('', ['address_parts', 'postal_code']),
      R.anyPass([validateCanadaZipCode, validateUSAZipCode]),
      R.not,
    ),
    defaultMessage: 'Zip format is wrong. Check it and try to change',
  },
};

class Schema {
  constructor(rules) {
    this.rules = rules;

    this.validateField = this.validateField.bind(this);
    this.validate = this.validate.bind(this);
  }

  validateField(name, value) {
    let errorMessage = null;

    if (R.has(name, this.rules)) {
      this.rules[name].forEach(({ rule, message }) => {
        if (R.is(String, rule) && validationRules[rule].isNotValid(value)) {
          errorMessage = message || validationRules[rule].defaultMessage;
        } else if (R.is(Function, rule) && rule(value)) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  }

  validate(data) {
    const errors = {
      isInvalid: false,
    };

    Object.keys(this.rules).forEach((name) => {
      errors[name] = this.validateField(name, data[name]);

      if (!R.isNil(errors[name])) {
        errors.isInvalid = true;
      }
    });

    return errors;
  }
}

const SchemaFactory = (rules) => new Schema(rules);

export default SchemaFactory;
