import React from 'react';
import { Layout } from '@poly/site-book';

import { LayoutContent } from '../styled.js';
import AuthContainer from '../hooks/auth-container.js';
import POLogLayout from '../layouts/po-log-page-layout.js';
import POLogFilter from '../components/purchase-orders/po-log-filter.js';
import Navigation from '../containers/navbar/navigation-container.js';

function POLog() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <POLogFilter />
        </Layout.Header>
        <LayoutContent>
          <POLogLayout />
        </LayoutContent>
      </Layout>
    </AuthContainer>
  );
}

export default POLog;
