import * as R from 'ramda';
import React from 'react';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { L } from '@poly/site-book';
import { highlightTextToReactElement } from '@poly/client-utils/src/strings.js';

import { isExist } from '../../util/general.js';
import { useOpenUserStaffSidebar } from './useOpenUserStaffSidebar.js';

const A = styled(L.withComponent('span'))`
  display: inline-block;
`;

export function UserStaffLink({ user, searchText }) {
  const openSidebar = useOpenUserStaffSidebar();

  return (
    <A onClick={() => openSidebar(user)} bold>
      {isExist(searchText)
        ? highlightTextToReactElement(searchText, R.prop('fullName', user))
        : R.prop('fullName', user)}
    </A>
  );
}

UserStaffLink.propTypes = {
  searchText: string,
  user: shape({ _id: string.isRequired }).isRequired,
};
