import * as R from 'ramda';
import { ForgotPasswordPageBase } from '@poly/common-ui';
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  routeLogin,
  setForgotPasswordMessage,
} from '../redux/actions/index.js';
import { APP_TITLE } from './Login/LoginPage.js';

function ForgotPasswordPage() {
  const dispatch = useDispatch();

  const onSuccess = (response) => {
    dispatch(setForgotPasswordMessage(R.prop('message', response)));
    dispatch(routeLogin());
  };
  const goToLogin = () => dispatch(routeLogin());

  return (
    <ForgotPasswordPageBase
      onSuccess={onSuccess}
      goToLogin={goToLogin}
      title={APP_TITLE}
    />
  );
}

export default ForgotPasswordPage;
