import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@poly/site-ui';
import ProjectHistory from '../../../../components/mr-pm-project/right-block/history.js';
import { projectPMFormatter } from '../../../../util/project-utils.js';
import { dateFormatter } from '../../../../util/job-costs.js';
import {
  noDataToDisplayWidget,
  entityFormatter,
  fieldFormatter,
} from '../../../../util/general.js';
import { useMRProjectHistory } from '../../../../hooks/mr-pm-project.js';

const historyColumns = [
  ['WO #', R.identity, projectPMFormatter],
  ['Description', R.prop('description'), fieldFormatter],
  ['Completed', R.prop('endDate'), dateFormatter],
];

export default function (props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const { loading, projects } = useMRProjectHistory(projectId);

  if (loading) return <Loader />;
  if (!projects.length) return noDataToDisplayWidget('WO History')();

  const historyColumnsValue = entityFormatter(projects, historyColumns);

  return <ProjectHistory {...props} historyColumns={historyColumnsValue} />;
}
