import { TableSearchComponent } from '@poly/site-ui';
import React from 'react';
import {
  setReportProjectsPaginationCurrentPage,
  setReportTableSearchString,
} from '../../redux/actions/index.js';

export default function (props) {
  return (
    <TableSearchComponent
      {...props}
      setTableSearchString={setReportTableSearchString}
      setPaginationCurrentPage={setReportProjectsPaginationCurrentPage}
    />
  );
}
