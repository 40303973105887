import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@poly/site-ui';
import Table from '../../components/table/with-widget.js';
import {
  entityFormatter,
  fieldFormatter,
  noDataToDisplayWidget,
} from '../../util/general.js';
import { getLinkByProjectType } from '../../utils/projects/get-link-by-type.js';
import { useUserProjectsQuery } from '../../hooks/staff.js';

function TabLoader() {
  return <Loader size={26} minHeight={54} />;
}

const columnsValue = [
  ['WO #', R.identity, getLinkByProjectType],
  ['Description', R.prop('description'), fieldFormatter],
];

export default function UserProjects(props) {
  const userId = useSelector((state) => state.staff.modals.sidebar.userId);

  const activeTab = useSelector(
    (state) => state.staff.modals.sidebar.tabs.activeTab,
  );
  const activeQuery = useSelector(
    (state) => state.staff.modals.sidebar.tabs.activeQuery,
  );
  const projectsResultPropName = useSelector(
    (state) => state.staff.modals.sidebar.tabs.projectsResultPropName,
  );

  const selectedUser = useSelector((state) => state.staff.modals.user);

  const { loading, projects } = useUserProjectsQuery(
    userId,
    activeQuery,
    projectsResultPropName,
  );

  const { rows, columns, headers, formats, ...restProps } = entityFormatter(
    projects,
    columnsValue,
  );

  if (loading) {
    return <TabLoader />;
  }

  if (!projects.length) {
    return noDataToDisplayWidget('Projects')();
  }

  return (
    userId && (
      <Table
        {...props}
        {...restProps}
        rows={rows}
        columns={columns}
        headers={headers}
        formats={formats}
        gridColumns="90px auto"
        activeTab={activeTab}
        selectedUser={selectedUser}
        activeQuery={activeQuery}
        projectsResultPropName={projectsResultPropName}
      />
    )
  );
}
