import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  HeadingH3,
  HeadingH5,
  Heading,
  Button,
  Modal,
  Icon,
  S,
} from '@poly/site-book';

import { ButtonLoader } from '../ButtonLoader.js';
import { ErrorText } from '../Form/ValidationMessage.js';

export function ConfirmModal({
  showModal: show,
  closeModal,
  heading,
  subHeading,
  text,
  onConfirm,
  loading,
  error,
  confirmBtnText,
  additionalBtnText,
  withAdditionalBtn,
  onAdditionalBtnClick,
}) {
  return (
    <Modal {...{ show }}>
      <Modal.Item>
        <Heading>
          <HeadingH5>{heading}</HeadingH5>
          <IconButton onClick={closeModal} disabled={loading}>
            <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
          </IconButton>
        </Heading>
      </Modal.Item>
      <Modal.Item margin={20}>
        <HeadingH3 lighter>{subHeading}</HeadingH3>
      </Modal.Item>
      <Modal.Item margin={30} style={{ minWidth: 400 }}>
        <S type="content">{text}</S>
      </Modal.Item>
      {error && <ErrorText>{error}</ErrorText>}
      <Modal.Buttons>
        <Button
          type="reset"
          mode="gray"
          onClick={closeModal}
          disabled={loading}
        >
          <S type="title">Cancel</S>
        </Button>
        {withAdditionalBtn && (
          <Button
            mode="orange"
            onClick={onAdditionalBtnClick}
            disabled={loading}
          >
            {loading && <ButtonLoader />}
            <S type="title">{additionalBtnText}</S>
          </Button>
        )}
        <Button
          type="submit"
          mode="orange"
          onClick={onConfirm}
          disabled={loading}
        >
          {loading && <ButtonLoader />}
          <S type="title">{confirmBtnText}</S>
        </Button>
      </Modal.Buttons>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  heading: PropTypes.string,
  subHeading: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  error: PropTypes.string,
  confirmBtnText: PropTypes.string,
  withAdditionalBtn: PropTypes.bool,
  additionalBtnText: PropTypes.string,
  onAdditionalBtnClick: PropTypes.func,
};

ConfirmModal.defaultProps = {
  heading: null,
  error: null,
  confirmBtnText: 'Confirm',
  withAdditionalBtn: false,
  additionalBtnText: 'Additional',
  onAdditionalBtnClick: () => undefined,
};
