import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { SUPPLIERS_EMAILS } from '../../../../constants/suppliers.js';
import SuppliersTab from '../../../../components/project/details/response-team/suppliers-tab.js';
import { getEmailByType } from '../../../../utils/suppliers/index.js';
import { DropDownLoader } from '../../../../util/dropdown.js';
import {
  AddResponseTeamSuccess,
  EditResponseTeamSuccess,
  GeneralError,
} from '../../../../constants/alerts.js';
import { omitBlockedSuppliersQuery } from '../../../../utils/suppliers/suppliers-filter.js';
import { useSuppliers } from '../../../../hooks/suppliers.js';
import { useUpdateProject } from '../../../../hooks/projects.js';

function SuppliersResponseTeam(props) {
  const { assignedSuppliers, searchTerm } = props;

  const projectId = useSelector(
    (state) => state.projects.modals.responseTeam.projectDbId,
  );
  const [addLoading, setAddLoading] = useState(false);
  const [projectSuppliers, setProjectSuppliers] = useState([]);

  useEffect(() => {
    if (assignedSuppliers && assignedSuppliers.length > 0) {
      const mappedSuppliers = assignedSuppliers.map((supplier) => ({
        _id: supplier._id,
        name: supplier.company.name,
        email: getEmailByType(
          supplier.company.emails,
          SUPPLIERS_EMAILS.SERVICE,
        ),
        rating: supplier.rating,
      }));
      setProjectSuppliers(mappedSuppliers);
    }
  }, [assignedSuppliers]);

  const { allSuppliers, supplierLoading } = useSuppliers({
    searchTerm,
    query: omitBlockedSuppliersQuery,
  });

  const { updateProject } = useUpdateProject();

  const suppliersIds = projectSuppliers.length
    ? projectSuppliers.map((supplier) => supplier._id)
    : [];

  const filteredSuppliers = allSuppliers
    ?.filter(({ _id }) => !suppliersIds.includes(_id))
    ?.map(({ _id, rating, company: { name, emails } }) => ({
      _id,
      rating,
      name,
      email: getEmailByType(emails, SUPPLIERS_EMAILS.SERVICE),
    }));

  const handleSuppliersClick = useCallback(
    (data, isSelected) => () => {
      setAddLoading(true);
      const projectSuppliersIds = projectSuppliers.map(
        (supplier) => supplier._id,
      );
      const suppliersIdsValue = isSelected
        ? projectSuppliersIds.filter((id) => id !== data._id)
        : [...projectSuppliersIds, data._id];
      const newSuppliers = isSelected
        ? projectSuppliers.filter((supplier) => supplier._id !== data._id)
        : [...projectSuppliers, data];

      updateProject(projectId, {
        suppliers: suppliersIdsValue.map((supplierId) => ({ supplierId })),
      })
        .then(() => {
          setProjectSuppliers(newSuppliers);
          setAddLoading(false);
          toast.success(
            isSelected ? EditResponseTeamSuccess : AddResponseTeamSuccess,
          );
        })
        .catch(() => {
          setAddLoading(false);
          toast.error(GeneralError);
        });
    },
    [allSuppliers, supplierLoading, updateProject],
  );

  if (supplierLoading) return <DropDownLoader />;

  return (
    <SuppliersTab
      {...props}
      projectSuppliers={projectSuppliers}
      allSuppliers={filteredSuppliers}
      addLoading={addLoading}
      handleSuppliersClick={handleSuppliersClick}
    />
  );
}

SuppliersResponseTeam.propTypes = {
  assignedSuppliers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  searchTerm: PropTypes.string,
};

export default SuppliersResponseTeam;
