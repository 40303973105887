import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { updatesSubscription } from '@poly/site-ui';
import { useEntitySubscriptionQuery } from '@poly/client-utils/src/entitySubscription.js';
import { useReactiveEntities } from './useReactiveEntities.js';
import {
  suppliersChanged,
  suppliersQuery,
  getSupplierPeople,
  usersChanged,
  getSupplier,
  getSupplierProjects,
  searchProjectChanged,
  getSupplierUpdates,
  serviceTypesQuery,
  createSupplier,
  updateSupplier,
} from '../queries/index.js';
import { SUPPLIERS_SORTING } from '../constants/suppliers.js';
import { MAX_SIZE, STANDARD_SIZE } from '../constants/index.js';
import { useReactiveUpdates } from './useReactiveUpdates.js';
import { supplierDetailsSubscription } from '../queries/suppliers/subscriptions.js';

export const useSuppliers = ({ searchTerm, query, sort, from, pageSize }) => {
  const { data, loading, result } = useReactiveEntities({
    gqlQuery: suppliersQuery,
    gqlChangedQuery: suppliersChanged,
    query,
    from,
    pageSize,
    alias: 'useSuppliers',
    searchTerm,
    sort,
  });

  const total = data?.searchSuppliers.total || 0;
  const allSuppliers = data?.searchSuppliers.hits || [];
  const supplierLoading = loading || false;

  return {
    total,
    allSuppliers,
    supplierLoading,
    result,
  };
};

export const useSupplierPeople = (supplierId) => {
  const query = {
    nested: {
      path: 'links',
      query: supplierId ? { term: { 'links.supplierId': supplierId } } : null,
    },
  };

  const { data, loading } = useReactiveEntities({
    gqlQuery: getSupplierPeople,
    gqlChangedQuery: usersChanged,
    additionalVars: { supplierId },
    query,
    alias: 'useSupplierPeople',
    skipQuery: !supplierId,
  });

  return {
    people: data?.supplier.searchContacts?.hits || [],
    defaultAddress: data?.supplier.company.address,
    peopleLoading: loading,
  };
};

export const useSupplierDetails = (supplierId) => {
  const { data, loading, networkStatus } = useQuery(getSupplier, {
    alias: 'useSupplierDetails',
    variables: { supplierId },
    notifyOnNetworkStatusChange: true,
  });

  return {
    supplier: data?.supplier,
    loading,
    networkStatus,
  };
};

export const useSuppliersToSelect = (searchTerm) => {
  const { sort } = SUPPLIERS_SORTING;

  const noSupplierResults = !searchTerm.length ? null : 'No Results Found';

  const { total, allSuppliers, supplierLoading } = useSuppliers({
    searchTerm,
    sort,
  });

  return {
    total,
    allSuppliers,
    supplierLoading,
    noSupplierResults,
  };
};

export const useSupplierProjectsQuery = ({ supplierId, query }) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: getSupplierProjects,
      gqlChangedQuery: searchProjectChanged,
      additionalVars: { supplierId },
      query,
      pageSize: MAX_SIZE,
      alias: 'useSupplierProjectsQuery',
    });

  const projects = data?.supplier.searchProjects.hits || [];

  return {
    projects,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};

export const useSupplierUpdatesQuery = ({
  additionalVars,
  queryEndpointName,
  from,
  size,
}) => {
  const { result, loading } = useReactiveUpdates({
    gqlQuery: getSupplierUpdates,
    gqlSubscription: updatesSubscription,
    additionalVars,
    queryEndpointName,
    size,
    from,
    alias: 'useSupplierUpdatesQuery',
  });

  return {
    result,
    loading,
  };
};

export const useSupplierDetailsSub = (props) => {
  const { subscribeToMore, restProps } = useEntitySubscriptionQuery({
    gqlQuery: getSupplier,
    queryEndpointName: 'supplier',
    gqlSubscription: supplierDetailsSubscription,
    subscriptionEndpointName: 'supplierChanged',
    queryParamsFromProps: R.compose(
      R.objOf('supplierId'),
      R.prop('supplierId'),
    ),
    subscriptionParamsFromProps: R.compose(R.objOf('id'), R.prop('supplierId')),
    propsOfComponent: props,
    alias: 'useSupplierDetailsSub',
  });

  return {
    subscribeToMore,
    restProps,
  };
};

export const useSupplierNames = (nameSearch) => {
  const { data, loading } = useQuery(suppliersQuery, {
    alias: 'useSupplierNames',
    variables: {
      input: {
        size: 3,
        query: {
          bool: {
            should: [
              {
                term: {
                  'company.name.keyword': { value: nameSearch, boost: 3 },
                },
              },
              { term: { 'company.name': nameSearch } },
            ],
          },
        },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const names = R.pipe(
    R.pathOr([], ['searchSuppliers', 'hits']),
    R.map(R.pick(['_id', 'company'])),
  )(data);

  return {
    data,
    loading,
    names,
  };
};

export const useSupplierServiceTypes = ({ typeSearch, supplierSource }) => {
  const { data, loading } = useQuery(serviceTypesQuery, {
    alias: 'useSupplierServiceTypes',
    variables: {
      input: {
        size: STANDARD_SIZE,
        searchTerm: typeSearch,
        ...(supplierSource ? { query: { match: { supplierSource } } } : {}),
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const serviceTypes = data?.searchServiceTypes.hits || [];

  return {
    data,
    typesLoading: loading,
    serviceTypes,
  };
};

export const useCreateSupplier = () => {
  const [mutate] = useMutation(createSupplier, {
    alias: 'useCreateSupplier',
    refetchQueries: ['suppliersQuery'],
  });

  return {
    createSupplier: (input) => mutate({ variables: { input } }),
  };
};

export const useUpdateSupplier = () => {
  const [mutate] = useMutation(updateSupplier, {
    alias: 'useUpdateSupplier',
    refetchQueries: ['getSupplier'],
  });

  return {
    updateSupplier: (id, update) => mutate({ variables: { id, update } }),
  };
};

export const useSuppliersCountQuery = ({ sort, query }) => {
  const { data, loading, subscribeToMore, refetch, networkStatus, result } =
    useReactiveEntities({
      gqlQuery: suppliersQuery,
      gqlChangedQuery: suppliersChanged,
      sort,
      query,
      alias: 'useSuppliersCountQuery',
    });

  const count = data?.searchSuppliers.total || 0;

  return {
    count,
    data,
    loading,
    subscribeToMore,
    refetch,
    networkStatus,
    result,
  };
};
