import PropTypes from 'prop-types';

export const commonTablePropTypes = {
  headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
};
