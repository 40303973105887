import * as R from 'ramda';
import React from 'react';
import { Pip, S } from '@poly/site-book';
import { highlightTextToReactElement } from '@poly/client-utils';

import { ACTIVE, STATUS_COLORS } from '../constants/staff.js';
import { isExist } from './general.js';

// getUserTypeName :: User -> String
export const getUserTypeName = R.pathOr('–', [
  'userGroups',
  0,
  'userGroup',
  'name',
]);

export const statusFormatter = (search) =>
  function (user) {
    const isPrimary = R.propOr(false, 'isPrimary', user);
    const status = R.when(R.isNil, R.always(ACTIVE))(R.prop('status', user));
    return isPrimary ? (
      <Pip color={STATUS_COLORS[ACTIVE]}>
        <S type="title" textColor="#2d2f33">
          {isExist(search)
            ? highlightTextToReactElement(search, 'Primary')
            : 'Primary'}
        </S>
      </Pip>
    ) : (
      <Pip color={STATUS_COLORS[status]}>
        <S type="title" textColor="#2d2f33">
          {isExist(search)
            ? highlightTextToReactElement(search, status)
            : status}
        </S>
      </Pip>
    );
  };
