import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  UPDATE_MATERIAL_SPEND_PERMISSION,
  DELETE_MATERIAL_SPEND_PERMISSION,
  UPDATE_TIME_LOG_PERMISSION,
  DELETE_TIME_LOG_PERMISSION,
} from '@poly/security';

import { Loader } from '@poly/site-ui';
import JobCostsComponent from '../../../../components/project/job-costs/index.js';
import { supplierFormatter } from '../../../../utils/suppliers/constructions.js';

import {
  lineStatusFormatter,
  POFormatter,
  projectInvoiceLinkFormatter,
} from '../../../../utils/purchase-orders/index.js';
import {
  currencyFormatter,
  dateFormatter,
  durationFormatter,
  EditIcon,
  RemoveIcon,
} from '../../../../util/job-costs.js';
import {
  setInvoicesModal,
  setMaterialsModal,
  setPOsModal,
  setTimelogsModal,
} from '../../../../redux/actions/index.js';
import {
  entityFormatter,
  fieldFormatter,
  formatCurrency,
} from '../../../../util/general.js';
import { EditPOIcon } from '../../../../utils/purchase-orders/pos-hocs.js';
import {
  useProjectJobCosts,
  useRemoveMaterials,
  useRemoveTimelogs,
} from '../../../../hooks/project-job-costs.js';

const getAlign = (horizontal) => ({
  align: { vertical: 'center', horizontal },
});

const purchaseOrderColumns = (dispatch, user) => [
  ['PO #', R.identity, POFormatter(), getAlign()],
  ['Status', R.prop('status'), lineStatusFormatter, getAlign()],
  ['Supplier', R.prop('supplier'), supplierFormatter, getAlign()],
  [
    'Invoice #',
    R.identity,
    projectInvoiceLinkFormatter(dispatch, setInvoicesModal),
    getAlign(),
  ],
  ['Amount', R.prop('amount'), formatCurrency, getAlign()],
  [
    '',
    R.identity,
    (order) => (
      <EditPOIcon
        {...{
          dispatch,
          user,
          order: { ...order, projectJobCostsRelation: true },
        }}
      />
    ),
    getAlign('center'),
  ],
];

const technicianColumns = (dispatch) => [
  [
    'Technician',
    R.path(['assignedTo', 'fullName']),
    fieldFormatter,
    getAlign(),
  ],
  ['Date', R.prop('startDate'), dateFormatter, getAlign()],
  ['Time', R.prop('duration'), durationFormatter, getAlign('end')],
  [
    '',
    R.identity,
    (data) => (
      <EditIcon
        {...{
          dispatch,
          action: setTimelogsModal,
          permissions: UPDATE_TIME_LOG_PERMISSION,
          ...data,
        }}
      />
    ),
    getAlign('center'),
  ],
  [
    '',
    R.identity,
    (data) => (
      <RemoveIcon
        {...{
          dispatch,
          action: setTimelogsModal,
          permissions: DELETE_TIME_LOG_PERMISSION,
          ...data,
        }}
      />
    ),
    getAlign('center'),
  ],
];

const materialColumns = (dispatch) => [
  ['Description', R.prop('description'), fieldFormatter, getAlign()],
  ['Unit cost', R.prop('price'), currencyFormatter, getAlign('end')],
  ['QTY', R.prop('quantity'), fieldFormatter, getAlign('end')],
  ['Total', R.prop('total'), currencyFormatter, getAlign('end')],
  [
    ' ',
    R.identity,
    (data) => (
      <EditIcon
        {...{
          dispatch,
          action: setMaterialsModal,
          permissions: UPDATE_MATERIAL_SPEND_PERMISSION,
          ...data,
        }}
      />
    ),
    getAlign('center'),
  ],
  [
    '',
    R.identity,
    (data) => (
      <RemoveIcon
        {...{
          dispatch,
          action: setMaterialsModal,
          permissions: DELETE_MATERIAL_SPEND_PERMISSION,
          ...data,
        }}
      />
    ),
    getAlign('center'),
  ],
];

export default function (props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user);

  const projectId = useSelector((state) => state?.location.payload.id);

  const { removeMaterial } = useRemoveMaterials();
  const { removeTimelogs } = useRemoveTimelogs();
  const { project } = useProjectJobCosts(projectId);

  const purchaseOrdersValue = entityFormatter(
    project?.purchaseOrders,
    purchaseOrderColumns(dispatch, user),
  );

  const timeLogsValue = entityFormatter(
    project?.timeLogs,
    technicianColumns(dispatch),
  );

  const materials = entityFormatter(
    project?.materialSpends,
    materialColumns(dispatch),
  );

  const openTimeLogsModal = useCallback(
    () => dispatch(setTimelogsModal({ type: 'add' })),
    [dispatch, setTimelogsModal],
  );

  const openMaterialsModal = useCallback(
    () => dispatch(setMaterialsModal({ type: 'add' })),
    [dispatch, setMaterialsModal],
  );

  const addPOModal = useCallback(
    () =>
      dispatch(
        setPOsModal({ addPORequest: { project: projectId, id: project?._id } }),
      ),
    [dispatch, setPOsModal],
  );

  if (!project) return <Loader />;

  return (
    <JobCostsComponent
      {...props}
      materials={materials}
      timeLogs={timeLogsValue}
      purchaseOrders={purchaseOrdersValue}
      openTimeLogsModal={openTimeLogsModal}
      openMaterialsModal={openMaterialsModal}
      addPOModal={addPOModal}
      removeMaterial={removeMaterial}
      removeTimelogs={removeTimelogs}
    />
  );
}
