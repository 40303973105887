import React from 'react';
import { pathEqLegacy } from '@poly/utils';
import { useDispatch, useSelector } from 'react-redux';

import FilterComponent from '../../components/assets/filter.js';
import { setAssetModal } from '../../redux/actions/asset.js';

function AssetsFilterContainer() {
  const dispatch = useDispatch();
  const isAddModalOpened = useSelector(
    pathEqLegacy(['asset', 'modal', 'type'], 'add'),
  );

  const openAddAssetModal = () => dispatch(setAssetModal({ type: 'add' }));

  return <FilterComponent {...{ openAddAssetModal, isAddModalOpened }} />;
}

export default AssetsFilterContainer;
