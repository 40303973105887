import React from 'react';
import { useSelector } from 'react-redux';

import { TableSearchComponent, isDateFilterNil } from '@poly/site-ui';
import {
  setCompletedWOsTableSearchString,
  setCompletedWOsPaginationCurrentPage,
} from '../../redux/actions/index.js';

export default function () {
  const completedProjects = useSelector((state) => state.completedProjects);
  const { tableSearchString } = completedProjects.tableSearchString;
  const disabled = isDateFilterNil(completedProjects);

  return (
    <TableSearchComponent
      tableSearchString={tableSearchString}
      disabled={disabled}
      setTableSearchString={setCompletedWOsTableSearchString}
      setPaginationCurrentPage={setCompletedWOsPaginationCurrentPage}
    />
  );
}
