import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import Link from 'redux-first-router-link';
import { highlightTextToReactElement } from '@poly/client-utils';
import { HREF_PLACEHOLDER } from '@poly/constants';
import { L, Pip, S } from '@poly/site-book';

import { PROPERTIES_FILTERS } from '../constants/properties.js';
import { isExist } from './general.js';

const A = styled(L.withComponent('span'))`
  display: inline-block;
`;

export const propertyLinkFormatter = (property, search) => {
  const id = R.propOr(null, '_id', property);
  const name = R.propOr(null, 'name', property);

  return property ? (
    // eslint-disable-next-line
    <Link to={{ type: 'PROPERTY', payload: { id } }}>
      <A bold>
        {isExist(search) ? highlightTextToReactElement(search, name) : name}
      </A>
    </Link>
  ) : (
    <S type="content" bold>
      —
    </S>
  );
};

export const statusFormatter = (search) =>
  function (status) {
    const { title, color } = PROPERTIES_FILTERS.find(
      ({ type }) => status === type,
    );

    return (
      <Pip {...{ color }}>
        <S type="title">
          {isExist(search) ? highlightTextToReactElement(search, title) : title}
        </S>
      </Pip>
    );
  };

export const propertyUserFormatter = (dispatch, action) =>
  function (user) {
    return (
      <L onClick={() => dispatch(action(user))} href={HREF_PLACEHOLDER} bold>
        {user.fullName}
      </L>
    );
  };
