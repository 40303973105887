import React from 'react';
import { ServerError } from '@poly/site-ui';

import { ERROR_MESSAGE } from '../../constants/index.js';

function SiteServerError(props) {
  return <ServerError {...props} errorMessage={ERROR_MESSAGE} />;
}

export default SiteServerError;
