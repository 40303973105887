import React from 'react';
import PropTypes from 'prop-types';
import {
  S,
  Pip,
  FilterByStatus as PolyBookFilterByStatus,
} from '@poly/site-book';

export function FilterByStatus({
  active,
  onClick,
  color,
  title,
  showPip,
  typeFilter,
  count,
}) {
  return (
    <PolyBookFilterByStatus
      {...{ active, color }}
      onClick={() => onClick(typeFilter, count)}
    >
      {showPip && <Pip {...{ color }} />}
      <S type="title">
        {title} ({count})
      </S>
    </PolyBookFilterByStatus>
  );
}

FilterByStatus.propTypes = {
  active: PropTypes.bool.isRequired,
  showPip: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  typeFilter: PropTypes.string,
  count: PropTypes.number,
};

FilterByStatus.defaultProps = {
  count: 0,
  typeFilter: '',
};
