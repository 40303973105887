import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { collectionNames } from '@poly/constants';
import { FilesTab } from '@poly/site-ui';
import PropTypes from 'prop-types';
import { fileColumns } from '../../../utils/files.js';

function FilesProject(props) {
  const dispatch = useDispatch();

  const { collection } = props;

  const id = useSelector((state) => state.location.payload.id);

  const documentId = useSelector((state) => state.projects.activeProject._id);

  const collectionValue = collection || collectionNames.projects;
  const fileColumnsValue = fileColumns(dispatch);

  return (
    <FilesTab
      {...props}
      id={id}
      documentId={documentId}
      collection={collectionValue}
      fileColumns={fileColumnsValue}
    />
  );
}

FilesProject.propTypes = {
  collection: PropTypes.string,
};

export default FilesProject;
