import * as R from 'ramda';
import { bool, shape, string } from 'prop-types';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import { Avatar, Grid, L, Message, S, Username } from '@poly/site-book';
import { getFirstLetterOfNames } from '@poly/client-utils';
import { HREF_PLACEHOLDER } from '@poly/constants';
import { isExist } from '../util/general.js';
import { phoneFormatter } from './formatters/index.js';
import { useOpenUserStaffSidebar } from './staff/useOpenUserStaffSidebar.js';

const href = HREF_PLACEHOLDER;
const checkData = R.anyPass([R.isEmpty, R.isNil]);

const GridCellGap = styled(Grid.Cell)`
  grid-row-gap: 4px;
`;

export function MultipleDetailsRow(props) {
  return (
    <Grid.Row>
      <Grid.Cell
        {...(checkData(R.prop('list', props)) && { vertical: 'center' })}
      >
        <S type="title" textColor="#5d688c">
          {R.prop('title', props)}
        </S>
      </Grid.Cell>
      <GridCellGap>
        {checkData(R.prop('list', props)) ? (
          <S type="content" bold>
            {' '}
            —{' '}
          </S>
        ) : (
          R.pipe(
            R.prop('list'),
            R.reject(R.isNil),
            R.map((item) =>
              R.prop('linkType', props) ? (
                <L
                  key={R.prop('fileName', item)}
                  href={R.prop('url', item)}
                  target="_blank"
                  bold
                >
                  {R.prop('fileName', item)}
                </L>
              ) : (
                <S key={item} type="content" bold>
                  {item}
                </S>
              ),
            ),
          )(props)
        )}
      </GridCellGap>
    </Grid.Row>
  );
}

export function EmailRow(props) {
  return (
    <Grid.Row>
      <Grid.Cell
        {...(!isExist(R.prop('value', props)) && { vertical: 'center' })}
      >
        <S type="title" textColor="#5d688c">
          {R.prop('title', props)}
        </S>
      </Grid.Cell>
      <GridCellGap>
        {checkData(R.prop('array', props)) ? (
          <S type="content" bold>
            {' '}
            —{' '}
          </S>
        ) : (
          R.prop('array', props).map((item, index) => (
            <S key={R.inc(index)} type="content" bold>
              {R.prop('email', item)}
            </S>
          ))
        )}
      </GridCellGap>
    </Grid.Row>
  );
}

const MemberDataWrapper = styled.div`
  display: block;
  margin-top: 5px;

  ${S} {
    display: block;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export function OpenStaffSidebarUsername(props) {
  const { isManager, ...userProps } = props;

  const openUserStaffSidebar = useOpenUserStaffSidebar();

  const onClick = useCallback(() => {
    openUserStaffSidebar(userProps, R.always(isManager));
  }, [isManager, openUserStaffSidebar]);

  return <Username {...userProps} showLink onClick={onClick} />;
}

OpenStaffSidebarUsername.propTypes = {
  _id: string.isRequired,
  isManager: bool,
};
export function UserTeamMember(props) {
  return (
    <Grid.Row>
      <Grid.Cell>
        <OpenStaffSidebarUsername
          {...R.pathOr({}, ['member'], props)}
          isManager={props.type === 'Manager'}
        >
          <Avatar
            type="text"
            content={getFirstLetterOfNames(
              props.member.firstName,
              props.member.lastName,
            )}
          />
        </OpenStaffSidebarUsername>
        <Message.Content>
          <S type="title">{R.prop('type', props)}</S>
        </Message.Content>
      </Grid.Cell>
      <Grid.Cell>
        <MemberDataWrapper>
          <S type="content" bold>
            {phoneFormatter(R.path(['member', 'phone'], props)) || '—'}
          </S>
          <S type="content" bold>
            {R.path(['member', 'email'], props) || '—'}
          </S>
        </MemberDataWrapper>
      </Grid.Cell>
    </Grid.Row>
  );
}

UserTeamMember.propTypes = {
  type: string.isRequired,
  member: shape({
    email: string,
    firstName: string,
    lastName: string,
  }),
};

export function SupplierTeamMember(props) {
  return (
    <Grid.Row>
      <Grid.Cell>
        <Username name={R.pathOr('', ['member', 'name'], props)} href={href}>
          <Avatar
            type="text"
            content={R.path(['member', 'name'], props)
              .substring(0, 2)
              .toUpperCase()}
          />
        </Username>
        <Message.Content>
          <S type="title">{R.prop('type', props)}</S>
        </Message.Content>
      </Grid.Cell>
      <Grid.Cell>
        <MemberDataWrapper>
          <S type="content" bold>
            {phoneFormatter(R.path(['member', 'phone'], props)) || '—'}
          </S>
          <S type="content" bold>
            {R.path(['member', 'email'], props) || '—'}
          </S>
        </MemberDataWrapper>
      </Grid.Cell>
    </Grid.Row>
  );
}

export { DetailsRow, AddressDetailsRow as AddressRow } from '@poly/site-ui';
