import * as R from 'ramda';
import React from 'react';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Loader } from '@poly/site-ui';
import { ProjectType } from '@poly/constants';

import Projects from '../../../components/asset/tabs/projects.js';
import {
  projectFormatter,
  projectPMFormatter,
} from '../../../util/project-utils.js';
import {
  fieldFormatter,
  entityFormatter,
  formatDateWithDashes,
  noDataToDisplayWidget,
} from '../../../util/general.js';
import { useAssetProjects } from '../../../hooks/assets.js';

const dateFormatter = (value) => fieldFormatter(formatDateWithDashes(value));

function ProjectLink({ type, projectId }) {
  if (type === ProjectType.PREVENTIVE_MAINTENANCE) {
    return projectPMFormatter({ projectId });
  }

  return projectFormatter(projectId);
}

ProjectLink.propTypes = {
  projectId: string,
  type: string,
};

const columns = [
  ['WO #', R.pick(['projectId', 'type']), ProjectLink],
  ['Description', R.prop('description'), fieldFormatter],
  ['Completed', R.prop('endDate'), dateFormatter],
];

export default function (props) {
  const assetId = useSelector((state) => state.location.payload.id);

  const { loading, projects } = useAssetProjects(assetId);

  const projectsObj = entityFormatter(projects, columns);

  if (loading) return <Loader />;
  if (!projects.length) return noDataToDisplayWidget('Projects')();

  return <Projects {...props} projects={projectsObj} />;
}
