import React from 'react';
import { useSelector } from 'react-redux';

import { TableSearchComponent } from '@poly/site-ui';
import {
  setAssetsPaginationCurrentPage,
  setAssetsTableSearchString,
} from '../../redux/actions/index.js';

export default function () {
  const tableSearchString = useSelector(
    (state) => state.assets.tableSearchString,
  );
  const disabled = useSelector((state) => state.assets.filter === null);

  return (
    <TableSearchComponent
      tableSearchString={tableSearchString}
      disabled={disabled}
      setTableSearchString={setAssetsTableSearchString}
      setPaginationCurrentPage={setAssetsPaginationCurrentPage}
    />
  );
}
