import React from 'react';
import { useSelector } from 'react-redux';
import { Loader, usePaginatorState } from '@poly/site-ui';
import ProjectUpdates from '../../../../components/project/project-updates.js';
import { useMRProjectUpdatesQuery } from '../../../../hooks/mr-pm-project.js';
import useFilesUpdates from '../../../../hooks/useFiles.js';

export default function (props) {
  const projectId = useSelector((state) => state.location.payload.id);

  const { paginator } = usePaginatorState();
  const { size, currentPage } = paginator;

  const from = (currentPage - 1) * size;

  const { result } = useMRProjectUpdatesQuery({
    queryEndpointName: 'maintenancePlan',
    additionalVars: { projectId },
    from,
    size,
  });

  const documentId = result.maintenancePlan?._id;

  const additionalFilesProps = useFilesUpdates({
    documentId,
    collection: 'maintenancePlans',
  });

  if (result.loading || !result.maintenancePlan) return <Loader />;

  const updates = result?.maintenancePlan.documentUpdates.hits || [];
  const total = result?.maintenancePlan.documentUpdates.total;

  return (
    <ProjectUpdates
      {...props}
      {...result}
      {...additionalFilesProps}
      updates={updates}
      total={total}
    />
  );
}
