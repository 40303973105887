import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { AbsoluteLoader } from '@poly/site-ui';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import {
  highlightCurrency,
  highlightField,
} from '../../utils/highlight/index.js';
import { usePOsFilters } from '../../utils/purchase-orders/pos-filters.js';
import { noDataToDisplay } from '../../util/general.js';
import {
  POFormatter,
  projectPOFormatter,
  propertySelector,
} from '../../utils/purchase-orders/index.js';
import {
  setApprovedPOsPaginationCurrentPage,
  setApprovedPOsPaginationDropdownLabel,
  setApprovedPOsPaginationPageSize,
  setApprovedPOsPaginationTotal,
} from '../../redux/actions/index.js';
import { usePurchaseOrdersQuery } from '../../hooks/purchase-orders.js';
import usePagination from '../../hooks/usePagination.js';

const getColumns = (search) => [
  ['PO#', R.identity, POFormatter(search)],
  ['Amount', R.prop('amount'), highlightCurrency(search)],
  ['WO#', R.prop('project'), projectPOFormatter(search)],
  ['Supplier', R.path(['supplier', 'company', 'name']), highlightField(search)],
  ['Property', propertySelector, highlightField(search)],
  ['GL Code', R.prop('glCode'), highlightField(search)],
];

export default function (props) {
  const approvedPOs = useSelector((state) => state.approvedPOs);

  const {
    type,
    currentPage,
    pageSize,
    paginationDropdownLabel,
    tableSearchString,
  } = approvedPOs;

  const from = (currentPage - 1) * pageSize;

  const { searchTerm, query, sort } = usePOsFilters(approvedPOs);

  const { total, requests, requestsLoading, restPurchaseOrdersProps } =
    usePurchaseOrdersQuery({
      searchTerm,
      query,
      pageSize,
      from,
      sort,
    });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setApprovedPOsPaginationTotal,
    setPageSize: setApprovedPOsPaginationPageSize,
    setCurrent: setApprovedPOsPaginationCurrentPage,
    setPaginationDropdownLabel: setApprovedPOsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns(searchTerm));
  const headers = R.map(R.nth(0), getColumns(searchTerm));
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns(searchTerm),
  );
  const colsPropsValue = R.map(R.nth(3), getColumns(searchTerm));
  const gridColumns = `
      minmax(80px, 150px)
      100px
      minmax(80px, 150px)
      minmax(150px, 1fr)
      minmax(150px, 1fr)
      100px
    `;

  if (requestsLoading) return <AbsoluteLoader />;
  if (!requests.length)
    return noDataToDisplay('Approved POs Waiting on Invoice')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restPurchaseOrdersProps}
      props={colsPropsValue}
      type={type}
      rows={requests}
      columns={columns}
      headers={headers}
      formats={formats}
      gridColumns={gridColumns}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      paginationDropdownLabel={paginationDropdownLabel}
      total={total}
      tableSearchString={tableSearchString}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      requestsLoading={requestsLoading}
      searchTerm={searchTerm}
    />
  );
}
