import React from 'react';
import { Layout } from '@poly/site-book';

import AuthContainer from '../hooks/auth-container.js';
import ContentContainer from '../utils/pm-projects/content.js';
import ProjectsFilter from '../containers/pm-projects/filter/index.js';
import Navigation from '../containers/navbar/navigation-container.js';
import ProjectsPageLayout from '../layouts/pm-projects-page-layout.js';

function PMProjects() {
  return (
    <AuthContainer>
      <Layout>
        <Layout.Header>
          <Navigation />
          <ProjectsFilter />
        </Layout.Header>
        <ContentContainer>
          <ProjectsPageLayout />
        </ContentContainer>
      </Layout>
    </AuthContainer>
  );
}

export default PMProjects;
