import { SupplierStatuses } from '@poly/constants';
import { createFullTextSearchQuery } from '@poly/utils/src/index.js';

import { SUPPLIERS_SORTING } from '../../constants/suppliers.js';

const getShouldQuery = (tableSearchString) => [
  {
    wildcard: {
      type: {
        value: `*${tableSearchString}*`,
        case_insensitive: true,
      },
    },
  },
  createFullTextSearchQuery(tableSearchString),
];

const formQuery = ({ typeFilter, tableSearchString = '' }) => {
  const { ACTIVE, BLOCKED } = SupplierStatuses;

  const queryType = {
    [ACTIVE]: {
      bool: {
        must_not: { match: { status: BLOCKED } },
        should: getShouldQuery(tableSearchString),
      },
    },
    [BLOCKED]: {
      bool: {
        should: getShouldQuery(tableSearchString),
        must_not: { match: { status: ACTIVE } },
        minimum_should_match: 1,
      },
    },
  };
  return queryType[typeFilter];
};

export const useSuppliersFilters = (props) => {
  const query = formQuery(props);
  const { sort } = SUPPLIERS_SORTING;

  return {
    query,
    sort,
  };
};

export const omitBlockedSuppliersQuery = {
  bool: { must_not: [{ match: { status: SupplierStatuses.BLOCKED } }] },
};
