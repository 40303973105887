import { PurchaseOrderStatus } from '@poly/constants';

export const { PENDING_APPROVAL, APPROVED, CLOSED, CANCELLED, PAID } =
  PurchaseOrderStatus;

export const COLORS = [
  {
    type: PENDING_APPROVAL,
    title: 'PENDING APPROVAL',
    color: '#ffbc28',
  },
  {
    type: APPROVED,
    title: 'APPROVED',
    color: '#9bdc53',
  },
  {
    type: CANCELLED,
    title: 'CANCELLED',
    color: '#9ea2a9',
  },
  {
    type: CLOSED,
    title: 'CLOSED',
    color: '#9ea2a9',
  },
  {
    type: PAID,
    title: 'PAID',
    color: '#9ea2a9',
  },
];

export const scopeTypes = [
  {
    value: true,
    label: 'In Scope',
  },
  {
    value: false,
    label: 'Out Scope',
  },
];
