import React from 'react';
import PropTypes from 'prop-types';
import {
  S,
  Icon,
  Widget,
  Heading,
  HeadingH5,
  IconButton,
} from '@poly/site-book';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { CREATE_CONTACT_USER_PERMISSION } from '@poly/security';
import { TableComponent } from '@poly/site-ui';

import PeopleSidebar from '../../../contact/index.js';
import PeopleModal from '../../../../containers/property/tabs/people/add-edit-modal.js';
import { shouldDisplayTable } from '../../../../util/job-costs.js';

export function AddPeopleButton({ openAddPeopleModal }) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_CONTACT_USER_PERMISSION,
  );

  return (
    ifHasPermission && (
      <IconButton fill="#ff8d02" onClick={openAddPeopleModal} hover>
        <S type="title">Add People</S>
        <Icon name="add" fill="#ff8d02" dimensions={{ width: 30 }} />
      </IconButton>
    )
  );
}

function People({
  users,
  selectedUser,
  closeSidebar,
  openAddPeopleModal,
  openEditPeopleModal,
}) {
  return (
    <Widget.Item>
      <Heading>
        <HeadingH5>Profile</HeadingH5>
        <AddPeopleButton {...{ openAddPeopleModal }} />
        <PeopleModal />
      </Heading>
      {shouldDisplayTable(users) && (
        <TableComponent
          gridColumns="
          minmax(100px, 200px)
          minmax(100px, 1fr)
          minmax(75px, 150px)
          minmax(80px, 160px)
        "
          {...users}
        />
      )}
      <PeopleSidebar
        {...{ selectedUser }}
        onClose={closeSidebar}
        onEdit={openEditPeopleModal}
      />
    </Widget.Item>
  );
}

People.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
  openAddPeopleModal: PropTypes.func.isRequired,
  openEditPeopleModal: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    _id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.shape({
      formatted_address: PropTypes.string,
    }),
  }),
  users: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    columns: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
    formats: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired,
  }).isRequired,
};

People.defaultProps = {
  selectedUser: null,
};

AddPeopleButton.propTypes = {
  openAddPeopleModal: PropTypes.func.isRequired,
};

export default People;
