import * as R from 'ramda';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  GeneralError,
  AddInvoiceSuccess,
  InvoiceNumberError,
} from '../../constants/alerts.js';
import { MODAL_STATUSES } from '../../constants/invoices.js';
import AddEditInvoice from '../../components/invoices/add-edit-modal.js';
import { setInvoicesModal, setPOsModal } from '../../redux/actions/index.js';
import { checkInvoiceNumberError } from '../../utils/invoices/index.js';
import { validateInvoiceAmountByPoBalance } from './helpers.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';
import { useCreateInvoice } from '../../hooks/invoices.js';
import useValidation from '../../hooks/useValidation.js';
import { pdfValidationConfig } from './edit-invoice.js';
import {
  removeCurrencyFormatting,
  getValue,
  isExist,
} from '../../util/general.js';

function InvoiceAdd(props) {
  const dispatch = useDispatch();

  const { modalInfo } = props;

  const {
    amount,
    glCodes,
    balance,
    project,
    supplierId,
    cardNumber,
    purchaseOrderId,
    withJobCostRefetch,
  } = modalInfo;

  const { setRef, getRef } = useRefHandlers();

  const [code, setCode] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receiveDate, setReceiveDate] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [commentsState, setCommentsState] = useState(null);
  const [status, setStatus] = useState(MODAL_STATUSES.ACTIVE);
  const [invoiceTotalState, setInvoiceTotalState] = useState(null);
  const [invoiceNumberState, setInvoiceNumberState] = useState(null);

  const { createInvoice } = useCreateInvoice(withJobCostRefetch);

  const { errors, setError, onChange, validate, validateField } = useValidation(
    {
      validationRules: {
        invoiceNumber: [{ rule: 'required' }],
        invoiceTotal: [
          {
            rule: validateInvoiceAmountByPoBalance,
            message: 'Invoice amount exceeds PO amount',
          },
          {
            rule: ({ invoiceTotal }) => R.equals(invoiceTotal, 0),
            message: 'Value cannot be zero',
          },
          {
            rule: ({ invoiceTotal }) => !isExist(invoiceTotal),
            message: 'Please Fill Out This Field',
          },
        ],
        receiveDate: [{ rule: 'required' }],
        invoiceDate: [{ rule: 'required' }],
        code: [{ rule: 'required' }],
        file: [{ rule: 'required' }, pdfValidationConfig],
      },
    },
  );
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const invoiceNumber = getValue('invoiceNumber', e);
      const invoiceTotal = isExist(getValue('invoiceTotal', e))
        ? removeCurrencyFormatting(getValue('invoiceTotal', e))
        : null;
      const glCode = code?.value;
      const comments = getValue('comments', e);

      setInvoiceNumberState(invoiceNumber);
      setInvoiceTotalState(invoiceTotal);
      setCommentsState(comments);

      const errorsValue = validate({
        invoiceNumber,
        invoiceTotal: { invoiceTotal, balance },
        receiveDate,
        invoiceDate,
        code: glCode,
        file,
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const input = {
        invoiceDate: invoiceDate.toISOString(),
        receiveDate: receiveDate.toISOString(),
        invoiceFile: file.file,
        total: invoiceTotal,
        purchaseOrderId,
        invoiceNumber,
        supplierId,
        comments,
        glCode,
      };

      return createInvoice(input)
        .then(() => {
          dispatch(setInvoicesModal({ createInvoice: null }));
          dispatch(
            setPOsModal({
              closePO: {
                cardNumber,
                _id: purchaseOrderId,
              },
            }),
          );
          toast.success(AddInvoiceSuccess);
        })
        .catch((err) => {
          setLoading(false);
          if (checkInvoiceNumberError(err.message)) {
            setError({ invoiceNumber: InvoiceNumberError });
            return;
          }
          setError({ server: err.message });
          toast.error(GeneralError);
        });
    },
    [
      code,
      file,
      balance,
      validate,
      dispatch,
      setError,
      cardNumber,
      setLoading,
      supplierId,
      receiveDate,
      invoiceDate,
      createInvoice,
      purchaseOrderId,
    ],
  );

  const closeModal = useCallback(
    () => dispatch(setInvoicesModal({ createInvoice: null })),
    [dispatch],
  );

  const onInvoiceTotalChange = useCallback(
    (e) => {
      const value = R.path(['target', 'value'], e);
      if (isExist(value)) {
        const invoiceTotalValue = removeCurrencyFormatting(value);
        return validateField('invoiceTotal', {
          invoiceTotal: invoiceTotalValue,
          balance,
        });
      }
      return validateField('invoiceTotal', { invoiceTotal: null, balance });
    },
    [balance, validateField],
  );

  const onReceiveDateChange = useCallback(
    (date) => {
      setReceiveDate(date);
      validateField('receiveDate', date);
    },
    [validateField, setReceiveDate],
  );

  const onInvoiceDateChange = useCallback(
    (date) => {
      setInvoiceDate(date);
      validateField('invoiceDate', date);
    },
    [validateField, setInvoiceDate],
  );

  const onSelectCode = useCallback(
    (codeValue) => {
      setCode(codeValue);
      validateField('code', codeValue?.value);
    },
    [setCode, validateField],
  );

  const onAddFile = useCallback(
    (fileValue) => {
      setError({ ...errors, file: null });
      setFile(fileValue);
    },
    [setFile, setError, errors],
  );

  const onRemoveFile = useCallback(() => setFile(null), [setFile]);

  const onStatusSelect = useCallback(
    (statusValue) => setStatus(statusValue.value),
    [setStatus],
  );

  return (
    <AddEditInvoice
      {...props}
      setRef={setRef}
      getRef={getRef}
      type="add"
      project={project}
      closeModal={closeModal}
      code={code}
      file={file}
      cardNumber={cardNumber}
      amount={amount}
      errors={errors}
      balance={balance}
      status={status}
      loading={loading}
      purchaseOrderId={purchaseOrderId}
      supplierId={supplierId}
      glCodes={glCodes}
      onInvoiceTotalChange={onInvoiceTotalChange}
      onReceiveDateChange={onReceiveDateChange}
      onInvoiceDateChange={onInvoiceDateChange}
      onSelectCode={onSelectCode}
      onAddFile={onAddFile}
      onRemoveFile={onRemoveFile}
      onStatusSelect={onStatusSelect}
      onSubmit={onSubmit}
      onChange={onChange}
      receiveDate={receiveDate}
      invoiceDate={invoiceDate}
      total={invoiceTotalState}
      invoiceNumber={invoiceNumberState}
      comments={commentsState}
    />
  );
}

InvoiceAdd.propTypes = {
  modalInfo: PropTypes.shape({
    amount: PropTypes.number,
    project: PropTypes.string,
    balance: PropTypes.number,
    cardNumber: PropTypes.string,
    supplierId: PropTypes.string,
    purchaseOrderId: PropTypes.string,
    withJobCostRefetch: PropTypes.bool,
    glCodes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  }),
};

export default function (props) {
  const modalInfo = useSelector((state) => state.invoices.modals.createInvoice);

  return modalInfo && <InvoiceAdd {...props} modalInfo={modalInfo} />;
}
