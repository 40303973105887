import React from 'react';
import PropTypes from 'prop-types';
import { S, Pip, Grid, HeadingH5, Widget } from '@poly/site-book';
import { PageHeader } from '@poly/site-ui';

import { AssetStatusColors } from '../../constants/asset.js';
import { propertyLinkFormatter } from '../../util/property-utils.js';
import { formatDateWithDashes } from '../../util/general.js';

function DetailsRow({ title, value }) {
  return (
    <Grid.Row>
      <Grid.Cell>
        <S type="title" textColor="#5d688c">
          {title}
        </S>
      </Grid.Cell>
      <Grid.Cell>
        <S type="content" bold>
          {value || '—'}
        </S>
      </Grid.Cell>
    </Grid.Row>
  );
}

function LinkedRow({ title, value, formatter }) {
  return (
    <Grid.Row>
      <Grid.Cell>
        <S type="title" textColor="#5d688c">
          {title}
        </S>
      </Grid.Cell>
      <Grid.Cell>{formatter(value)}</Grid.Cell>
    </Grid.Row>
  );
}

DetailsRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

DetailsRow.defaultProps = {
  value: '—',
};

LinkedRow.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.shape({
    _id: PropTypes.string,
  }),
  formatter: PropTypes.func.isRequired,
};

LinkedRow.defaultProps = {
  value: null,
};

function AssetDetails({
  asset: {
    status,
    description,
    manufacturer,
    model,
    serial,
    location,
    room,
    startUp,
    property,
  },
}) {
  return (
    <Widget.Item>
      <PageHeader>
        <HeadingH5>Assets Details</HeadingH5>
        <Pip color={AssetStatusColors[status]}>
          <S type="title" textColor="#4b5a8f">
            {status}
          </S>
        </Pip>
      </PageHeader>
      <Grid columns="120px 1fr" simple>
        <DetailsRow title="Description" value={description} />
        <DetailsRow title="Manufacturer" value={manufacturer} />
        <DetailsRow title="Model number" value={model} />
        <DetailsRow title="Serial number" value={serial} />
        <DetailsRow title="Location" value={location} />
        <DetailsRow title="Room" value={room} />
        <DetailsRow title="Start Up" value={formatDateWithDashes(startUp)} />
        <LinkedRow
          title="Property"
          value={property}
          formatter={propertyLinkFormatter}
        />
      </Grid>
    </Widget.Item>
  );
}

AssetDetails.propTypes = {
  asset: PropTypes.shape({
    description: PropTypes.string,
    manufacturer: PropTypes.string,
    model: PropTypes.string,
    serial: PropTypes.string,
    location: PropTypes.string,
    room: PropTypes.string,
    startUp: PropTypes.string,
    status: PropTypes.string.isRequired,
    property: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default AssetDetails;
