import { Loader } from '@poly/site-book';
import PropTypes from 'prop-types';
import React from 'react';

export function ButtonLoader(props) {
  return (
    <Loader {...props}>
      <Loader.Circle {...props} />
    </Loader>
  );
}

ButtonLoader.propTypes = {
  size: PropTypes.number,
};

ButtonLoader.defaultProps = {
  size: 20,
};
