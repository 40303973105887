import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { collectionNames } from '@poly/constants';
import { FilesTab } from '@poly/site-ui';
import { fileColumns } from '../../../utils/files.js';
import { useSupplierDetails } from '../../../hooks/suppliers.js';

export default function (props) {
  const dispatch = useDispatch();

  const supplierId = useSelector((state) => state.location.payload.id);

  const { supplier, loading, networkStatus } = useSupplierDetails(supplierId);

  const collection = collectionNames.suppliers;
  const fileColumnsValue = fileColumns(dispatch);

  return (
    <FilesTab
      {...props}
      documentId={supplierId}
      collection={collection}
      id={supplier?.company.name}
      fileColumns={fileColumnsValue}
      loading={loading}
      networkStatus={networkStatus}
    />
  );
}
