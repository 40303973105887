import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import React from 'react';
import { Loader } from '@poly/site-ui';
import ProjectFilesContainer from './tabs/files.js';
import EditProject from './edit-project.js';
import ProjectCostsContainer from './tabs/job-costs/index.js';
import ProjectUpdatesContainer from './tabs/updates.js';
import ProjectAssetsContainer from './tabs/assets/main.js';
import ProjectNavigationContainer from './navigation.js';
import AddAssetToProject from './tabs/assets/add-asset-to-project.js';
import RemoveAssetConfirmModal from './tabs/assets/confirm-remove-asset.js';
import AddProject from './add-project-container.js';
import { usePrefetchProject } from '../../hooks/prefetch.js';

const tabs = {
  updates: ProjectUpdatesContainer,
  costs: ProjectCostsContainer,
  assets: ProjectAssetsContainer,
  files: ProjectFilesContainer,
};

export const DataWrapper = styled.section`
  position: relative;
`;

function ProjectDataContainer({
  loading,
  activeTab,
  isAddAssetModal,
  isRemoveAssetModal,
  isEditProjectModal,
  isAddProjectModal,
}) {
  const Container = tabs[activeTab];

  return (
    <>
      <ProjectNavigationContainer />
      <DataWrapper>
        {loading ? <Loader /> : <Container />}
        {isEditProjectModal && <EditProject />}
        {isAddAssetModal && <AddAssetToProject />}
        {isRemoveAssetModal && <RemoveAssetConfirmModal />}
        {isAddProjectModal && <AddProject />}
      </DataWrapper>
    </>
  );
}

ProjectDataContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  isAddAssetModal: PropTypes.bool.isRequired,
  isRemoveAssetModal: PropTypes.bool.isRequired,
  isEditProjectModal: PropTypes.bool.isRequired,
  isAddProjectModal: PropTypes.bool.isRequired,
};

function DataProject(props) {
  const { restProps } = usePrefetchProject(props);

  return <ProjectDataContainer {...props} {...restProps} />;
}

export default function (props) {
  const activeTab = useSelector((state) => state.projects.activeTab);

  const projectId = useSelector(
    (state) => state.projects.activeProject?.projectId,
  );

  const _id = useSelector((state) => state.projects.activeProject?._id);

  const isAddAssetModal = useSelector(
    (state) => !!state.projectAssets.modals.addAsset,
  );

  const isEditProjectModal = useSelector(
    (state) => !!state.projects.modals.editProject,
  );

  const isRemoveAssetModal = useSelector(
    (state) => !!state.projectAssets.modals.removeAsset,
  );

  const isAddProjectModal = useSelector((state) => !!state.projects.modals.add);

  if (!projectId || !_id) return null;

  return (
    <DataProject
      {...props}
      _id={_id}
      activeTab={activeTab}
      projectId={projectId}
      isAddAssetModal={isAddAssetModal}
      isEditProjectModal={isEditProjectModal}
      isRemoveAssetModal={isRemoveAssetModal}
      isAddProjectModal={isAddProjectModal}
    />
  );
}
