import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useState, useCallback, useEffect, useMemo } from 'react';

import { getGlCodeOptionsByClientGLCodes } from './tabs/gl-codes-utils.js';

// getGlCodes :: User -> [Option]
const getGlCodes = R.compose(
  getGlCodeOptionsByClientGLCodes,
  R.defaultTo([]),
  R.prop('clientGLCodes'),
);

export const useGlCodeSelectLogic = (modalInfo) => {
  const [code, setCode] = useState(null);

  const userState = useSelector((state) => state.user);

  const glCodes = useMemo(() => getGlCodes(userState), [userState]);

  useEffect(
    () =>
      setCode(glCodes.find((codeElem) => codeElem.value === modalInfo.glCode)),
    [modalInfo],
  );

  const onSelectCode = useCallback(
    (codeValue) => {
      setCode(codeValue);
    },
    [setCode],
  );

  return {
    code,
    glCodes,
    setCode,
    onSelectCode,
  };
};
