import * as R from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AbsoluteLoader } from '@poly/site-ui';
import { QUEUE } from '../../../constants/projects.js';
import { TableWithSavingDataForExport } from '../../../components/table/table.js';
import TableCheckbox from '../../../components/checkboxes/table-checkbox.js';
import HeaderCheckbox from '../../../components/checkboxes/header-checkbox.js';
import {
  highlightField,
  highlightProject,
  highlightProjectStatus,
} from '../../../utils/highlight/index.js';

import { dateFormatter } from '../../../utils/projects/index.js';
import { noDataToDisplay } from '../../../util/general.js';

import { projectTypeConverter } from '../../../util/project-utils.js';
import {
  setPmProjectsPaginationCurrentPage,
  setPmProjectsPaginationDropdownLabel,
  setPmProjectsPaginationPageSize,
  setPmProjectsPaginationTotal,
  setResponseTeamModal,
  setSelectedPmProjects,
  togglePmProject,
} from '../../../redux/actions/index.js';
import {
  HoveredGridRow,
  assignedToFormatter,
} from '../../../components/projects/assigned-to.js';
import { useProjectsQuery } from '../../../hooks/projects.js';
import usePagination from '../../../hooks/usePagination.js';
import { useProjectFilters } from '../../../utils/projects/projects-filter.js';
import { useSortableTable } from '../../../hooks/table-enhancers.js';

const getAlign = (horizontal) => ({ align: { vertical: 'start', horizontal } });

const STATE_PATH = ['PMProjects', 'selectedProjects'];

const getColumns = ({ search, dispatch, isQueue, projects = [] } = {}) => {
  const columns = [
    ['WO #', R.identity, highlightProject(search)],
    ['WO Type', R.prop('type'), projectTypeConverter(search)],
    ['Description', R.prop('description'), highlightField(search)],
    ['Property', R.path(['property', 'name']), highlightField(search)],
    [
      'Assigned To',
      R.identity,
      assignedToFormatter(search, dispatch, setResponseTeamModal),
      getAlign('stretch'),
      R.path(['technicians', 0, 'fullName']),
    ],
    ['Start', R.prop('startDate'), dateFormatter(search)],
    ['Due', R.prop('endDate'), dateFormatter(search)],
    ['Status', R.identity, highlightProjectStatus(search)],
  ];

  return isQueue
    ? [
        [
          <HeaderCheckbox
            key="header-checkbox"
            statePath={STATE_PATH}
            pageItems={projects.map(({ _id }) => _id)}
            onChange={R.compose(dispatch, setSelectedPmProjects)}
          />,
          R.prop('_id'),
          (id) => (
            <TableCheckbox
              key="table-checkbox"
              statePath={STATE_PATH}
              onChange={R.compose(dispatch, togglePmProject)}
              id={id}
            />
          ),
        ],
        ...columns,
      ]
    : columns;
};

export default function (props) {
  const dispatch = useDispatch();

  const pmProjectsValue = useSelector((state) => state.PMProjects);

  const locationType = useSelector((state) => state.location.type);

  const {
    currentPage,
    pageSize,
    typeFilter,
    tableSearchString,
    paginationDropdownLabel,
  } = pmProjectsValue;

  const from = (currentPage - 1) * pageSize;

  const propsForFilter = {
    typeFilter,
    locationType,
    tableSearchString,
  };

  const { searchTerm, query, sort } = useProjectFilters(propsForFilter);

  const { projects, restProjectsProps, total, result } = useProjectsQuery({
    searchTerm,
    query,
    pageSize,
    from,
    sort,
  });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setPmProjectsPaginationTotal,
    setCurrent: setPmProjectsPaginationCurrentPage,
    setPageSize: setPmProjectsPaginationPageSize,
    setPaginationDropdownLabel: setPmProjectsPaginationDropdownLabel,
    total,
    currentPage,
  });

  const isQueue = typeFilter === QUEUE;

  const cols = getColumns({
    search: tableSearchString,
    dispatch,
    isQueue,
    projects,
  });

  const columnsValue = R.map(R.nth(1), cols);

  const headers = R.map(R.nth(0), cols);

  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(4))(item),
    cols,
  );

  const omitSorting = [isQueue];

  const formats = R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), cols);

  const colsPropsValue = R.map(R.nth(3), cols);

  const gridColumns = `
          ${isQueue ? '16px' : ''}
          110px
          minmax(80px, 100px)
          repeat(2, minmax(100px, 700px))
          minmax(80px, 150px)
          minmax(80px, 120px)
          minmax(80px, 120px)
          minmax(80px, 160px)
        `;

  const { rows, sorting, setSorting } = useSortableTable(
    projects,
    valuesToSort,
  );

  if (restProjectsProps.loading) return <AbsoluteLoader />;
  if (!projects.length) return noDataToDisplay('Projects')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...result}
      {...restProjectsProps}
      props={colsPropsValue}
      sorting={sorting}
      setSorting={setSorting}
      searchTerm={searchTerm}
      tableSearchString={tableSearchString}
      rows={rows}
      columns={columnsValue}
      headers={headers}
      formats={formats}
      valuesToSort={valuesToSort}
      omitSorting={omitSorting}
      gridColumns={gridColumns}
      dispatch={dispatch}
      size={pageSize}
      currentPage={currentPage}
      from={from}
      query={query}
      projects={projects}
      total={total}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      paginationDropdownLabel={paginationDropdownLabel}
      locationType={locationType}
      GridRowComponent={HoveredGridRow}
      typeFilter={typeFilter}
    />
  );
}
