import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { AbsoluteLoader } from '@poly/site-ui';
import { SORTING } from '../../constants/index.js';
import { TableWithSavingDataForExport } from '../../components/table/table.js';
import {
  highlightCurrency,
  highlightField,
} from '../../utils/highlight/index.js';

import { noDataToDisplay } from '../../util/general.js';
import {
  setPOLogPaginationCurrentPage,
  setPOLogPaginationDropdownLabel,
  setPOLogPaginationPageSize,
  setPOLogPaginationTotal,
} from '../../redux/actions/index.js';
import {
  highlightPOStatus,
  POFormatter,
  projectPOFormatter,
  propertySelector,
} from '../../utils/purchase-orders/index.js';
import { usePurchaseOrdersQuery } from '../../hooks/purchase-orders.js';
import usePagination from '../../hooks/usePagination.js';
import { useSortableTable } from '../../hooks/table-enhancers.js';

const getColumns = ({ tableSearchString: search }) => [
  ['PO#', R.identity, POFormatter(search), R.prop('cardNumber')],
  ['Amount', R.prop('amount'), highlightCurrency(search)],
  [
    'WO#',
    R.prop('project'),
    projectPOFormatter(search),
    R.path(['project', 'projectId']),
  ],
  ['Supplier', R.path(['supplier', 'company', 'name']), highlightField(search)],
  ['Property', propertySelector, highlightField(search)],
  ['GL Code', R.prop('glCode'), highlightField(search)],
  ['Status', R.prop('status'), highlightPOStatus(search)],
];

export default function (props) {
  const poLog = useSelector((state) => state.POLog);

  const { currentPage, pageSize, tableSearchString, paginationDropdownLabel } =
    poLog;

  const from = (currentPage - 1) * pageSize;

  const { sort } = SORTING;

  const { total, requests, requestsLoading, restPurchaseOrdersProps } =
    usePurchaseOrdersQuery({
      searchTerm: tableSearchString,
      pageSize,
      from,
      sort,
    });

  const { onChange, onShowSizeChange, showPagination } = usePagination({
    setTotal: setPOLogPaginationTotal,
    setPageSize: setPOLogPaginationPageSize,
    setCurrent: setPOLogPaginationCurrentPage,
    setPaginationDropdownLabel: setPOLogPaginationDropdownLabel,
    total,
    currentPage,
  });

  const columns = R.map(R.nth(1), getColumns({ tableSearchString }));
  const headers = R.map(R.nth(0), getColumns({ tableSearchString }));
  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    getColumns({ tableSearchString }),
  );

  const valuesToSort = R.map(
    (item) => R.compose(R.defaultTo(R.nth(1, item)), R.nth(3))(item),
    getColumns({ tableSearchString }),
  );

  const gridColumns = `
        minmax(80px, 150px)
        minmax(80px, 150px)
        100px
        minmax(150px, 1fr)
        minmax(150px, 1fr)
        100px
        minmax(80px, 150px)
      `;

  const { rows, sorting, setSorting } = useSortableTable(
    requests,
    valuesToSort,
  );

  if (requestsLoading) return <AbsoluteLoader />;
  if (!requests.length) return noDataToDisplay('Purchase Orders')();

  return (
    <TableWithSavingDataForExport
      {...props}
      {...restPurchaseOrdersProps}
      total={total}
      currentPage={currentPage}
      size={pageSize}
      from={from}
      searchTerm={tableSearchString}
      paginationDropdownLabel={paginationDropdownLabel}
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      showPagination={showPagination}
      columns={columns}
      headers={headers}
      formats={formats}
      rows={rows}
      sorting={sorting}
      setSorting={setSorting}
      valuesToSort={valuesToSort}
      gridColumns={gridColumns}
    />
  );
}
