import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { SearchGrid } from '@poly/site-ui';

import { assetsDescriptionFormatter } from '../../../utils/global-search/index.js';
import { highlightAsset } from '../../../utils/highlight/index.js';

const columnConfig = (search) => [
  [R.identity, highlightAsset(search)],
  [R.identity, assetsDescriptionFormatter(search)],
];
function AssetGlobalSearch({ hits, searchTerm }) {
  const gridColumns = `
      minmax(60px, 160px)
      auto
    `;

  return (
    <SearchGrid
      rows={hits}
      columnConfig={columnConfig}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
    />
  );
}

AssetGlobalSearch.propTypes = {
  searchTerm: PropTypes.string,
  hits: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

export default AssetGlobalSearch;
